import img1 from "./assets/img4.webp";
import { useEffect, useState } from "react";

const SubmitButton = ({ loading }) => {
  return (
    <button
      type="submit"
      disabled={loading}
      className="w-full bg-[#c61d21] text-white py-2 rounded-md font-bold hover:bg-red-700 transition duration-300 relative disabled:opacity-70 disabled:cursor-not-allowed"
    >
      <span className={`${loading ? "opacity-0" : "opacity-100"}`}>Submit</span>

      {loading && (
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
        </div>
      )}
    </button>
  );
};

const BookAYagnaForm = ({ defaultDate }) => {
  const [isLoading, setIsLoading] = useState(false);
  // State for form inputs
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    location: "",
    address: "",
    gathering: "",
    time: "",
    date: "",
    note: "",
  });

  // Function to convert DD-MM-YYYY to YYYY-MM-DD
  const convertToHTMLDate = (dateString) => {
    if (!dateString) return "";
    const [day, month, year] = dateString.split("-");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    if (defaultDate) {
      const htmlFormattedDate = convertToHTMLDate(defaultDate);
      setFormData((prevData) => ({ ...prevData, date: htmlFormattedDate }));
    }
  }, [defaultDate]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      formData.name.trim() == "" ||
      formData.email.trim() == "" ||
      formData.phone.trim() == "" ||
      formData.location.trim() == "" ||
      formData.address.trim() == "" ||
      formData.gathering.trim() == "" ||
      formData.time.trim() == "" ||
      formData.date.trim() == "" ||
      formData.note.trim() == ""
    ) {
      alert("Please enter all fields!");
      return;
    }
    setIsLoading(true);

    const formBody = new URLSearchParams(formData).toString();

    console.log(formBody);

    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbzu0-WhXBnP4p4vGZe-rCOQ2pX5eNwGfH2vXJLjkdfJ-pX05Rojg9Io_rMfQnL5SKWvfA/exec",
        {
          method: "POST",
          body: formBody,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (response.ok) {
        setFormData({
          name: "",
          email: "",
          phone: "",
          location: "",
          address: "",
          gathering: "",
          time: "",
          date: "",
          note: "",
        });
      } else {
        throw new Error("Failed to submit the form.");
      }
    } catch (error) {
      // setMessage("An error occurred while submitting the form.");
      console.error(error);
    } finally {
      alert("Yagna Booked successfully");
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col md:flex-row bg-white shadow-lg rounded-lg overflow-y-auto overflow-x-hidden md:overflow-y-hidden h-[500px] md:h-[600px]">
      {/* Left Side - Image */}
      <div className="w-[full] md:w-[40%] h-[300px] md:h-full ">
        <img
          loading="lazy"
          src={img1}
          alt="Speak with a Senior Priest"
          className="object-cover w-full h-full"
        />
      </div>

      {/* Right Side - Form */}
      <div className="w-[full] md:w-[60%] p-6 flex flex-col justify-start items-start">
        <h2 className="text-[#1b1616] text-2xl font-bold font-['Poppins'] m-0">
          Book Yagna
          <hr
            className="w-[300%] text-black"
            style={{
              borderTop: "2px solid #00000",
            }}
          />
        </h2>

        <p className="text-[#7f7a7a] text-sm font-normal font-['Open Sans'] text-start mb-2">
          Let your home reverberate with the Holy name,make a special occasion,a
          divine occasion by marking it with a sankirtan where devotees from the
          Iskcon Vijayawada temple will come to you and sing the glories of the
          Lord.
        </p>

        <form className="space-y-4" onSubmit={handleSubmit}>
          <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-1">
            <div>
              <label
                htmlFor="name"
                className="text-black text-sm font-bold font-['Inter'] m-0"
              >
                Name :
              </label>
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleInputChange}
                id="name"
                className="p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800 m-0 h-8"
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="text-black text-sm font-bold font-['Inter'] m-0"
              >
                Email :
              </label>
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleInputChange}
                id="email"
                className="p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800 m-0 h-8"
              />
            </div>

            <div>
              <label
                htmlFor="phone"
                className="text-black text-sm font-bold font-['Inter'] "
              >
                Phone :
              </label>
              <input
                type="tel"
                name="phone"
                placeholder="Phone"
                value={formData.phone}
                onChange={handleInputChange}
                id="phone"
                className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800 h-8"
              />
            </div>

            <div>
              <label
                htmlFor="location"
                className="text-black text-sm font-bold font-['Inter'] "
              >
                Location :
              </label>
              <input
                type="text"
                name="location"
                placeholder="Location"
                value={formData.location}
                onChange={handleInputChange}
                id="location"
                className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800 h-8"
              />
            </div>

            <div>
              <label
                htmlFor="address"
                className="text-black text-sm font-bold font-['Inter'] m-0"
              >
                Address :
              </label>
              <input
                type="text"
                name="address"
                placeholder="Address"
                value={formData.address}
                onChange={handleInputChange}
                id="address"
                className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800 m-0 h-8"
              />
            </div>

            <div>
              <label
                htmlFor="gathering"
                className="text-black text-sm font-bold font-['Inter'] m-0"
              >
                Size of Gathering :
              </label>
              <input
                type="text"
                name="gathering"
                placeholder="Size of Gathering"
                value={formData.gathering}
                onChange={handleInputChange}
                id="gathering"
                className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800 m-0 h-8"
              />
            </div>
            <div>
              <label
                htmlFor="time"
                className="text-black text-sm font-bold font-['Inter'] m-0"
              >
                Preferred Time :
              </label>
              <input
                type="time"
                name="time"
                placeholder="Time"
                value={formData.time}
                onChange={handleInputChange}
                id="time"
                className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800 m-0 h-8"
              />
            </div>
            <div>
              <label
                htmlFor="date"
                className="text-black text-sm font-bold font-['Inter'] m-0"
              >
                Preferred Date :
              </label>
              <input
                type="date"
                name="date"
                placeholder="Date"
                value={formData.date}
                onChange={handleInputChange}
                id="date"
                className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800 m-0 h-8"
              />
            </div>
          </div>
          <div className="m-0">
            <label
              htmlFor="note"
              className="text-black text-sm font-bold font-['Inter'] m-0"
            >
              Special Note :
            </label>
            <textarea
              rows="4"
              name="note"
              placeholder="Special Note"
              value={formData.note}
              onChange={handleInputChange}
              id="note"
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800 m-0 h-20"
            ></textarea>
          </div>
          <SubmitButton loading={isLoading} />
        </form>
      </div>
    </div>
  );
};

export default BookAYagnaForm;
