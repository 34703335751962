import React from "react";
import guru from "../../img/16807694_1378471962242406_8392921627802468018_n1 1.webp";
import "./mainSection.css";

const MainSection = () => {
  return (
    <div className="main-section-wrapper">
      <div className="main-section-container">
        <div className="main-section">
          <div className="text-section">
            The <span className="name-color">Bhakti Shastri</span> program at
            ISKCON Vijayawada is an in-depth course designed for devotees eager
            to systematically study the key scriptures of Gaudiya Vaishnavism.
            This program offers a structured, academic approach to understanding
            the essential teachings of the Bhagavad-gita, Nectar of Devotion,
            Nectar of Instruction, and Sri Isopanisad. It helps devotees deepen
            their knowledge and practice of Krishna consciousness. <br />
            <br />
            The program is ideal for those who wish to strengthen their
            understanding of bhakti-yoga, apply the wisdom of the scriptures in
            daily life, and develop preaching and leadership skills within the
            ISKCON community.
          </div>
          <div className="image-section">
            <div className="image-container">
              <img
                loading="lazy"
                src={guru}
                alt="Spiritual Leader in Traditional Dress"
                className="spiritual-image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainSection;
