import React, { useEffect } from "react";
import "./style.css";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import heroSecImg from "./assets/heroSec.webp";
import gitaGrpImg from "./assets/gitaGrp.webp";
import endGrpImg from "./assets/endGrp.webp";
import jaapmalaImg from "./assets/jaapmala.webp";

function Gita4You() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClick = () => {
    window.location.href = "https://www.iskconvjagita4you.org/";
  };

  return (
    <>
      <Header />
      <div className="w-full gita-container flex flex-col justify-center items-center">
        {/* First Section */}

        <img
          loading="lazy"
          src={heroSecImg}
          alt="heroSecImg"
          className="full-width-image"
        />

        {/* Second Section */}
        <div className="lg:mt-10 p-3 flex flex-col justify-center items-center">
          <p className="text-2xl lg:text-5xl font-bold text-[#DE7D0D] text-shadow-downward">
            Karthik Maas Special
          </p>
          <p className="text-black text-sm lg:text-2xl">
            Learn and Understand Bhagavad Gita at the comfort of your home in
            just 18 days
          </p>
          <p className="text-black text-base lg:text-2xl">
            Classes Start from{" "}
            <span className="font-bold">21 October 2025</span>
          </p>
          <button
            onClick={handleClick}
            className="w-[75%] lg:w-[45%] lg:text-xl lg:font-semibold bg-[#C61D21] p-2 text-white rounded-lg"
          >
            Register Now
          </button>
          <p className="text-black text-base lg:text-2xl mt-2">
            If already registered, kindly{" "}
            <span className="text-[#C61D21] font-semibold">LOGIN</span>
          </p>
        </div>

        {/* Third Section */}
        <div className="mt-2 mb-8 w-[90%] flex flex-col justify-center items-center">
          <p className="text-2xl lg:text-5xl font-bold text-[#DE7D0D]">
            Watch the below video on how to register
          </p>
          <div className="lg:mt-5">
            <iframe
              className="rounded-xl lg:w-[70rem] lg:h-[35rem]"
              width="355"
              height="220"
              src="https://www.youtube.com/embed/BEAmbhKNphk?si=RVkn4vDi8KUuWztm"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </div>

        {/* Third Section */}
        <div className="w-[95%] lg:w-[80%] mb-10 lg:my-16">
          <img loading="lazy" src={gitaGrpImg} alt="gita Grp Img" />
        </div>

        {/* Fourth Section */}
        <div className="p-2 lg:w-[80%] lg:space-y-5">
          <p className="text-xl lg:text-4xl font-bold text-[#DE7D0D]">
            Recommended Books and Resources for a better understanding
          </p>
          <p className="text-black lg:text-2xl text-base">
            The Course materials are available both in English and Telugu
          </p>
        </div>

        {/* Fifth Section */}
        <div className="w-[95%] lg:w-[80%] lg:mt-5 flex flex-col justify-center items-center border-2 border-solid border-black p-3  rounded-lg space-y-5 lg:space-y-10">
          <img
            loading="lazy"
            src={endGrpImg}
            alt="end Grp Img"
            className="object-contain lg:p-4 p-2"
          />
          <p className="text-xl lg:text-3xl text-black font-semibold">
            Bhagavad Gita As it is (Set of 3)
          </p>
          <p className="lg:w-[80%] text-black text-sm lg:text-xl">
            The set is combination of 3 books (Bhagavad gits as it is, Science
            of Self Realization and Perfect Question and Perfect Answer) and a
            japa mala which will help in better understanding of the topics
            taught during the course. However please do note that the books are
            not compulsory the course.
          </p>
          <div className="w-[100%] flex flex-col lg:flex-row justify-center lg:justify-around items-center gap-1 ">
            <button className="w-[100%] lg:w-[20%] p-2 lg:text-2xl lg:font-semibold bg-white rounded-md text-[#C61D21] border-2 border-solid border-[#C61D21]">
              RS 500
            </button>
            <button className="w-[100%] lg:w-[20%] p-2 lg:text-2xl lg:font-semibold bg-[#C61D21] rounded-md text-white border-2 border-solid border-[#C61D21]">
              Pay Now
            </button>
          </div>
        </div>

        {/* sixth Section */}
        <div className="my-10 lg:my-20 w-[95%] lg:w-[80%]  flex flex-col justify-center items-center border-2 border-solid border-black p-3  rounded-lg space-y-5 lg:space-y-10">
          <img loading="lazy" src={jaapmalaImg} alt="jaapmala Img" />
          <p className="text-xl lg:text-3xl text-black font-semibold">
            Japamala & Japabag for daily chanting
          </p>
          <div className="w-[100%] flex flex-col lg:flex-row justify-center lg:justify-around items-center gap-1 ">
            <button className="w-[100%] lg:w-[20%] p-2 lg:text-2xl lg:font-semibold bg-white rounded-md text-[#C61D21] border-2 border-solid border-[#C61D21]">
              RS 500
            </button>
            <button className="w-[100%] lg:w-[20%] p-2 lg:text-2xl lg:font-semibold bg-[#C61D21] rounded-md text-white border-2 border-solid border-[#C61D21]">
              Pay Now
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Gita4You;
