import React, { useEffect } from "react";
import "./styles.css";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import langerImg from "./assets/food.webp";
import krishnaImg from "./assets/krishna.webp";
import riverImg from "./assets/river.webp";
import poojaImg from "./assets/pooja.webp";
import anniversaryImg from "./assets/Web Site 02 (1).webp";
import vaikuntha from "./assets/vaikuntha.webp";
import gita4u from "./assets/gita4u.webp";
import GauraPurnima from "./assets/GauraPurnima.webp";
import Jointhefamily from "./assets/Jointhefamily.webp";
import RamNavami from "./assets/SitaRama.webp";

import { useNavigate } from "react-router-dom";

function DonationTemp() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

//  const eventData = [
//     {
//       id: 1,
//       title: "Vaikuntha Ekadashi",
//       image: vaikuntha,
//       href:"/VaikuntaEkadashi"
//     },
//     {
//       id: 2,
//       title: "Gita 4 You",
//       image: gita4u,
//       href:"/gita4You"
//     },
//     {
//       id: 3,
//       title: "Gaura Purnima",
//       image: GauraPurnima,
//       href:"/GauraPurnima"

//     },
//     {
//       id: 4,
//       title: "Join the Family of Lord Krishna",
//       image: Jointhefamily,
//       href: "/JointheFamily"
//     },
//   ];
  const donateCardData = [
    {
      id: 1,
      title: "Annadaan",
      description:
        "Annadaan - ISKCON Vijayawada.",
      link: "/food-for-life",
      image: langerImg,
    },
    {
      id: 2,
      title: "Maha Kumbh Mela",
      description:
        "Maha Kumbh Mela - ISKCON Vijayawada.",
      link: "/MahaKumbhMela",
      image: riverImg,
    },
    {
      id: 3,
      title: "Nitya Seva",
      description:
        "Nitya Seva - ISKCON Vijayawada. ",
      link: "/nitya-seva",
      image: poojaImg,
    },
    {
      id: 4,
      title: "23rd Anniversary",
      description: "23rd Anniversary - ISKCON Vijayawada.",
      link: "/anniversary",
      image: anniversaryImg,
    },
    {
      id: 5,
      title: "Vaikuntha Ekadashi",
      description: "Vaikuntha Ekadashi - ISKCON Vijayawada.",
      link: "/VaikuntaEkadashi",
      image: vaikuntha,
    },
    {
      id: 6,
      title: "Gita 4 You",
      description: "Gita 4 You - ISKCON Vijayawada.",
      link: "/gita4You",
      image: gita4u,
    },
    {
      id: 7,
      title: "Gaura Purnima",
      description: "Gaura Purnima - ISKCON Vijayawada.",
      link: "/GauraPurnima",
      image: GauraPurnima,
    },
    {
      id: 8,
      title: "Join the Family of Lord Krishna",
      description: "Join the Family - ISKCON Vijayawada.",
      link: "/JointheFamily",
      image: Jointhefamily,
    },
    {
      id: 9,
      title: "Ram Navami",
      description: "Ram Navami - ISKCON Vijayawada.",
      link: "/RamNavami",
      image: RamNavami,
    },
   
  ];

  return (
    <>
      <Header />
      <div className="donate-container w-full !pb-4">
        <div>
          <img
            loading="lazy"
            className="relative"
            src={krishnaImg}
            alt="Krishna Img"
          />
        </div>
        <div className="text-center p-4">
          <p className="text-xl text-black lg:text-5xl font-serif font-bold">
            Turn Your Money Into Prasad
          </p>
          <p className="lg:text-xl lg:p-6 text-black">
            If you would like to make a donation towards a particular area of
            activity, please select an option from below. ISKCON relies entirely
            on voluntary donations and so every donation counts. Please note
            that donation is processed on a secure site.
          </p>
        </div>
        <div className="w-full px-4 grid grid-cols-1 lg:grid-cols-3 gap-6">
  {donateCardData.map((donate) => (
    <div
      className="w-full bg-[#C61D21] flex flex-col items-center p-[0.7rem] rounded-xl"
      key={donate.id}
    >
      <div className="w-full border-4 border-white rounded-lg">
        <img
          loading="lazy"
          src={donate.image}
          alt={donate.title}
          className="w-full h-80 object-cover object-top rounded-lg"
        />
      </div>
      <div className="w-full py-4 grid"> 
        <p className="text-[#FFBA21] text-xl lg:text-3xl font-bold text-center mb-3"> 
          {donate.title}
        </p>
        <p className="text-white text-sm lg:text-xl lg:w-[90%] mx-auto text-center mb-4"> 
          {donate.description}
        </p>
        <button
          onClick={() => {
            navigate(donate.link);
          }}
          className="w-[50%] text-white bg-[#F3B01B] mx-auto p-2 text-xl font-bold border-solid border-2 border-white rounded-xl"
        >
          Donate Now
        </button>
      </div>
    </div>
  ))}
</div>

        {/* <div className="w-[100%] space-y-6 lg:space-y-0 lg:gap-6 flex lg:grid lg:grid-cols-2 flex-col items-center justify-center my-5 ">
            {eventData.map((event) => (
              <a href={event.href} className="no-underline">
              <div
                className="w-[100%] lg:w-[100%] bg-[#C61D21] flex flex-col justify-center items-center p-3 rounded-xl lg:rounded-2xl space-y-4 border-4 border-solid border-[#FFB700]"
                key={event.id}
              >
                <img loading="lazy" src={event.image} alt={event.title} />
                <p className=" text-xl lg:text-2xl font-semibold mb-3 text-white ">
                  {event.title}
                </p>
              </div></a>
            ))}
          </div> */}
      </div>
      
 <div>
          
          
        </div>

      <Footer />
    </>
  );
}

export default DonationTemp;
