import React, { useState, useEffect } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import BannerImgDesk from "./assets/ramnavami.webp";
import thirdbg from "./assets/bg3.webp";
import "./Ramnavami.css";
import axios from "axios";
import img1 from './assets/SitaRama.webp'
import img2 from './assets/kirtanbhajan.webp'
import img3 from './assets/katha.webp'
import img4 from './assets/mahaannadan.webp'
import img5 from './assets/mahaaarti.webp'


function RamNavami() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    vastraAlankaranaSeva: false,
    vadapapuPanakamSeva: false,
    annadaanSeva: false,
    alankaranaSeva: false,
    sevaDate: "",
    honoureeName: "",
    relationship: "",
    donorName: "",
    email: "",
    mobile: "",
    dob: "",
    receive80G: false,
    panNumber: "",
    receiveMahaPrasadam: false,
    selectedVastraAlankaranaAmount: null,
    selectedVadapapuPanakamAmount: null,
    selectedAnnadaanAmount: null,
    selectedAlankaranaAmount: null,
    customVastraAlankaranaAmount: "",
    customVadapapuPanakamAmount: "",
    customAnnadaanAmount: "",
    customAlankaranaAmount: "",
    mahaPrasadamDetails: {
      doorNumber: "",
      houseName: "",
      streetName: "",
      area: "",
      country: "",
      state: "",
      city: "",
      pincode: "",
    },
  });

  const [errors, setErrors] = useState({});
  const [show80GDetails, setShow80GDetails] = useState(false);
  const [showMahaPrasadamDetails, setShowMahaPrasadamDetails] = useState(false);



  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      };

      if (name === "customVastraAlankaranaAmount" && value) {
        updatedData.selectedVastraAlankaranaAmount = parseFloat(value);
      } else if (name === "customMahaSankhabhisekhaAmount" && value) {
        updatedData.selectedMahaSankhabhisekhaAmount = parseFloat(value);
      } else if (name === "customMahaAnnadaanAmount" && value) {
        updatedData.selectedMahaAnnadaanAmount = parseFloat(value);
      } else if (name === "customMahaNivedanaAmount" && value) {
        updatedData.selectedMahaNivedanaAmount = parseFloat(value);
      }

      return updatedData;
    });
  };

  const handle80GCheckboxChange = (e) => {
    setShow80GDetails(e.target.checked);
    handleInputChange(e);
  };

  const handleMahaPrasadamCheckboxChange = (e) => {
    setShowMahaPrasadamDetails(e.target.checked);
    handleInputChange(e);
  };

  const handleAmountSelect = (section, amount) => {
    setFormData((prevData) => {
      switch (section) {
        case "vastraAlankarana":
          return {
            ...prevData,
            selectedVastraAlankaranaAmount:
              prevData.selectedVastraAlankaranaAmount === amount
                ? null
                : amount,
            customVastraAlankaranaAmount: "",
          };
        case "vadapapuPanakam":
          return {
            ...prevData,
            selectedVadapapuPanakamAmount:
              prevData.selectedVadapapuPanakamAmount === amount ? null : amount,
            customVadapapuPanakamAmount: "",
          };
        case "annadaan":
          return {
            ...prevData,
            selectedAnnadaanAmount:
              prevData.selectedAnnadaanAmount === amount ? null : amount,
            customAnnadaanAmount: "",
          };
        case "alankarana":
          return {
            ...prevData,
            selectedAlankaranaAmount:
              prevData.selectedAlankaranaAmount === amount ? null : amount,
            customAlankaranaAmount: "",
          };
        default:
          return prevData;
      }
    });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.donorName) newErrors.donorName = "Donor name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.mobile) newErrors.mobile = "Mobile number is required";
    if (!formData.dob) newErrors.dob = "Date of birth is required";
    if (formData.receive80G && !formData.panNumber)
      newErrors.panNumber = "PAN number is required for 80G certificate";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const getTotalAmount = () => {
    const vastraAmount =
      formData.selectedVastraAlankaranaAmount ||
      parseFloat(formData.customVastraAlankaranaAmount) ||
      0;
    const vadapapuPanakamAmount =
      formData.selectedVadapapuPanakamAmount ||
      parseFloat(formData.customVadapapuPanakamAmount) ||
      0;
    const annadaanAmount =
      formData.selectedAnnadaanAmount ||
      parseFloat(formData.customAnnadaanAmount) ||
      0;
    const alankaranaAmount =
      formData.selectedAlankaranaAmount ||
      parseFloat(formData.customAlankaranaAmount) ||
      0;
    return (
      vastraAmount + vadapapuPanakamAmount + annadaanAmount + alankaranaAmount
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    const totalAmount = getTotalAmount();
    let isFormDataStored = false;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/payment/create-order`,
        {
          amount: totalAmount,
          currency: "INR",
        }
      );

      const order = response.data;
      let sevaDescription = "Donation Payment";

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: totalAmount * 100,
        currency: "INR",
        name: "ISKCON Guru Purnima Donation",
        description: sevaDescription,
        order_id: order.id,
        notes: {
          donorName: formData.donorName,
          donorPhoneNumber: formData.mobile,
          donorEmail: formData.email,
        },
        handler: async function (response) {
          try {
            const verificationResponse = await axios.post(
              `${process.env.REACT_APP_API_URL}/api/payment/verify-payment`,
              {
                razorpay_order_id: response.razorpay_order_id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
                donationDetails: { ...formData, amount: totalAmount },
              }
            );

            if (verificationResponse.data.success) {
              alert("Payment Successful!");
              if (!isFormDataStored) {
                await storeFormData("Payment Successful");
                isFormDataStored = true;
              }
            }
          } catch (err) {
            console.error(err);
            alert("Payment verification failed");
            if (!isFormDataStored) {
              await storeFormData("Payment Failed");
              isFormDataStored = true;
            }
          }
        },
        prefill: {
          name: formData.donorName,
          email: formData.email,
          contact: formData.mobile,
        },
        theme: { color: "#C61D21" },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();

      paymentObject.on("payment.failed", async function (response) {
        alert("Payment Failed: " + response.error.description);
        if (!isFormDataStored) {
          await storeFormData("Payment Failed");
          isFormDataStored = true;
        }
      });
    } catch (error) {
      console.error("Error initiating payment:", error);
      alert("Failed to initiate payment. Please try again.");
      if (!isFormDataStored) {
        await storeFormData("Payment Failed");
        isFormDataStored = true;
      }
    }
  };

  const storeFormData = async (paymentStatus) => {
    const totalDonationAmount = getTotalAmount();
  
    // Prepare data in the format expected by your Apps Script
    const formDataToSend = new URLSearchParams();
    
    // Add all fields to the form data
    formDataToSend.append('paymentStatus', paymentStatus);
    formDataToSend.append('vastraAlankaranaSeva', formData.vastraAlankaranaSeva ? "Yes" : "No");
    formDataToSend.append('vastraAlankaranaAmount', formData.selectedVastraAlankaranaAmount || formData.customVastraAlankaranaAmount || 0);
    formDataToSend.append('vadapapuPanakamSeva', formData.vadapapuPanakamSeva ? "Yes" : "No");
    formDataToSend.append('vadapapuPanakamAmount', formData.selectedVadapapuPanakamAmount || formData.customVadapapuPanakamAmount || 0);
    formDataToSend.append('annadaanSeva', formData.annadaanSeva ? "Yes" : "No");
    formDataToSend.append('annadaanAmount', formData.selectedAnnadaanAmount || formData.customAnnadaanAmount || 0);
    formDataToSend.append('alankaranaSeva', formData.alankaranaSeva ? "Yes" : "No");
    formDataToSend.append('alankaranaAmount', formData.selectedAlankaranaAmount || formData.customAlankaranaAmount || 0);
    formDataToSend.append('totalDonationAmount', totalDonationAmount);
    formDataToSend.append('sevaDate', formData.sevaDate || "");
    formDataToSend.append('honoureeName', formData.honoureeName || "");
    formDataToSend.append('relationship', formData.relationship || "");
    formDataToSend.append('donorName', formData.donorName);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('mobile', formData.mobile);
    formDataToSend.append('dob', formData.dob);
    formDataToSend.append('receive80G', formData.receive80G ? "Yes" : "No");
    formDataToSend.append('panNumber', formData.receive80G ? formData.panNumber : "");
    formDataToSend.append('receiveMahaPrasadam', formData.receiveMahaPrasadam ? "Yes" : "No");
    formDataToSend.append('doorNumber', formData.mahaPrasadamDetails.doorNumber || "");
    formDataToSend.append('houseName', formData.mahaPrasadamDetails.houseName || "");
    formDataToSend.append('streetName', formData.mahaPrasadamDetails.streetName || "");
    formDataToSend.append('area', formData.mahaPrasadamDetails.area || "");
    formDataToSend.append('country', formData.mahaPrasadamDetails.country || "");
    formDataToSend.append('state', formData.mahaPrasadamDetails.state || "");
    formDataToSend.append('city', formData.mahaPrasadamDetails.city || "");
    formDataToSend.append('pincode', formData.mahaPrasadamDetails.pincode || "");
  
    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbwTo_IRtHRLnhe_PwDLzzSFwJAAtoldXv0S5HbdA4TmtjE3BlCLyC9GvKS1j-1g3ock/exec",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formDataToSend.toString()
        }
      );
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      console.log("Data stored successfully in Google Sheets");
      return true;
    } catch (error) {
      console.error("Error storing data:", error);
      alert("An error occurred while storing your donation details. Please try again.");
      return false;
    }
  };
  const EventCard = ({ Image, title, fullWidth = false }) => (
    <div className={`bg-white rounded-lg overflow-hidden shadow-lg transition-transform duration-300 hover:scale-105 ${fullWidth ? 'md:col-span-2' : ''}`}>
      {/* Image container - full width with increased height */}
      <div className="w-full h-64 overflow-hidden">  {/* Changed from h-48 to h-64 */}
        <img 
          src={Image} 
          alt="" 
          className="w-full h-full object-cover" 
        />
      </div>
      
      {/* Title with red background - reduced padding */}
      <div className="p-2 bg-[#C61D21]">  {/* Changed from p-4 to p-2 */}
        <h3 className="text-xl font-bold text-center text-white">{title}</h3>
      </div>
    </div>
  );
  
  const eventHighlights = [
    { Image: img1,
      title: "Sri Sita Rama Kalyana Mahotsavam",
    },
    {Image: img2,
      title: "Devotional Kirtans & Bhajans",
    },
    { Image: img3,
      title: "Sri Rama Katha Pravachan",
    },
    {Image: img4,
      title: "Maha Annadaan (Grand Feast)",
    },
    {Image: img5,
      title: "Special Pujas & Aartis",
      fullWidth: true
    }
  ];

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <Header />
      <div className="ramnavami-container">
        <div className="ramnavami-banner">
          <img
            loading="lazy"
            src={BannerImgDesk}
            alt="main Img"
            className="ramnavami-banner-img"
          />
        </div>
        <div
          className="ramnavami-content-bg"
          style={{ backgroundImage: `url(${thirdbg})` }}
        >
          <div className="ramnavami-content">
            <h2 className="ramnavami-headline">
              Sri Rama Navami Mahotsavam 2025
            </h2>
            <h2 className="ramnavami-subheadline">
              A Grand Celebration at ISKCON Vijayawada
            </h2>
            <div className="ramnavami-text-container">
              <div className="ramnavami-text-content">
                <p>
                  Rama Navami is the most glorious day of Lord Sri Ramachandra,
                  the embodiment of righteousness (dharma) and the ideal king
                  (maryada-purushottama). Lord Rama's flawless character,
                  unwavering devotion, and supreme compassion serve as a guiding
                  light for humanity. His pastimes in the Ramayana provide
                  timeless lessons of truth, duty, and love, especially in
                  today's world, where the values of Vedic culture are gradually
                  fading.
                </p>

                <p>
                  To honor this divine occasion, <strong>ISKCON Vijayawada</strong> invites you
                  to the <strong>Sri Sita Ramachandra Kalyanam Mahotsavam</strong>, a <strong>grand and
                  sacred wedding ceremony of Lord Rama and Sita Devi.</strong> 
                </p>

                <div className="ramnavami-event-details">
                  <p><strong>📅 Date:</strong> 6th April 2025</p>
                  <p><strong>⏰ Time:</strong> From 9:00 AM onwards</p>
                  <p><strong>📍 Venue:</strong> ISKCON SRI RADHA SHYAMSUNDAR MANDIR</p>
                </div>
              </div>
            </div>
          </div>
          
          <div className="xl:flex justify-between pb-4 px-4">
            {/* Event Highlights Cards - Left Side */}
            <div className="xl:w-[55%] mb-8 xl:mb-0">
              <h2 className="text-3xl font-bold text-center text-[#C61D21] mb-6">Event Highlights</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {eventHighlights.map((event, index) => (
                  <EventCard
                    key={index}
                    Image={event.Image}
                    title={event.title}
                    fullWidth={event.fullWidth}
                  />
                ))}
              </div>
            </div>
            
            {/* Donation Form - Right Side */}
            <div className="xl:w-[42%] xl:flex xl:justify-center xl:pt-[3.5rem] ">
  <div className="donationDetail bg-[#C61D21] py-10 px-2 rounded-3xl xl:h-full xl:p-8 xl:max-w-[1400px] w-full">
                <form onSubmit={handleSubmit} className="text-white">
                  <h2 className="text-center text-2xl xl:text-4xl">
                    Donation Details
                  </h2>

                  <div className="innerForm sevaContainer">
                    {/* Vastra Alankarana Seva */}
                    <label>
                      <input
                        type="checkbox"
                        name="vastraAlankaranaSeva"
                        checked={formData.vastraAlankaranaSeva}
                        onChange={handleInputChange}
                        style={{ accentColor: "blue" }}
                      />
                      Vastra Alankarana Seva
                    </label>
                    {formData.vastraAlankaranaSeva && (
                      <div className="grid grid-cols-2 -mt-4">
                        {[25000, 10000, 5000].map((amount) => (
                          <button
                            className="col-span-1 font-semibold p-3 bg-[#F9E7D0] rounded-xl text-black"
                            type="button"
                            key={amount}
                            onClick={() =>
                              handleAmountSelect("vastraAlankarana", amount)
                            }
                            style={{
                              backgroundColor:
                                formData.selectedVastraAlankaranaAmount ===
                                amount
                                  ? "#fac785"
                                  : "",
                              margin: "5px",
                            }}
                          >
                            ₹ {amount.toLocaleString("en-IN")}
                          </button>
                        ))}
                        <input
                          type="number"
                          name="customVastraAlankaranaAmount"
                          placeholder="₹ Enter Amount"
                          value={formData.customVastraAlankaranaAmount || ""}
                          onChange={handleInputChange}
                          className="col-span-2 p-3 border rounded-xl my-1 mx-1 customAmount text-black"
                        />
                      </div>
                    )}

                    {/* Vadapapu Panakam Seva */}
                    <label>
                      <input
                        type="checkbox"
                        name="vadapapuPanakamSeva"
                        checked={formData.vadapapuPanakamSeva}
                        onChange={handleInputChange}
                        style={{ accentColor: "blue" }}
                      />
                      Vadapapu Panakam Seva
                    </label>
                    {formData.vadapapuPanakamSeva && (
                      <div className="grid grid-cols-2 -mt-4">
                        {[25000, 10000, 5000].map((amount) => (
                          <button
                            className="col-span-1 font-semibold p-3 bg-[#F9E7D0] rounded-xl text-black"
                            type="button"
                            key={amount}
                            onClick={() =>
                              handleAmountSelect("vadapapuPanakam", amount)
                            }
                            style={{
                              backgroundColor:
                                formData.selectedVadapapuPanakamAmount ===
                                amount
                                  ? "#fac785"
                                  : "",
                              margin: "5px",
                            }}
                          >
                            ₹ {amount.toLocaleString("en-IN")}
                          </button>
                        ))}
                        <input
                          type="number"
                          name="customVadapapuPanakamAmount"
                          placeholder="₹ Enter Amount"
                          value={formData.customVadapapuPanakamAmount || ""}
                          onChange={handleInputChange}
                          className="col-span-2 p-3 border rounded-xl my-1 mx-1 customAmount text-black"
                        />
                      </div>
                    )}

                    {/* Annadaan Seva */}
                    <label>
                      <input
                        type="checkbox"
                        name="annadaanSeva"
                        checked={formData.annadaanSeva}
                        onChange={handleInputChange}
                        style={{ accentColor: "blue" }}
                      />
                      Annadaan Seva
                    </label>
                    {formData.annadaanSeva && (
                      <div className="grid grid-cols-2 -mt-4">
                        {[25000, 10000, 5000].map((amount) => (
                          <button
                            className="col-span-1 font-semibold p-3 bg-[#F9E7D0] rounded-xl text-black"
                            type="button"
                            key={amount}
                            onClick={() =>
                              handleAmountSelect("annadaan", amount)
                            }
                            style={{
                              backgroundColor:
                                formData.selectedAnnadaanAmount === amount
                                  ? "#fac785"
                                  : "",
                              margin: "5px",
                            }}
                          >
                            ₹ {amount.toLocaleString("en-IN")}
                          </button>
                        ))}
                        <input
                          type="number"
                          name="customAnnadaanAmount"
                          placeholder="₹ Enter Amount"
                          value={formData.customAnnadaanAmount || ""}
                          onChange={handleInputChange}
                          className="col-span-2 p-3 border rounded-xl my-1 mx-1 customAmount text-black"
                        />
                      </div>
                    )}

                    {/* Alankarana Seva */}
                    <label>
                      <input
                        type="checkbox"
                        name="alankaranaSeva"
                        checked={formData.alankaranaSeva}
                        onChange={handleInputChange}
                        style={{ accentColor: "blue" }}
                      />
                      Alankarana Seva
                    </label>
                    {formData.alankaranaSeva && (
                      <div className="grid grid-cols-2 -mt-4">
                        {[15000, 10000, 5000].map((amount) => (
                          <button
                            className="col-span-1 font-semibold p-3 bg-[#F9E7D0] rounded-xl text-black"
                            type="button"
                            key={amount}
                            onClick={() =>
                              handleAmountSelect("alankarana", amount)
                            }
                            style={{
                              backgroundColor:
                                formData.selectedAlankaranaAmount === amount
                                  ? "#fac785"
                                  : "",
                              margin: "5px",
                            }}
                          >
                            ₹ {amount.toLocaleString("en-IN")}
                          </button>
                        ))}
                        <input
                          type="number"
                          name="customAlankaranaAmount"
                          placeholder="₹ Enter Amount"
                          value={formData.customAlankaranaAmount || ""}
                          onChange={handleInputChange}
                          className="col-span-2 p-3 border rounded-xl my-1 mx-1 customAmount text-black"
                        />
                      </div>
                    )}
                  </div>

                  <div className="innerForm">
                    <h3 className="text-center">Personal Details</h3>
                    <label className="xl:w-full">
                      <input
                        type="text"
                        name="donorName"
                        value={formData.donorName}
                        onChange={handleInputChange}
                        placeholder="Donor Name"
                        className="mt-2"
                      />
                      {errors.donorName && (
                        <span className="block -mt-2 ml-2">
                          {errors.donorName}
                        </span>
                      )}
                    </label>
                    <label className="xl:w-full">
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        placeholder="Email ID"
                      />
                      {errors.email && (
                        <span className="block -mt-2 ml-2">{errors.email}</span>
                      )}
                    </label>
                    <div className="flex gap-2">
                      <label className="flex-1">
                        <input
                          type="tel"
                          name="mobile"
                          value={formData.mobile}
                          onChange={handleInputChange}
                          placeholder="Mobile Number"
                          className="p-[10px] md:w-full"
                        />
                        {errors.mobile && (
                          <span className="block mt-1 ml-2">
                            {errors.mobile}
                          </span>
                        )}
                      </label>
                      <label className="flex-1">
                        <input
                          type="text"
                          name="dob"
                          value={formData.dob}
                          onChange={handleInputChange}
                          className="text-[#909192] p-2 border rounded-md"
                          placeholder="Date Of Birth"
                          onFocus={(e) => (e.target.type = "date")}
                          onBlur={(e) => (e.target.type = "text")}
                        />
                        {errors.dob && (
                          <span className="block -mt-2 ml-2">{errors.dob}</span>
                        )}
                      </label>
                    </div>
                  </div>
                  <p className="text-white mt-2 text-[10px]">
                    (Sankalpa and Archana will be performed for you on your
                    birthday)
                  </p>

                  <p className="text-white -mt-2 text-xs">
                    By continuing, you are agreeing to{" "}
                    <a className="underline text-[#EEF9A3]">
                      Terms of Use and Privacy Policy
                    </a>
                  </p>

                  <div className="px-3 tandd mt-8">
                    <label>
                      <input
                        type="checkbox"
                        name="receive80G"
                        checked={formData.receive80G}
                        onChange={handle80GCheckboxChange}
                        style={{ accentColor: "blue" }}
                      />
                      I would like to receive 80(G) Certificate
                    </label>
                    {show80GDetails && (
                      <div className="mb-3">
                        <p className="text-white text-[10px] text-left ml-2">
                          Finance Act 2021 mandates uploading donation details
                          for 80(G) deductions. Ensure PAN and address details
                          are provided.
                        </p>
                        <input
                          type="text"
                          name="panNumber"
                          value={formData.panNumber}
                          onChange={handleInputChange}
                          placeholder="Pan Number *"
                          className="w-full bg-white text-black px-4 py-2 rounded-lg outline-none focus:ring-2 focus:ring-yellow-400"
                          required
                        />
                        <input
                          type="text"
                          name="doorNumber"
                          placeholder="Door Number"
                          className="text-black"
                          value={formData.mahaPrasadamDetails.doorNumber}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              mahaPrasadamDetails: {
                                ...prev.mahaPrasadamDetails,
                                doorNumber: e.target.value,
                              },
                            }))
                          }
                        />
                        <input
                          type="text"
                          className="text-black"
                          name="houseName"
                          placeholder="House / Apartment / Building Name"
                          value={formData.mahaPrasadamDetails.houseName}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              mahaPrasadamDetails: {
                                ...prev.mahaPrasadamDetails,
                                houseName: e.target.value,
                              },
                            }))
                          }
                        />
                        <input
                          type="text"
                          className="text-black"
                          name="streetName"
                          placeholder="Street Name"
                          value={formData.mahaPrasadamDetails.streetName}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              mahaPrasadamDetails: {
                                ...prev.mahaPrasadamDetails,
                                streetName: e.target.value,
                              },
                            }))
                          }
                        />
                        <input
                          type="text"
                          className="text-black"
                          name="area"
                          placeholder="Location / Area"
                          value={formData.mahaPrasadamDetails.area}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              mahaPrasadamDetails: {
                                ...prev.mahaPrasadamDetails,
                                area: e.target.value,
                              },
                            }))
                          }
                        />
                        <input
                          type="text"
                          className="text-black"
                          name="country"
                          placeholder="Country"
                          value={formData.mahaPrasadamDetails.country}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              mahaPrasadamDetails: {
                                ...prev.mahaPrasadamDetails,
                                country: e.target.value,
                              },
                            }))
                          }
                        />
                        <input
                          type="text"
                          className="text-black"
                          name="state"
                          placeholder="State"
                          value={formData.mahaPrasadamDetails.state}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              mahaPrasadamDetails: {
                                ...prev.mahaPrasadamDetails,
                                state: e.target.value,
                              },
                            }))
                          }
                        />
                        <input
                          type="text"
                          className="text-black"
                          name="city"
                          placeholder="City"
                          value={formData.mahaPrasadamDetails.city}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              mahaPrasadamDetails: {
                                ...prev.mahaPrasadamDetails,
                                city: e.target.value,
                              },
                            }))
                          }
                        />
                        <input
                          type="text"
                          className="text-black"
                          name="pincode"
                          placeholder="Pincode"
                          value={formData.mahaPrasadamDetails.pincode}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              mahaPrasadamDetails: {
                                ...prev.mahaPrasadamDetails,
                                pincode: e.target.value,
                              },
                            }))
                          }
                        />
                      </div>
                    )}

                    <label>
                      <input
                        type="checkbox"
                        name="receiveMahaPrasadam"
                        checked={formData.receiveMahaPrasadam}
                        onChange={handleMahaPrasadamCheckboxChange}
                        style={{ accentColor: "blue" }}
                      />
                      I would like to receive Maha prasadam (Only within India)
                    </label>
                    {showMahaPrasadamDetails && (
                      <div className="mt-3">
                        <input
                          type="text"
                          name="doorNumber"
                          placeholder="Door Number"
                          className="text-black"
                          value={formData.mahaPrasadamDetails.doorNumber}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              mahaPrasadamDetails: {
                                ...prev.mahaPrasadamDetails,
                                doorNumber: e.target.value,
                              },
                            }))
                          }
                        />
                        <input
                          type="text"
                          className="text-black"
                          name="houseName"
                          placeholder="House / Apartment / Building Name"
                          value={formData.mahaPrasadamDetails.houseName}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              mahaPrasadamDetails: {
                                ...prev.mahaPrasadamDetails,
                                houseName: e.target.value,
                              },
                            }))
                          }
                        />
                        <input
                          type="text"
                          className="text-black"
                          name="streetName"
                          placeholder="Street Name"
                          value={formData.mahaPrasadamDetails.streetName}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              mahaPrasadamDetails: {
                                ...prev.mahaPrasadamDetails,
                                streetName: e.target.value,
                              },
                            }))
                          }
                        />
                        <input
                          type="text"
                          className="text-black"
                          name="area"
                          placeholder="Location / Area"
                          value={formData.mahaPrasadamDetails.area}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              mahaPrasadamDetails: {
                                ...prev.mahaPrasadamDetails,
                                area: e.target.value,
                              },
                            }))
                          }
                        />
                        <input
                          type="text"
                          className="text-black"
                          name="country"
                          placeholder="Country"
                          value={formData.mahaPrasadamDetails.country}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              mahaPrasadamDetails: {
                                ...prev.mahaPrasadamDetails,
                                country: e.target.value,
                              },
                            }))
                          }
                        />
                        <input
                          type="text"
                          className="text-black"
                          name="state"
                          placeholder="State"
                          value={formData.mahaPrasadamDetails.state}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              mahaPrasadamDetails: {
                                ...prev.mahaPrasadamDetails,
                                state: e.target.value,
                              },
                            }))
                          }
                        />
                        <input
                          type="text"
                          className="text-black"
                          name="city"
                          placeholder="City"
                          value={formData.mahaPrasadamDetails.city}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              mahaPrasadamDetails: {
                                ...prev.mahaPrasadamDetails,
                                city: e.target.value,
                              },
                            }))
                          }
                        />
                        <input
                          type="text"
                          className="text-black"
                          name="pincode"
                          placeholder="Pincode"
                          value={formData.mahaPrasadamDetails.pincode}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              mahaPrasadamDetails: {
                                ...prev.mahaPrasadamDetails,
                                pincode: e.target.value,
                              },
                            }))
                          }
                        />
                      </div>
                    )}
                  </div>

                  <div className="flex justify-center mt-8">
                    <button
                      type="submit"
                      className="bg-[#FAE29C] text-black px-12 py-3 rounded-2xl font-bold"
                    >
                      Donate{" "}
                      {getTotalAmount() > 0
                        ? "₹ " + getTotalAmount().toLocaleString("en-IN")
                        : ""}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default RamNavami;