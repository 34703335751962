import React from "react";
import "./page3.css";

const Page3 = () => {
  return (
    <>
      <div className="page3">
        <div className="benefits-section">
          <div className="container">
            <h2 className="benefits-title font-bold">Key Benefits:</h2>

            <div className="benefits-card">
              <ul className="benefits-list">
                <li>
                  <span className="semi-bold">
                    {" "}
                    Personalized training over 64 weeks.
                  </span>
                </li>
                <li>
                  <span className="semi-bold">
                    {" "}
                    Supportive small-group environment.
                  </span>
                </li>
                <li>
                  <span className="semi-bold">
                    {" "}
                    Opportunities for leadership and preaching.
                  </span>
                </li>
                <li>
                  <span className="semi-bold">
                    {" "}
                    Easy to conduct in homes, accessible to all.
                  </span>
                </li>
              </ul>

              <p className="benefits-description">
                The Bhakti-Vriksha program aims to grow Krishna consciousness by
                empowering devotees to practice, preach, and create new
                groups—spreading Lord Caitanya’s message far and wide.
              </p>

              {/* <p className="benefits-cta">
                Join Us And Be A Part Of This Growing Tree Of Devotion!
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Page3;
