import "./style.css";
import React, { useEffect } from "react";
import Footer from "../../../Components/Footer/Footer";
import Header from "../../../Components/Header/Header";
import background from "./assets/background.webp";
import headingImg from "./assets/firstThreeCombinedDesktopBg.webp";
import gitaImg from "./assets/gita.webp";
import vishnuImg from "./assets/vishnu.webp";
import top from "./assets/floral-lotus-mandala-clipart-black-vector-art-600x600-removebg-preview 1.webp";
import bottom from "./assets/floral-lotus-mandala-clipart-black-vector-art-600x600-removebg-preview 2.webp";
import { Helmet } from "react-helmet";


function GitaForYou() {
  useEffect(() => {
    // Scroll to top on component mount
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        {/* Primary Meta Tags */}
        <title>
          Learn Bhagavad Gita Teachings | ISKCON Vijayawada Discourses
        </title>
        <meta
          name="description"
          content="Explore Krishna’s teachings from the Bhagavad Gita. Join spiritual discourses at ISKCON Vijayawada and deepen your understanding of Vedic wisdom."
        />
        <meta
          name="keywords"
          content="Bhagavad Gita teachings, ISKCON discourses, spiritual wisdom, Krishna philosophy"
        />
        <meta name="author" content="ISKCON Vijayawada" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.iskconvijayawada.org/GitaForYou"
        />
        <meta
          property="og:title"
          content="Learn Bhagavad Gita Teachings | ISKCON Vijayawada Discourses"
        />
        <meta
          property="og:description"
          content="Explore Krishna’s teachings from the Bhagavad Gita. Join spiritual discourses at ISKCON Vijayawada and deepen your understanding of Vedic wisdom."
        />
        <meta property="og:image" content={gitaImg} />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Learn Bhagavad Gita Teachings | ISKCON Vijayawada Discourses"
        />
        <meta
          name="twitter:description"
          content="Explore Krishna’s teachings from the Bhagavad Gita. Join spiritual discourses at ISKCON Vijayawada and deepen your understanding of Vedic wisdom."
        />
        <meta name="twitter:image" content={gitaImg} />
      </Helmet>

      <Header />

      <div className="w-full relative md:mt-12">
        {/* Background Pattern Container */}
        <div
          className="absolute inset-0 bg-cover bg-no-repeat bg-center -z-30"
          style={{ backgroundImage: `url(${headingImg})` }}
        />

        {/* Content Container */}
        <div className="relative z-10 max-w-[100%] md:max-w-[90%] mx-auto px-4 py-2">
          {/* Seminar Badge */}
          <div className="flex justify-center mb-8">
            <div className="bg-red text-white px-8 py-3 rounded-b-xl text-xl md:text-2xl font-bold border-2 border-white">
              GITA4YOU
            </div>
          </div>

          {/* Main Title */}
          <div className="text-center mb-12">
            <h2 className="text-2xl md:text-6xl font-semibold text-red mb-1">
              Explore{" "}
              <span className="font-bold">
                The Timeless Wisdom Of The Bhagavad Gita
              </span>{" "}
              in Just 18 Days
            </h2>
          </div>

          <div className="flex flex-col md:grid md:grid-cols-12 gap-6 mb-8">
            {/* Image - Full width on mobile (top) and 4 columns on desktop (right) */}
            <div className="md:col-span-4 md:order-last mb-6 md:mb-0">
              <img
                loading="lazy"
                src={gitaImg}
                alt="Bhagavad Gita Course"
                className="w-full rounded-lg object-cover shadow-[18px_20px_10px_#e09c89,_0_6px_6px_rgba(0,0,0,0.1),_0_0_0_1px_rgba(255,228,214,0.7)]"
              />
            </div>

            {/* Text - Full width on mobile (bottom) and 8 columns on desktop (left) */}
            <div className="md:col-span-8 flex">
              <p className="text-xl md:text-3xl 2xl:text-4xl leading-relaxed text-gray-800 text-justify flex items-center">
                ISKCON Vijayawada brings you the 18-Day Gita4You Crash Course,
                designed for individuals seeking a deeper understanding of life
                through the teachings of the Bhagavad Gita. This course will
                simplify the essence of each chapter, helping you apply its
                lessons to your everyday life.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="min-h-screen relative">
        <div
          className="absolute inset-0 bg-cover bg-no-repeat bg-center opacity-70 -z-30"
          style={{ backgroundImage: `url(${background})` }}
        />
        <div className="overflow-hidden w-full box-border px-2 md:px-4 py-6">
          <div className="text-center">
            <h2 className="text-3xl md:text-5xl font-semibold text-red my-6 md:mb-10">
              Why Join the Course?
            </h2>
          </div>
          <div className="max-w-[100%] md:max-w-[90%]  mx-auto overflow-hidden rounded-xl relative">
            {/* Base card with image background */}
            <div className="relative">
              {/* Background gradient overlay */}
              <div className="absolute inset-0  md:bg-gradient-to-r from-transparent to-[#E78B1C] bg-gradient-to-b z-10"></div>

              {/* Main content container */}
              <div className="relative flex flex-col md:flex-row">
                {/* Left side - Image with fade effect */}
                <div className="md:w-1/2 relative">
                  <div className="w-full h-full gradient-mask">
                    <img
                      loading="lazy"
                      src={vishnuImg}
                      alt="Krishna and Arjuna"
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>

                {/* Right side - Content with overlap */}
                <div className="p-8 md:w-1/2 flex flex-col justify-center relative z-20 md:-ml-16">
                  <h2 className="text-xl md:text-3xl font-bold mb-6 ">
                    <span>The </span>
                    <span className="text-red">Bhagavad Gita</span>
                    <span>
                      {" "}
                      Is More Than Just A Spiritual Text; It's A Guide For:
                    </span>
                  </h2>

                  <ul className="space-y-4 text-lg md:text-2xl !pl-0">
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span className="font-medium">
                        Self-Discovery And Personal Growth.
                      </span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span className="font-medium">
                        Achieving Inner Peace And Clarity In Decision-Making.
                      </span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span className="font-medium">
                        Understanding The Deeper Meaning Of Karma, Devotion, And
                        Knowledge.
                      </span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span className="font-medium">
                        Finding Balance In Work, Relationships, And Personal
                        Well-Being.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="relative bg-[#c61d21] text-white min-h-[30vh] overflow-hidden w-full box-border px-2 md:px-4 py-4">
          <div className="relative z-10 max-w-[100%] md:max-w-[85%] mx-auto">
            <div className="w-full">
              <h1 className="text-center font-['Source_Serif_4'] font-semibold text-[#f3b01b] mb-3 text-[32px] leading-[1.2] sm:text-[54px] lg:text-[70px]">
                What You’ll Learn
              </h1>
              <ul className="list-none grid md:justify-center  mx-auto my-2 px-4 md:px-5 leading-[1.5] text-[#FFDEBD] text-[16px]  sm:text-[26px] sm:leading-[1.8] sm:px-10 lg:text-[32px] lg:leading-[2] lg:my-[30px] lg:max-w-[1600px]">
                <li className="my-[10px] sm:my-[10px]">
                  <span className="text-[#f3b01b] mt-1">✦</span> Over 18 days,
                  you’ll delve into one chapter per day, covering
                </li>
                <li className="my-[10px] sm:my-[10px]">
                  <span className="text-[#f3b01b] mt-1">✦</span> Who we truly
                  are and our purpose in this world.
                </li>
                <li className="my-[10px] sm:my-[10px]">
                  <span className="text-[#f3b01b] mt-1">✦</span> Karma and how
                  our actions shape our future.
                </li>
                <li className="my-[10px] sm:my-[10px]">
                  <span className="text-[#f3b01b] mt-1">✦</span> The power of
                  devotion and spiritual knowledge.
                </li>
                <li className="my-[10px] sm:my-[10px]">
                  <span className="text-[#f3b01b] mt-1">✦</span> How to develop
                  a peaceful mind and face challenges with strength.
                </li>
                <li className="my-[10px] sm:my-[10px]">
                  <span className="text-[#f3b01b] mt-1">✦</span> Practical tools
                  for applying Gita’s teachings in daily life.
                </li>
              </ul>
            </div>
          </div>
          <div className="absolute top-0 left-0 right-0 bottom-0 pointer-events-none">
            <img
              loading="lazy"
              className="absolute top-[-25px] left-[-25px] w-[140px] h-auto opacity-100 sm:top-[-50px] sm:left-[-50px] sm:w-[280px] lg:top-[-75px] lg:left-[-75px] lg:w-[420px]"
              src={top}
              alt="Top Left Design"
              loading="lazy"
            />
            <img
              loading="lazy"
              className="absolute bottom-[-28px] right-[-20px] w-[233px] h-auto opacity-100 sm:bottom-[-56px] sm:right-[-39px] sm:w-[466px] lg:bottom-[-84px] lg:right-[-58px] lg:w-[699px]"
              src={bottom}
              alt="Bottom Right Design"
              loading="lazy"
            />
          </div>
        </div>

        <div className="max-w-[100%] md:max-w-[87%] mx-auto space-y-8 py-4 px-2">
          {/* Our Programs Section */}
          <div className="text-center">
            <h2 className="text-3xl md:text-5xl font-semibold text-red mb-4">
              Course Highlights
            </h2>
          </div>

          {/* Weekend Programs Box */}
          <ul className="space-y-2 list-disc pl-6 mb-6 text-xl md:text-3xl">
            <li>
              <span className="font-bold">Daily Lessons:</span> One chapter per
              day with clear and practical insights.
            </li>
            <li>
              <span className="font-bold">Interactive Learning:</span> Engage
              with experienced instructors for guidance and Q&A. sessions
              available
            </li>
            <li>
              <span className="font-bold">Accessible Format:</span> Simple
              explanations of deep spiritual concepts.
            </li>
            <li>
              <span className="font-bold">Study Materials: </span> Supportive
              resources to enhance your understanding
            </li>
          </ul>
        </div>

        <div className="max-w-[100%] md:max-w-[90%] mx-auto space-y-8 md:py-4 px-2">
          {/* Our Programs Section */}
          <div className="text-center">
            <h2 className="text-3xl md:text-5xl font-semibold text-red mb-4">
              Who Can Join?
            </h2>
          </div>

          <p className="mb-4 font-semibold text-xl md:text-3xl text-center">
            Students, professionals, and spiritual seekers eager to explore the
            Gita. Anyone looking for clarity, balance, and a path to personal
            transformation.
          </p>
        </div>

        {/* Main Content Container */}
        <div className="max-w-[100%] md:max-w-[90%] mx-auto space-y-8 py-4 px-2">
          {/* Our Programs Section */}
          <div className="text-center">
            <h2 className="text-3xl md:text-5xl font-semibold text-red mb-4">
              Course Details
            </h2>
          </div>

          {/* Weekend Programs Box */}
          <div className="border-2 border-red rounded-lg p-6 md:p-12 bg-[#f4dec6] shadow-md">
            <ul className="space-y-2 list-disc pl-6 mb-6 font-semibold text-xl md:text-3xl">
              <li>
                <span className="font-bold">Duration:</span> 18 days
              </li>
              <li>
                <span className="font-bold">Mode:</span> Online and in-person
                sessions available
              </li>
              <li>
                <span className="font-bold">Cost:</span> Absolutely free
              </li>
              <li>
                <span className="font-bold">Instructors:</span> Led by seasoned
                spiritual teachers from ISKCON Vijayawada
              </li>
            </ul>

            <p className="mb-4 text-red font-bold text-xl md:text-2xl">
              Join the 18-Day Gita4You Crash Course and embark on a journey of
              spiritual growth and self-realization.
            </p>
            <a href="https://www.iskconvjagita4you.org/">
              <button
                variant="destructive"
                size="lg"
                className="bg-[#C61D21] text-lg md:text-3xl text-white font-semibold rounded-lg hover:bg-[#a01830]  px-8 py-2"
              >
                REGISTER NOW
              </button>
            </a>

            <span className="text-red font-bold pl-4 block sm:inline">
              Seats are limited!
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default GitaForYou;
