import img1 from "./assets/icons/1.webp";
import img2 from "./assets/icons/2.webp";
import img3 from "./assets/icons/3.webp";
import img4 from "./assets/icons/4.webp";
import img5 from "./assets/icons/5.webp";
import img6 from "./assets/icons/6.webp";
import img7 from "./assets/icons/7.webp";
import img8 from "./assets/icons/8.webp";
import arrow from "./assets/icons/arrow.webp";

const datas = [
  // the name of the icon images are not in order but are proper beacuse changes were made in order of these cards by clients
  
  {
    id: 1,
    img: img6,
    content: "GET CONNECTED TO ISKCON",
    arrow: arrow,
  },
  {
    id: 2,
    img: img1,
    content: " SPEAK TO SENIOR MENTOR",
    arrow: arrow,
  },
  {
    id: 3,
    img: img3,
    content: "REQUEST KRISHNA PRASAD",
    arrow: arrow,
  },
  {
    id: 4,
    img: img7,
    content: "BOOK YOUR STAY ROOM",
    arrow: arrow,
  },
  {
    id: 5,
    img: img2,
    content: "BOOK A KIRTAN AT HOME",
    arrow: arrow,
  },

  {
    id: 6,
    img: img4,
    content: "BOOK A YAGNA",
    arrow: arrow,
  },
  {
    id: 7,
    img: img8,
    content: "STEPS TO BHAKTI",
    arrow: arrow,
  },
  {
    id: 8,
    img: img5,
    content: "NEED HD QUALITY PHOTOS & VIDEOS OF ISKCON",
    arrow: arrow,
  },

];

export default datas;
