import React from "react";
import "./heading.css";

export const Heading = () => {
  return (
    <>
      <div className="heading-upper">
        <div className="name">
          <div className="BHAKTI-SASHTRI">BHAKTI VRIKSHA</div>
        </div>
      </div>
      <div className="flex-col text-center">
        <h1 className="items-center md:text-[68px] text-[20px] mt-3 text-red font-semibold">
          Bhakti-Vriksha at ISKCON Vijayawada:
        </h1>
        <h1 className="md:text-[68px] text-[20px] text-red font-bold">
          Growing in Devotion
        </h1>
      </div>
    </>
  );
};
