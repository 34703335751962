import React, { useEffect } from "react";
import "./About.css";
import "@fontsource/source-sans-pro";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import MainBanner from "./components/MainBanner/MainBanner";
import img from "./assets/images/img.webp";
import Banner from "./components/Banner/Banner";
import List from "./components/List/List";
import { Helmet } from "react-helmet";

const About = () => {
  useEffect(() => {
    // Scroll to top on component mount
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>
          About ISKCON Vijayawada | History, Mission & Spiritual Journey
        </title>
        <meta
          name="description"
          content="Discover the history and mission of ISKCON Vijayawada. Learn about Krishna Consciousness, temple activities, and how we serve the spiritual community."
        />
        <meta
          name="keywords"
          content="ISKCON Vijayawada, Krishna temple history, about ISKCON, temple mission, spiritual journey"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="ISKCON Vijayawada" />
        <meta
          property="og:title"
          content="About ISKCON Vijayawada | History, Mission & Spiritual Journey"
        />
        <meta
          property="og:description"
          content="Discover the history and mission of ISKCON Vijayawada. Learn about Krishna Consciousness, temple activities, and how we serve the spiritual community."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.iskconvijayawada.org/about"
        />
        <meta
          property="og:image"
          content="https://www.iskconvijayawada.org/static/media/logo.467e4780dde7b11ae4cf.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="About ISKCON Vijayawada | History, Mission & Spiritual Journey"
        />
        <meta
          name="twitter:description"
          content="Discover the history and mission of ISKCON Vijayawada. Learn about Krishna Consciousness, temple activities, and how we serve the spiritual community."
        />
        <meta
          name="twitter:image"
          content="https://www.iskconvijayawada.org/static/media/logo.467e4780dde7b11ae4cf.png"
        />
      </Helmet>
      <Header />
      <div className="about-page md:mt-8">
        <MainBanner />
        <div className="d-flex justify-content-center p-4 ">
          <img loading="lazy" src={img} alt="About" className="about" />
        </div>
        <Banner />
        <List />
      </div>
      <Footer />
    </>
  );
};

export default About;
