import React, { useEffect, useState } from "react";
import Slider1 from "./Slider1";
import "../SundayFeast/sundayfeast.css";
import img5 from "./assets/img5.webp";
import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer/Footer";
import img1 from "./assets/img1.webp";
import img2 from "./assets/img2.webp";
import img3 from "./assets/img3.webp";
import img4 from "./assets/img4.webp";
import { CgMail } from "react-icons/cg";
import Slider2 from "./Slider2";
function SaturdaySatsang() {
  // form data Storing
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    specialNote: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormData({
      name: "",
      email: "",
      phone: "",
      address: "",
      specialNote: "",
    });
    // For future use
  };
  useEffect(() => {
    // Scroll to top on component mount
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className=" w-full">
      <Header />

      <div className="flex flex-col justify-center items-center card1 bg-cover bg-center md:mt-8">
        <div className="flex justify-center mb-8 md:mt-4">
          <div className="bg-red text-white px-8 py-3 rounded-b-xl text-2xl font-bold border-2 border-white">
            SATURDAY SATSANG
          </div>
        </div>
        <h1 className="text-center text-[#c61d21] text-2xl lg:text-6xl font-bold font-['Open Sans'] sunday lg:my-7">
          Saturday Satsang - ISKCON Sri Jagannath Mandir
        </h1>
        <div className="flex items-center  flex-col lg:flex-row mt-1 lg:mt-7 ">
          <img
            loading="lazy"
            src={img5}
            className="w-[304px] h-[193px] lg:w-[850px] lg:h-[540px] "
            alt=""
          />
          <div className="flex flex-col  justify-start items-start lg:h-[500px] ">
            <p className="w-[350px] h-[29.16px] lg:w-[653px] lg:h-[85px]  text-[#c61d21] text-2xl lg:text-5xl font-semibold font-['Source Serif Pro'] capitalize text-center">
              Join Us for Saturday Satsang!
            </p>

            <p className="w-[350px] lg:w-[591px] lg:h-[313px] text-black lg:text-4xl font-light font-['Inter'] capitalize text-base text-justify">
              At{" "}
              <strong className="font-semibold">
                ISKCON Sri Jagannath Mandir,
              </strong>{" "}
              we invite you to our Saturday Satsang, a spiritual gathering
              designed to uplift your soul and deepen your connection with
              Krishna. This is a wonderful opportunity to engage in devotional
              practices and connect with fellow devotees.
            </p>
          </div>
        </div>
        <p className="w-[350px] lg:w-[85rem] text-[#c61d21] text-base lg:text-4xl font-bold font-['Inter'] text-justify lg:text-start lg:my-16 mx-auto">
          It is an opportunity to come together as a community, nourish our
          bodies and souls, and immerse ourselves in the teachings of Lord
          Krishna
        </p>
      </div>

      <Slider1 />
      <Slider2 />

      <div className="w-full bg2 lg:bg-cover bg-center py-10 bg-contain">
        {/* Shehedule Of Event */}
        <div className="w-[382px] lg:w-[89.93rem] h-[317px] lg:h-[31.43rem] flex flex-col gap-2 lg:gap-12 items-center justify-center px-14 lg:py-10 bg-[#fff1df]/40 border-2 rounded-[5.19px] border-[#bc3521] backdrop-blur-[80.60px] mx-auto lg:mb-32 mb-10 lg:mt-20">
          <h2 className="text-3xl lg:text-6xl text-[#c61d21] font-bold lg:font-semibold font-['Source Serif'] lg:mt-10">
            Schedule of Events
          </h2>
          <div className="flex items-center justify-center gap-1 lg:gap-8  flex-col lg:flex-row ">
            <img
              loading="lazy"
              src={img1}
              className="w-[250px] lg:w-[27.93rem] h-[140px] lg:h-[16.31rem] "
              alt=""
            />
            <ul className="w-[339px] lg:w-[50rem] list-disc text-start ">
              <li className=" lg:m-2 my-1 ">
                <p className="font-normal text-black font-['Open Sans'] lg:leading-[3.125rem] capitalize text-base lg:text-4xl text-start m-0">
                  <strong className="  font-bold  ">Time : </strong>
                  Every Saturday from 6 PM to 9 PM.
                </p>
              </li>
              <li className="lg:m-2 my-1">
                <p className=" font-normal text-black font-['Open Sans'] lg:leading-[3.125rem] capitalize text-base lg:text-4xl text-start m-0">
                  <strong className=" font-bold  ">Location : </strong>
                  ISKCON Sri Jagannath Mandir
                </p>
              </li>
            </ul>
          </div>
        </div>

        {/* Why Attend  */}
        <div className="w-full h-[1650px] lg:h-[56rem] bg-[#c61d21] lg:py-10 py-7 lg:mx-auto">
          <h2 className="text-[#f3b01b] text-4xl lg:text-[4.25rem] font-semibold font-['Source Serif Pro'] capitalize text-center lg:my-5">
            Why Attend?
          </h2>
          <div className="flex flex-col lg:flex-row gap-6 items-center justify-center mt-7 lg:mt-[68px]">
            <div className="w-full lg:w-[41.68rem] flex-col flex justify-center items-center gap-6 lg:gap-14 ">
              <img
                loading="lazy"
                src={img2}
                alt=""
                className=" lg:w-[26.625rem] h-[342.95px] lg:h-[29.93rem]"
              />
              <div className="w-[305px] lg:w-[26.625rem] h-[8.25rem] flex-col justify-start items-start lg:gap-1.5 flex">
                <p className="self-stretch text-justify text-[#f3b01b] text-2xl lg:text-4xl font-bold font-['Source Serif Pro'] capitalize">
                  Spiritual Upliftment
                </p>
                <p className="self-stretch text-justify text-white text-base lg:text-xl font-semibold font-['Open Sans'] capitalize">
                  Engage in practices that enhance your spiritual growth and
                  bring peace to your mind and heart.
                </p>
              </div>
            </div>

            <div className="w-full lg:w-[41.68rem] flex-col flex justify-center items-center gap-6 lg:gap-14 ">
              <img
                loading="lazy"
                src={img3}
                alt=""
                className="w-[305px] lg:w-[26.625rem] h-[342.95px] lg:h-[29.93rem]"
              />
              <div className="w-[305px] lg:w-[26.625rem] h-[8.25rem] flex-col justify-start items-start lg:gap-1.5 flex">
                <p className="self-stretch text-justify text-[#f3b01b] text-2xl lg:text-4xl font-bold font-['Source Serif Pro'] capitalize">
                  Community Connection
                </p>
                <p className="self-stretch text-justify text-white text-base lg:text-xl font-semibold font-['Open Sans'] capitalize">
                  Connect with fellow devotees and build lasting friendships in
                  a warm and welcoming environment.
                </p>
              </div>
            </div>
            <div className="w-full lg:w-[41.68rem] flex-col flex justify-center items-center gap-6 lg:gap-14 ">
              <img
                loading="lazy"
                src={img4}
                alt=""
                className="w-[305px] lg:w-[26.625rem] h-[342.95px] lg:h-[29.93rem]"
              />
              <div className="w-[305px] lg:w-[26.625rem] h-[8.25rem] flex-col justify-start items-start lg:gap-1.5 flex">
                <p className="self-stretch text-justify text-[#f3b01b] text-2xl lg:text-4xl font-bold font-['Source Serif Pro'] capitalize">
                  Service Opportunity
                </p>
                <p className="self-stretch text-justify text-white text-base lg:text-xl font-semibold font-['Open Sans'] capitalize">
                  Participate in Annadaan and experience the joy of serving
                  others.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Join Us Section */}
        <div className="w-[97%] lg:w-[85%] flex flex-col justify-center mt-10 mx-auto rounded-md border-2 border-red md:text-3xl">
          <h2 className="text-center bg-red text-white font-bold text-lg p-2 md:p-8 md:text-4xl lg:mb-5">
            Join Us And Be A Part Of This Growing Tree Of Devotion!
          </h2>
          <p className="text-sm lg:text-3xl p-2 lg:mb-8 lg:text-center font-semibold">
            Be a part of our Saturday Satsang at ISKCON Sri Jagannath Mandir.
            Together, let us chant, learn, and serve in the spirit of devotion.
          </p>

          <div className="grid grid-cols-2 gap-4 p-1 lg:w-[90%] lg:mb-5 mx-auto">
            <div>
              <label className="block font-medium mb-1 ">Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full lg:w-[90%] bg-transparent p-2 border-2 border-solid border-gray-300 lg:border-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-red"
              />
            </div>
            <div>
              <label className="block font-medium mb-1">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full lg:w-[90%] p-2 bg-transparent  border-2 border-solid border-gray-300 lg:border-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-red"
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4 p-1 lg:w-[90%] lg:mb-5 mx-auto">
            <div>
              <label className="block font-medium mb-1 ml-1 ">Phone</label>
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="w-full lg:w-[90%] p-2 bg-transparent  border-2 border-solid border-gray-300 lg:border-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-red"
              />
            </div>
            <div>
              <label className="block font-medium mb-1">Address</label>
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                className="w-full lg:w-[90%] p-2 bg-transparent  border-2 border-solid border-gray-300 lg:border-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-red"
              />
            </div>
          </div>

          <div className="p-1 lg:w-[90%] mx-auto">
            <label className="block font-medium mb-1 ml-1">Special Note</label>
            <textarea
              name="specialNote"
              value={formData.specialNote}
              onChange={handleChange}
              className="w-full lg:mb-5 p-2 bg-transparent border-2 border-solid border-gray-300 lg:border-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-red"
              rows="3"
            ></textarea>
          </div>

          <button
            onClick={handleSubmit}
            className="w-full lg:w-[90%] mx-auto lg:mb-5 bg-red lg:text-4xl text-white font-bold py-2 px-2 lg:rounded-md"
          >
            Submit
          </button>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default SaturdaySatsang;
