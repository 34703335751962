import React, { useState } from "react";
import logo from "./assets/images/logo.png";
import { NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import "./Header.css";

const Header = () => {
  const navigate = useNavigate();


  const [submenu, setsubmenu] = useState({
    menu1: false,
    menu2: false,
    menu3: false,
    menu4: false,

  });

  const toggleMenu = (e, menu) => {
    e.stopPropagation();
    setsubmenu((prevMenus) => ({
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      [menu]: !prevMenus[menu],
    }));
  };



  return (
    <>
      <div className="d-flex pb-2 justify-content-between header sticky top-0 z-50">
        <NavLink to="/">
          <img
            loading="lazy"
            src={logo}
            alt="Iskcon Logo"
            className="iskcon-logo"
          />
        </NavLink>
        <div className="d-flex gap-2 align-items-center">
          <button
            onClick={() => {
              navigate("/QuickDonate");
              window.scrollTo(0, 0);
            }}
            className="btn donateBtn ml-auto"
          >
            QUICK DONATE
          </button>
          <button
            className="navbar-toggler bolck md:hidden"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <FontAwesomeIcon
                icon={faBars}
                style={{ color: "white", fontSize: "20px" }}
              />
            </span>
          </button>
        </div>
      </div>

      <div className="fixed w-full z-50 mob">
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <div className="pt-2 pb-2 px-4 nav-bar">
            <NavLink exact to="/" className="nav-link" activeClassName="active">
              HOME
            </NavLink>

            {/* ABOUT */}
            <button
              className="nav-link"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#hiddendata"
              aria-controls="hiddendata"
              aria-expanded="false"
            >
              ABOUT
              <span className="float-right">+</span>

            </button>
            <ul className="collapse pl-0 pb-0" id="hiddendata">
              <NavLink
                to="/about"
                className="nav-link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">ABOUT ISKCON</span>
                </li>
              </NavLink>
              <NavLink
                to="/founder"
                className="nav-link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">FOUNDER ACHARYA</span>
                </li>
              </NavLink>
              <NavLink
                to="/philosophy"
                className="nav-link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">OUR PHILOSOPHY</span>

                </li>
              </NavLink>
              <NavLink
                to="/mission"
                className="nav-link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">OUR MISSION</span>
                </li>
              </NavLink>
              <NavLink
                to="/more-about-iskcon"
                className="nav-link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">MORE ABOUT ISKCON</span>
                </li>
              </NavLink>
            </ul>

            {/* Temple  */}
            <button
              className="nav-link"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#hiddendata1"
              aria-controls="hiddendata1"
              aria-expanded="false"
            >
              TEMPLE
              <span className="float-right">+</span>

            </button>
            <ul className="collapse pl-0 pb-0" id="hiddendata1">
              <NavLink to="/arti" className="nav-link" activeClassName="active">
                <li>
                  <span className="dropdown-item">AARTI</span>
                </li>
              </NavLink>
              <NavLink
                to="/SaturdaySatsang"
                className="nav-link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">SATURADAY SATSANG</span>
                </li>
              </NavLink>
              <NavLink
                to="/SundayFeast"
                className="nav-link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">Sunday Feast</span>
                </li>
              </NavLink>
            </ul>

            {/* OUR PROGRAM */}
            <button
              className="nav-link"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#hiddendata2"
              aria-controls="hiddendata2"
              aria-expanded="false"
            >
              OUR PROGRAM

              <span className="float-right">+</span>
            </button>
            <ul className="collapse pl-0 pb-0" id="hiddendata2">

              <li className="nav-link">
                <span className="dropdown-item" onClick={(e) => toggleMenu(e, "menu1")} >YOUTH PREACHING <span className="ml-2" style={{ float: "right" }}> {!submenu.menu1 ? "+" : "x"} </span> </span>
              </li>

              <div className={submenu.menu1 ? "disblock" : "disnone"} >
                <NavLink
                  to="/YouthSeminar"
                  className="nav-link"
                  activeClassName="active"
                >
                  <li>
                    <span className="dropdown-item">YOUTH SEMINAR</span>
                  </li>
                </NavLink>
                <NavLink
                  to="/Counselling"
                  className="nav-link"
                  activeClassName="active"
                >
                  <li>
                    <span className="dropdown-item">COUNSELLING</span>
                  </li>
                </NavLink>
              </div>

              <li className="nav-link">
                <span className="dropdown-item" onClick={(e) => toggleMenu(e, "menu2")}>CONGREGATION PREACHING  <span className="ml-2" style={{ float: "right" }}> {!submenu.menu2 ? "+" : "x"} </span>   </span>

              </li>
              <div className={submenu.menu2 ? "disblock" : "disnone"}>
                <NavLink
                  to="/BhaktiVriksha"
                  className="nav-link"
                  activeClassName="active"
                >
                  <li>
                    <span className="dropdown-item">BHAKTI VRIKSH</span>
                  </li>
                </NavLink>
                <NavLink
                  to="/BhaktiSastri"
                  className="nav-link"
                  activeClassName="active"
                >
                  <li>
                    <span className="dropdown-item">BHAKTI SASTRI</span>
                  </li>
                </NavLink>
              </div>

              <li className="nav-link">
                <span className="dropdown-item" onClick={(e) => toggleMenu(e, "menu3")}>BHAGVAD GITA COURSES  <span className="ml-2" style={{ float: "right" }}> {!submenu.menu3 ? "+" : "x"} </span> </span>

              </li>
              <div className={submenu.menu3 ? "disblock" : "disnone"}>
                <NavLink
                  to="/GitaForYou"
                  className="nav-link"
                  activeClassName="active"
                >
                  <li>
                    <span className="dropdown-item">GITA FOR YOU</span>
                  </li>
                </NavLink>
              </div>

              <li className="nav-link">
                <span className="dropdown-item" onClick={(e) => toggleMenu(e, "menu4")}>CHILDREN PREACHING  <span className="ml-2" style={{ float: "right" }}> {!submenu.menu4 ? "+" : "x"} </span> </span>


              </li>
              <div className={submenu.menu4 ? "disblock" : "disnone"}>
                <NavLink
                  to="/GopalaFunSchool"
                  className="nav-link"
                  activeClassName="active"
                >
                  <li>
                    <span className="dropdown-item">GOPALA FUN SCHOOL</span>
                  </li>
                </NavLink>
              </div>



              <NavLink
                to="/CorporateProgrammes"
                className="nav-link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">CORPORATE PROGRAMMES</span>
                </li>
              </NavLink>

             

              <NavLink to="/SocialWork" className="nav-link" activeClassName="active">
                <li>
                  <span className="dropdown-item">SOCIAL WORK</span>
                </li>
              </NavLink>
             
            </ul>

            {/*DONATE  */}
            <button
              className="nav-link"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#hiddendata3"
              aria-controls="hiddendata3"
              aria-expanded="false"
            >
              DONATE
              <span className="float-right">+</span>

            </button>
            <ul className="collapse pl-0 pb-0" id="hiddendata3">
              <NavLink
                to="/LifeMembership"
                className="nav-link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">LIFE MEMBERSHIP</span>
                </li>
              </NavLink>
              <NavLink
                to="/nitya-seva"
                className="nav-link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">NITYA SEVA</span>
                </li>
              </NavLink>
              <NavLink
                to="/GauSeva"
                className="nav-link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">GAU SEVA</span>
                </li>
              </NavLink>
              <NavLink
                to="/food-for-life"
                className="nav-link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">FOOD FOR LIFE ANNADAAN</span>
                </li>
              </NavLink>
              {/* <NavLink to="/" className="nav-link" activeClassName="active">
                <li>
                  <span className="dropdown-item">VIGRAH SEVA</span>
                </li>
              </NavLink> */}
              {/* <NavLink
                to="/VaikuntaEkadashi"
                className="nav-link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">Vaikunt Ekadashi</span>
                </li>
              </NavLink> */}
              <NavLink
                to="/MahaKumbhMela"
                className="nav-link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">MAHA KUMBH MELA</span>
                </li>
              </NavLink>
              {/* <NavLink
                to="/gita4You"
                className="nav-link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">Gita 4 You</span>
                </li>
              </NavLink> */}
              {/* <NavLink
                to="/anniversary"
                className="nav-link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">23rd Anniversary</span>
                </li>
              </NavLink> */}
              <NavLink
                to="/GauraPurnima"
                className="nav-link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">GAURA PURNIMA</span>
                </li>
              </NavLink>
              <NavLink
                to="/RamNavami"
                className="nav-link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">RAM NAVAMI</span>
                </li>
              </NavLink>
            </ul>

            <NavLink to="#" className="nav-link" activeClassName="active">
              SHOP
            </NavLink>

            {/* SAMSKARA */}
            <button
              className="nav-link"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#hiddendata4"
              aria-controls="hiddendata4"
              aria-expanded="false"
            >
              SAMSKARA
              <span className="float-right">+</span>

            </button>

            <ul className="collapse pl-0 pb-0" id="hiddendata4">
              <NavLink
                to="/kirtan-booking"
                className="nav-link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">BOOK A KIRTAN</span>
                </li>
              </NavLink>
              <NavLink
                to="/yagna-booking"
                className="nav-link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">BOOK A YAGNA</span>
                </li>
              </NavLink>
            </ul>
          </div>
        </div>
      </div>

      {/* <div className="desktop"> */}
      <div className="fixed w-full z-50 desktop hidden md:block">
        <div className="pt-2 pb-2 nav-bar">
          <NavLink exact to="/" className="nav-link" activeClassName="active">
            HOME
          </NavLink>
          <div className="nav-item dropdown">
            <div
              className="nav-link dropdown-toggle"
              id="programDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              ABOUT
            </div>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
              <NavLink
                to="/about"
                className="nav-Link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">ABOUT ISKCON</span>
                </li>
              </NavLink>
              <NavLink
                to="/founder"
                className="nav-Link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">FOUNDER ACHARYA</span>
                </li>
              </NavLink>
              <NavLink
                to="/philosophy"
                className="nav-Link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">OUR PHILOSOPHY</span>
                </li>
              </NavLink>
              <NavLink
                to="/mission"
                className="nav-Link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">OUR MISSION</span>
                </li>
              </NavLink>
            </ul>
          </div>

          <div className="nav-item dropdown">
            <div
              className="nav-link dropdown-toggle"
              id="programDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              TEMPLE
            </div>

            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
              <NavLink to="/Arti" className="nav-link" activeClassName="active">
                <li>
                  <span className="dropdown-item">AARTI</span>
                </li>
              </NavLink>
              <NavLink
                to="/SaturdaySatsang"
                className="nav-link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">SATURDAY SATSANG</span>
                </li>
              </NavLink>
              <NavLink
                to="/SundayFeast"
                className="nav-link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">SUNDAY FEAST</span>
                </li>
              </NavLink>
            </ul>
          </div>

          {/* <NavLink to="#" className="nav-link" activeClassName="active">
            TEMPLE
          </NavLink> */}
          {/* <NavLink to="#" className="nav-link" activeClassName="active">
            OUR PROGRAM
          </NavLink> */}
          <div className="nav-item dropdown">
            <div
              className="nav-link dropdown-toggle"
              id="programDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              OUR PROGRAM
            </div>

            <ul className="dropdown-menu" aria-labelledby="programDropdown">

              <li className="dropdown-submenu">

                <span className="dropdown-item">YOUTH PREACHING <span className="float-right">+</span> </span>
                <ul className="dropdown-menu">
                  <NavLink
                    to="/YouthSeminar"
                    className="nav-link"
                    activeClassName="active"
                  >
                    <li>
                      <span className="dropdown-item">YOUTH SEMINAR</span>
                    </li>
                  </NavLink>
                  <NavLink
                    to="/Counselling"
                    className="nav-link"
                    activeClassName="active"
                  >
                    <li>
                      <span className="dropdown-item">COUNSELLING</span>
                    </li>
                  </NavLink>
                </ul>
              </li>

              <li className="dropdown-submenu">
                <span className="dropdown-item">CONGREGATION PREACHING <span className="float-right">+</span> </span>
                <ul className="dropdown-menu">
                  <NavLink
                    to="/BhaktiVriksha"
                    className="nav-link"
                    activeClassName="active"
                  >
                    <li>
                      <span className="dropdown-item">BHAKTI VRIKSH</span>
                    </li>
                  </NavLink>
                  <NavLink
                    to="/BhaktiSastri"
                    className="nav-link"
                    activeClassName="active"
                  >
                    <li>
                      <span className="dropdown-item">BHAKTI SASTRI</span>
                    </li>
                  </NavLink>
                </ul>
              </li>

              <li className="dropdown-submenu">
                <span className="dropdown-item">BHAGVAD GITA COURSES <span className="float-right">+</span>  </span>
                <ul className="dropdown-menu">
                  <NavLink
                    to="/GitaForYou"
                    className="nav-link"
                    activeClassName="active"
                  >
                    <li>
                      <span className="dropdown-item">GITA FOR YOU</span>
                    </li>
                  </NavLink>
                </ul>
              </li>

              <li className="dropdown-submenu">
                <span className="dropdown-item">CHILDREN PREACHING <span className="float-right">+</span> </span>
                <ul className="dropdown-menu">
                  <NavLink
                    to="/GopalaFunSchool"
                    className="nav-link"
                    activeClassName="active"
                  >
                    <li>
                      <span className="dropdown-item">GOPALA FUN SCHOOL</span>
                    </li>
                  </NavLink>
                </ul>
              </li>

              <NavLink
                to="/CorporateProgrammes"
                className="nav-link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">CORPORATE PROGRAMMES</span>
                </li>
              </NavLink>
              <NavLink to="/SocialWork" className="nav-Link" activeClassName="active">
                <li>
                  <span className="dropdown-item">SOCIAL WORK</span>
                </li>
              </NavLink>
              
            </ul>
          </div>
          {/* <NavLink to="#" className="nav-link" activeClassName="active">
            SEVA OPPORTUNITIES
          </NavLink> */}
          <div className="nav-item dropdown">
            <div
              className="nav-link dropdown-toggle"
              id="programDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              DONATE
            </div>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
              <NavLink
                to="/LifeMembership"
                className="nav-Link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">LIFE MEMBERSHIP</span>
                </li>
              </NavLink>
              <NavLink
                to="/nitya-seva"
                className="nav-Link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">NITYA SEVA</span>
                </li>
              </NavLink>
              <NavLink
                to="/GauSeva"
                className="nav-Link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">GAU SEVA</span>
                </li>
              </NavLink>
              <NavLink
                to="/food-for-life"
                className="nav-Link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">FOOD FOR LIFE ANNADAAN</span>
                </li>
              </NavLink>
              {/* <NavLink to="/" className="nav-Link" activeClassName="active">
                <li>
                  <span className="dropdown-item">VIGRAH SEVA</span>
                </li>
              </NavLink> */}
              <NavLink
                to="/MahaKumbhMela"
                className="nav-link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">MAHA KUMBH MELA</span>
                </li>
              </NavLink>
              {/* <NavLink
                to="/VaikuntaEkadashi"
                className="nav-Link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">VAIKUNTA EKADASHI</span>
                </li>
              </NavLink> */}
              {/* <NavLink
                to="/gita4You"
                className="nav-link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">Gita 4 You</span>
                </li>
              </NavLink> */}
              {/* <NavLink
                to="/anniversary"
                className="nav-link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">23rd Anniversary</span>
                </li>
              </NavLink> */}
              <NavLink
                to="/GauraPurnima"
                className="nav-link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">GAURA PURNIMA</span>
                </li>
              </NavLink>
              <NavLink
                to="/RamNavami"
                className="nav-link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">RAM NAVAMI</span>
                </li>
              </NavLink>
            </ul>
          </div>

          <NavLink to="#" className="nav-link" activeClassName="active">
            SHOP
          </NavLink>
          <div className="nav-item dropdown">
            <div
              className="nav-link dropdown-toggle"
              id="programDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              SAMSKARA
            </div>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
              <NavLink
                to="/kirtan-booking"
                className="nav-link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">Book A Kirtan</span>
                </li>
              </NavLink>
              <NavLink
                to="/yagna-booking"
                className="nav-link"
                activeClassName="active"
              >
                <li>
                  <span className="dropdown-item">Book Yagna</span>
                </li>
              </NavLink>
            </ul>
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default Header;
