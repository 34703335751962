import React, { useState, useEffect } from "react";
import "./NityaSevak.css";
import {CircleX} from'lucide-react';

import SevaForm from "../NityaForms/SevaForm";
import img1 from '../NityaForms/assets/img1.webp'
import img2 from '../NityaForms/assets/img2.webp'
import img3 from '../NityaForms/assets/img3.webp'
import img4 from '../NityaForms/assets/img4.webp'
import img5 from '../NityaForms/assets/img5.webp'
import img6 from '../NityaForms/assets/img6.webp'
import img7 from '../NityaForms/assets/img7.webp'
import img8 from '../NityaForms/assets/img8.webp'
import img9 from '../NityaForms/assets/img9.webp'
import img10 from '../NityaForms/assets/img10.webp'

const NityaSevak = () => {
  const sevaOptions = [
    {
      id: 1,
      title: "SAMPURNA SEVA",
      amount: 10000,
      image: img1
    },
    {
      id: 2,
      title: "EKADSHI SEVA",
      amount: 5000,
      image: img2
    },
    {
      id: 3,
      title: "VAISHNAV BHOJAN SEVA",
      amount: 3000,
      image: img3
    },
    {
      id: 4,
      title: "MAHA AARTHI SEVA",
      amount: 2116,
      image: img4
    },
    {
      id: 5,
      title: "GAV SEVA",
      amount: 1500,
      image: img5
    },
    {
      id: 6,
      title: "ANDAAN SEVA",
      amount: 2000,
      image:img6
    },
    {
      id: 7,
      title: "NIVEDANA SEVA",
      amount: 1200,
      image:img7
    },
    {
      id: 8,
      title: "PUSHPA SEVA",
      amount: 1200,
      image:img8

    },
    {
      id: 9,
      title: "MAHA RAJA BHOG SEVA",
      amount: 1000,
      image:img9

    },
    {
      id: 10,
      title: "JAYA VIJAYA SEVA",
      amount: 1000,
      image:img10

    },
  ];

  const [isOpen, setIsOpen] = useState({ status: false, data: null });

  const closePopup = () => setIsOpen({ status: false, data: null });

  return (
    <div className="nitya-sevak">
{isOpen.status && (
       <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
       <div className="relative">
        <button
  onClick={closePopup}
  className="absolute top-4 right-5 sm:right-20 text-4xl font-bold z-50 text-black"
>

           <CircleX size={34} color="black" />
         </button>
         <SevaForm sevaData={isOpen.data} onClose={closePopup} />
       </div>
     </div>
      )}

      <div className="pt-4">
        <h1 className="nitya-sevak-heading">Nitya Sevak</h1>
      </div>
      <div className="w-full flex items-center justify-center p-4">
        <div className="sm:w-[100%] lg:w-[100%]">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-16 justify-items-center">
            {sevaOptions.map((seva, index) => (
              <div
                key={index}
                className="rounded-2xl shadow-lg text-white text-center relative w-full sm:w-[100%] lg:w-[100%] flex flex-col justify-between h-full p-6"
                style={{
                  border: "5.21px solid #C61D21",
                  background:
                    "radial-gradient(50% 50% at 50% 50%, #C61D21 0%, #600E10 100%)",
                }}
              >
                <h2 className="text-xl lg:text-3xl font-bold pt-4 px-2">
                  {seva.title}
                </h2>
                <div className="w-full flex justify-center">
                  <hr className="w-full border-t-2 border-white opacity-80" />
                </div>
                <p className="text-2xl lg:text-2xl text-white font-medium">
                  ₹{seva.amount.toLocaleString()}
                </p>
                <button
                  className="text-red-700 lg:text-2xl font-semibold py-3 rounded-lg underline underline-offset-4 lg:underline-offset-8"
                  onClick={() => setIsOpen({ status: true, data: seva })}
                >
                  Register Now
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NityaSevak;
