import React, { useState, useEffect } from "react";
import "./Home.css";
import "@fontsource/dm-serif-display";
import Header from "../../Components/Header/Header";
import HomeSlider from "../../Components/HomeSlider/HomeSlider";
import NewsSlider from "../../Components/NewsSlider/NewsSlider";
import YouTubeSlider from "../../Components/YtSlider/YtSlider";
import Footer from "../../Components/Footer/Footer";
import DailyDarshan from "./assets/images/DailyDarshan.webp";
import Life from "./assets/images/Life.webp";
import Programs from "./assets/images/Programs.webp";
import Sevak from "./assets/images/Sevak.webp";
import Youth from "./assets/images/Youth.webp";
import CircleImage from "./assets/images/Vector.webp";
import ImageSlider from "./Components/ImageSlider/ImageSlider";

import { Link, NavLink } from "react-router-dom";
import courseimg from "./assets/coures.webp";
import festivalimg from "./assets/festival.webp";
import yatraimg from "./assets/images/yatra.jpg";
import lord from "./assets/lord.webp";
import datas from "./joiningformcontect";
import SpeakWithPriestForm from "./Components/formsection/SpeakWithPriestForm";
import RequestKrishnaPrasadForm from "./Components/formsection/RequestKrishnaPrasadForm";
import BookKirtanForm from "./Components/formsection/BookKirtanForm";
import GetConnectForm from "./Components/formsection/GetConnectForm";
import DownloadVideoForm from "./Components/formsection/DownloadVideoForm";
import BookAYagnaForm from "./Components/formsection/BookAYagnaForm";
import StepsToBhakti from "./Components/formsection/StepsToBhakti";
import { Popup } from "react-leaflet";
import { Helmet } from "react-helmet";

function Home() {
  const [rotatedIndex, setRotatedIndex] = useState(null);
  const [isOpen, setIsOpen] = useState({ status: false, data: null });

  const closePopup = () => setIsOpen({ status: false, data: null });

  useEffect(() => {
    // Scroll to top on component mount
    window.scrollTo(0, 0);
  }, []);

  // Function to dynamically render form components
  const renderForm = () => {
    switch (isOpen.data) {
      case 1:
        return <GetConnectForm />;
      case 2:
        return <SpeakWithPriestForm />;
      case 3:
        return <RequestKrishnaPrasadForm />;
        case 4:
          window.open("https://wa.me/+918006880038", "_blank");
          return null; // or return some message like "Redirecting to WhatsApp..."
      case 5:
        return <BookKirtanForm />;
      case 6:
        return <BookAYagnaForm />;
      case 7:
        return <StepsToBhakti />;

      case 8:
        return <DownloadVideoForm />;

      default:
        return <p>No form available</p>;
    }
  };
  const handleCircleClick = (index) => {
    setRotatedIndex(index);
  };

  const stats = [
    {
      number: "112043+",
      description: "Vedic Books Given",
    },
    {
      number: "56,000+",
      description: "KMS Travelled",
    },
    {
      number: "6,687,907+",
      description: "Covid-19 Free Food Distributed",
    },
    {
      number: "855+",
      description: "Places Travelled",
    },
  ];

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "HinduTemple",
            name: "ISKCON Vijayawada",
            url: "https://www.iskconvijayawada.org/",
            logo: "https://www.iskconvijayawada.org/logo.png",
            image: "https://www.iskconvijayawada.org/temple-image.jpg",
            description:
              "ISKCON Vijayawada is a spiritual sanctuary dedicated to Lord Krishna, offering a serene environment for devotion and cultural heritage exploration.",
            address: {
              "@type": "PostalAddress",
              streetAddress: "Karakatta Road, Undavalli",
              addressLocality: "Vijayawada",
              addressRegion: "Andhra Pradesh",
              postalCode: "552501",
              addressCountry: "IN",
            },
            geo: {
              "@type": "GeoCoordinates",
              latitude: 16.5083,
              longitude: 80.648,
            },
            openingHours: "Mo-Su 05:00-20:00",
            contactPoint: {
              "@type": "ContactPoint",
              telephone: "+91-8006880038",
              contactType: "customer service",
            },
            sameAs: [
              "https://www.facebook.com/iskconvja1/",
              "https://www.instagram.com/iskconvijayawada/",
            ],
          })}
        </script>
      </Helmet>
      <Header />
      <div className="mainPage md:mt-8">
        {/* <HomeSlider /> */}
        <ImageSlider />
        <div className="mainSection text-center">
          <h1>Hare Krishna</h1>
          <div className="d-flex justify-content-center">
            <h4>
              Welcome to the adobe of Sri Sri Radha Shyamsundar and Sri Sri
              Jagannath, where the most-benevolent lord has come to receive your
              love and to fill your lives with his grace. As devotees of lord we
              are here to serve you and look forward to welcome you at our
              temples.
            </h4>
          </div>
          <NavLink
            to="/more-about-iskcon"
            onClick={() => window.scrollTo(0, 0)}
          >
            <button className="btn mt-3 mb-2">
              Learn more about our Temples
            </button>
          </NavLink>
        </div>

        {/*  Courses Festival yatra form  Div */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-5 w-[80%] mx-auto mt-5">
          <div className=" bg-[#c61d21] rounded-[30px] border-8 border-[#c61d21] relative">
            <img loading="lazy" src={courseimg} alt="" />
            <div className="w-full  absolute top-[40%] bg-[#b11a1fc3] py-4 flex items-center justify-center">
              <Link
                to="/courses"
                onClick={() => window.scrollTo(0, 0)}
                className=" text-center text-white text-4xl font-semibold font-['Open Sans'] no-underline hover:underline "
              >
                Courses
              </Link>
            </div>
          </div>
          <div className=" bg-[#c61d21] rounded-[30px] border-8 border-[#c61d21] relative">
            <img loading="lazy" src={festivalimg} alt="" />
            <div className="w-full  absolute top-[40%] bg-[#b11a1fc3] py-4 flex items-center justify-center">
              <Link
                to="/festival"
                onClick={() => window.scrollTo(0, 0)}
                className=" text-center text-white text-4xl font-semibold font-['Open Sans'] no-underline hover:underline "
              >
                Festival
              </Link>
            </div>
          </div>
          <div className=" bg-[#c61d21] rounded-[30px] border-8 border-[#c61d21] relative">
            <img
              loading="lazy"
              className="w-[100%] h-[100%] object-cover"
              src={yatraimg}
              alt=""
            />
            <div className="w-full  absolute top-[40%] bg-[#b11a1fc3] py-4 flex items-center justify-center">
              <Link
                onClick={() => window.scrollTo(0, 0)}
                className=" text-center text-white text-4xl font-semibold font-['Open Sans'] no-underline hover:underline "
              >
                Yatra
              </Link>
            </div>
          </div>
        </div>

        {/* Joining the Family of Lord Krishna */}
        <div
          className="w-full  p-6 md:p-8 flex flex-col md:flex-row gap-3 justify-center items-center   relative"
          style={{
            background: `url(${lord})`,
            backgroundSize: "cover",
            backgroundPosition: "start",
            marginTop: "3%",
          }}
        >
          {/*  Old  card's popup  Modal / Popup */}
          {/* {isOpen.status && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className="relative flex bg-white rounded-lg shadow-lg w-[90%] md:w-[60%]">
               
                <button
                  onClick={closePopup}
                  className="absolute top-2 right-2 text-gray-600 hover:text-black text-4xl font-bold"
                >
                  &times;
                </button>

                
                {renderForm()}
              </div>
            </div>
          )}
           */}
          {/* new Popup after changes to card and switch cases and render form */}
          {isOpen.status &&
            (isOpen.data === 4 ? (
              // Case 4: Only redirect (no popup)
              <>{renderForm()}</>
            ) : (
              // All other cases: Show popup with close button
              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                <div className="relative flex bg-white rounded-lg shadow-lg w-[90%] md:w-[60%]">
                  {/* Close Button (hidden for case 4) */}
                  <button
                    onClick={closePopup}
                    className="absolute top-2 right-2 text-gray-600 hover:text-black text-4xl font-bold"
                  >
                    &times;
                  </button>

                  {/* Render Dynamic Form */}
                  {renderForm()}
                </div>
              </div>
            ))}
          <div
            className="w-full md:w-[50%] "
            style={{
              // textShadow:
              //   "-1px -1px 0 yellow, 1px -1px 0 yellow, -1px 1px 0 yellow, 1px 1px 0 yellow",
              textShadow:
                "-1px -1px 0 #C61D21, 1px -1px 0 #C61D21, -1px 1px 0 #C61D21, 1px 1px 0 #C61D21",
            }}
          >
            <span className="text-[#600e10]/90 text-2xl md:text-7xl font-normal font-['DM Serif Display'] md:leading-[116px] font-bold block  uppercase joinkrishna">
              Join the family of
              <br />
            </span>
            <p className="text-[#600e10]/90 text-4xl md:text-8xl font-normal font-['DM Serif Display'] md:leading-[116px] font-[750]  md:font-extrabold m-0 lordkrishna">
              LORD KRISHNA <br />
            </p>
          </div>
          <div className="w-[95%] md:w-[45%] grid grid-cols-1 md:grid-cols-2 gap-10 place-content-around md:my-32">
            {datas.map((data) => {
              return (
                <div className=" bg-[#f9eadb]/90 rounded-[10px] border-2 border-[#c61d21] flex gap-4 items-center p-4 hover:scale-105 hover:bg-[#fbd3ab] transition">
                  <img
                    loading="lazy"
                    src={data.img}
                    alt=""
                    className="w-[50px] h-[50px] "
                  />
                  <Link
                    className="text-[#600e10] text-base font-bold font-['Lato']  m-0 text-start uppercase no-underline hover:underline"
                    onClick={() =>
                      setIsOpen({
                        status: true,
                        data: data.id,
                      })
                    }
                  >
                    {data.content}
                  </Link>
                </div>
              );
            })}
          </div>
        </div>

        {/* Explore more */}
        <div className="discover-more">
          <h1>Explore More</h1>
          <div className="discover-items">
            {[
              {
                src: DailyDarshan,
                title: "Daily Darshan",
                link: "https://chat.whatsapp.com/LLGNidlvgoCBc0jeiwFvQ5",
              },
              { src: Life, title: "Life Membership", link: "/LifeMembership" },
              { src: Youth, title: "Youth Programs", link: "/YouthSeminar" },
              { src: Programs, title: "Our Programs", link: "#" },
              { src: Sevak, title: "Become Nitya Sevak", link: "/nitya-seva" },
            ].map((item, index) => (
              <div className="item" key={index}>
                <div className="d-flex justify-content-center">
                  <div
                    className="circle-image"
                    onClick={() => handleCircleClick(index)}
                  >
                    <NavLink
                      to={item.link}
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      <img
                        loading="lazy"
                        src={CircleImage}
                        alt="Circle Border"
                        className={`circle ${
                          rotatedIndex === index ? "rotate" : ""
                        }`}
                      />
                      <img
                        loading="lazy"
                        src={item.src}
                        alt={item.title}
                        className="inner-image"
                      />
                    </NavLink>
                  </div>
                </div>

                <h3 className="item-title">
                  <NavLink
                    to={item.link}
                    onClick={() => window.scrollTo(0, 0)}
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    {item.title}
                  </NavLink>
                </h3>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-center">
            <div
              className="item1"
              style={{ width: "50%", padding: "10px 20px" }}
            >
              <div className="d-flex justify-content-center">
                <div
                  className="circle-image"
                  onClick={() => handleCircleClick(5)}
                >
                  <NavLink
                    to="/nitya-seva"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    <img
                      loading="lazy"
                      src={CircleImage}
                      alt="Circle Border"
                      className={`circle ${rotatedIndex === 5 ? "rotate" : ""}`}
                    />
                    <img
                      loading="lazy"
                      src={Sevak}
                      className="inner-image"
                      alt="sevak"
                    />
                  </NavLink>
                </div>
              </div>
              <h3 className="item-title">
                <NavLink
                  to="/nitya-seva"
                  onClick={() => window.scrollTo(0, 0)}
                  style={{ color: "black", textDecoration: "none" }}
                >
                  Become Nitya Sevak
                </NavLink>
              </h3>
            </div>
          </div>
        </div>

        {/* YouTube Slider  */}
        <div className="mustWatch md:pt-4 pb-4">
          <h1>Must Watch</h1>
          <YouTubeSlider />
        </div>

        {/* News Section */}
        <div className="newsSection">
          <h1>
            ISKCON VIJAYAWADA <br /> In the news
          </h1>
          <NewsSlider />
        </div>
        <div className="w-full pb-10 md:pl-20">
          <div className="container mx-auto pl-12">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 lg:gap-0">
              <div className="flex flex-col items-center justify-center text-center lg:border-r-2 border-gray-500">
                <span className="text-4xl md:text-5xl font-bold text-[#c61d21] mb-2">
                  56084+
                </span>
                <span className="text-gray-600 text-sm md:text-base">
                  Vedic Books Given
                </span>
              </div>

              <div className="flex flex-col items-center justify-center text-center lg:border-r-2 border-gray-500">
                <span className="text-4xl md:text-5xl font-bold text-[#c61d21] mb-2">
                  6,687,907+
                </span>
                <span className="text-gray-600 text-sm md:text-base">
                  Free Food Distributed
                </span>
              </div>

              <div className="flex flex-col items-center justify-center text-center lg:border-r-2 border-gray-500 lg:mt-5">
                <span className="text-4xl md:text-5xl font-bold text-[#c61d21] mb-2">
                  3,000+
                </span>
                <span className="text-gray-600 text-sm md:text-base">
                  Grocery Kits Distributed During The Vijayawada Flood
                </span>
              </div>

              <div className="flex flex-col items-center md:-ml-32 justify-center text-center ">
                <span className="text-4xl md:text-5xl  font-bold text-[#c61d21] mb-2 text-center lg:ml-24">
                  40,000+
                </span>
                <span className="text-gray-600 text-sm md:text-base lg:ml-32">
                  Participants Completed Gita4You Course
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Home;
