import { useState } from "react";
import img1 from "./assets/img1.webp";

const SubmitButton = ({ loading }) => {
  return (
    <button
      type="submit"
      disabled={loading}
      className="w-full bg-[#c61d21] text-white py-2 rounded-md font-bold hover:bg-red-700 transition duration-300 relative disabled:opacity-70 disabled:cursor-not-allowed"
    >
      <span className={`${loading ? "opacity-0" : "opacity-100"}`}>Submit</span>

      {loading && (
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
        </div>
      )}
    </button>
  );
};

const SpeakWithPriestForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    note: "",
  });

  // Handle input change
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      formData.name.trim() == "" ||
      formData.email.trim() == "" ||
      formData.phone.trim() == ""
    ) {
      alert("Plaese enter all fields!");
      return;
    }
    setIsLoading(true);

    const formBody = new URLSearchParams(formData).toString();

    console.log(formBody);

    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbx1JDUoIukICOwqInDyhFMHsExB0m5_TLDuB28W9_0xEqhXEZ7jFY7bzTxJ7Y5cWoCJUA/exec",
        {
          method: "POST",
          body: formBody,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (response.ok) {
        setFormData({
          name: "",
          email: "",
          phone: "",
          note: "",
        });
      } else {
        throw new Error("Failed to submit the form.");
      }
    } catch (error) {
      // setMessage("An error occurred while submitting the form.");
      console.error(error);
    } finally {
      setIsLoading(false);
      alert("Request Registered Successfully!");
    }
  };

  return (
    <div className="flex flex-col md:flex-row bg-white shadow-lg rounded-lg overflow-y-scroll overflow-x-hidden md:overflow-y-hidden h-[500px] md:h-[600px]">
      {/* Left Side - Image */}
      <div className="w-full md:w-[40%] h-[200px] md:h-full">
        <img
          loading="lazy"
          src={img1}
          alt="Speak with a Senior Priest"
          className="object-cover w-full h-full"
        />
      </div>

      {/* Right Side - Form */}
      <div className="w-[full] md:w-[60%] p-6 flex flex-col justify-start items-start">
        <h2 className="text-[#1b1616] text-2xl font-bold font-['Poppins'] m-0">
        SPEAK TO SENIOR MENTOR

          <hr
            className="w-[300%] text-black"
            style={{
              borderTop: "2px solid #00000",
            }}
          />
        </h2>

        <p className="text-[#7f7a7a] text-sm font-normal font-['Open Sans'] text-start mb-2">
          If you are facing a crisis or if you have questions to help progress
          on the spiritual path, write to us and we will get in touch. Remember,
          you are not alone.
        </p>

        <form className="space-y-4" onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label
                htmlFor="name"
                className="text-black text-sm font-bold font-['Inter']"
              >
                Name :
              </label>
              <input
                type="text"
                placeholder="Name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                className="p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800"
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="text-black text-sm font-bold font-['Inter']"
              >
                Email :
              </label>
              <input
                type="email"
                placeholder="Email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                className="p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="phone"
              className="text-black text-sm font-bold font-['Inter']"
            >
              Phone :
            </label>
            <input
              type="tel"
              placeholder="Phone"
              id="phone"
              value={formData.phone}
              onChange={handleChange}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800"
            />
          </div>

          <div>
            <label
              htmlFor="note"
              className="text-black text-sm font-bold font-['Inter']"
            >
              Special Note :
            </label>
            <textarea
              rows="4"
              id="note"
              placeholder="Special Note"
              value={formData.note}
              onChange={handleChange}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800"
            ></textarea>
          </div>

          <SubmitButton loading={isLoading} />
        </form>
      </div>
    </div>
  );
};

export default SpeakWithPriestForm;
