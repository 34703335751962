import "./quickdonate.css";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import UpperImageBG from "./assests/lpm1bg.webp";
import axios from "axios";
import GeneralDonation from "./assests/ram.webp";
import DeityWorship from "./assests/krishna.webp";
import FoorForLife from "./assests/foodForLife.webp";
import KrishnaCow from "./assests/KrishnaCow.webp";
import OfferPooja from "./assests/offerPooja.webp";
import sponsorBook from "./assests/bookDistribution.webp";
import TulsiWorship from "./assests/TulshipWorship.webp";
import SponsorSankritan from "./assests/sponserSankritan.webp";

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Calendar } from "lucide-react";
import FoodForLife from "../../Pages/FoodForLife/FoodForLife";
import cow from "./assests/cow.webp";
import bhojan from "./assests/bhojan.webp";

const SpinnerButton = ({ isLoading, onClick, children }) => {
  return (
    <button
      onClick={onClick}
      disabled={isLoading}
      className="bg-[#C61D21] p-3 rounded-lg font-['Open Sans'] text-white text-2xl md:text-3xl border border-white m-0 relative min-w-[200px] disabled:opacity-70"
    >
      <span
        className={`flex items-center justify-center gap-2 ${
          isLoading ? "invisible" : ""
        }`}
      >
        {children}
      </span>

      {isLoading && (
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <svg
            className="animate-spin h-6 w-6 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        </div>
      )}
    </button>
  );
};

const DonationForm = () => {
  const [selectedDonation, setSelectedDonation] =
    useState("A General Donation");
  const [amount, setAmount] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [pan, setPan] = useState("");
  const [address, setAddress] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Optional activities state
  const [gauSevaAmount, setGauSevaAmount] = useState(0);
  const [cowQuantity, setCowQuantity] = useState(0);
  const [bhojanAmount, setBhojanAmount] = useState(0);

  // Memorial/honor donation fields
  const [showMemorialFields, setShowMemorialFields] = useState(false);
  const [memorialTitle, setMemorialTitle] = useState("");
  const [memorialName, setMemorialName] = useState("");
  const [memorialRelation, setMemorialRelation] = useState("");
  const [memorialMobile, setMemorialMobile] = useState("");
  const [memorialOccasion, setMemorialOccasion] = useState("");
  const [occasionDate, setOccasionDate] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (amount === "" || mobileNumber === "" || email === "" || name === "") {
      alert("Please enter all required fields");
      return;
    }

    const totalAmount = getTotalAmount();
    console.log(totalAmount);
    let isFormDataStored = false; // Flag to prevent duplicate storage

    const formData = {
      donationType: selectedDonation,
      amount: amount,

      // Personal information
      name: name,
      email: email,
      mobile: mobileNumber,
      pan: pan,
      address: address,
      pinCode: pinCode,

      // Additional activities
      gauSevaAmount: gauSevaAmount || 0,
      bhojanAmount: bhojanAmount || 0,

      // Memorial information (if applicable)
      memorialDonation: showMemorialFields ? "Yes" : "No",
      memorialTitle: memorialTitle,
      memorialName: memorialName,
      memorialRelation: memorialRelation,
      memorialMobile: memorialMobile,
      memorialOccasion: memorialOccasion,
      occasionDate: occasionDate,

      // Message
      message: message,

      totalDonation:
        Number(amount) + Number(gauSevaAmount) + Number(bhojanAmount),
      // Timestamp
      submissionDate: new Date().toISOString(),
    };

    try {
      // Create order on backend
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/payment/create-order`,
        {
          amount: totalAmount,
          currency: "INR",
        }
      );

      const order = response.data;
      let sevaDescription = selectedDonation;

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: totalAmount * 100,
        currency: "INR",
        name: "ISKCON Quick Donate",
        description: sevaDescription,
        order_id: order.id,
        notes: {
          donorName: name,
          donorPhoneNumber: mobileNumber,
          donorEmail: email,
          // sevaDate: formData.sevaDate,
          // honoureeName: formData.honoureeName,
          // relationship: formData.relationship,
        },
        handler: async function (response) {
          try {
            // Verify payment
            const verificationResponse = await axios.post(
              `${process.env.REACT_APP_API_URL}/api/payment/verify-payment`,
              {
                razorpay_order_id: response.razorpay_order_id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
                donationDetails: { ...formData, amount: totalAmount },
              }
            );

            if (verificationResponse.data.success) {
              // if (!isFormDataStored) {
              await storeFormData("Payment Successful");
              alert("Payment Successful!");
              isFormDataStored = true; // Mark data as stored
              // }
            }
          } catch (err) {
            console.error(err);
            alert("Payment verification failed");
            if (!isFormDataStored) {
              await storeFormData("Payment Failed");
              isFormDataStored = true; // Mark data as stored
            }
          }
        },
        prefill: {
          name: name,
          email: email,
          contact: mobileNumber,
        },
        theme: { color: "#C61D21" },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();

      paymentObject.on("payment.failed", async function (response) {
        alert("Payment Failed: " + response.error.description);
        if (!isFormDataStored) {
          await storeFormData("Payment Failed");
          isFormDataStored = true; // Mark data as stored
        }
      });
    } catch (error) {
      console.error("Error initiating payment:", error);
      alert("Failed to initiate payment. Please try again.");
      if (!isFormDataStored) {
        await storeFormData("Payment Failed");
        isFormDataStored = true; // Mark data as stored
      }
    }
  };

  const storeFormData = async (paymentStatus) => {
    setIsSubmitting(true);

    // Construct form data object
    const formData = {
      // Basic donation info
      paymentStatus,
      donationType: selectedDonation,
      amount: amount,

      // Personal information
      name: name,
      email: email,
      mobile: mobileNumber,
      pan: pan,
      address: address,
      pinCode: pinCode,

      // Additional activities
      gauSevaAmount: gauSevaAmount || 0,
      bhojanAmount: bhojanAmount || 0,

      // Memorial information (if applicable)
      memorialDonation: showMemorialFields ? "Yes" : "No",
      memorialTitle: memorialTitle,
      memorialName: memorialName,
      memorialRelation: memorialRelation,
      memorialMobile: memorialMobile,
      memorialOccasion: memorialOccasion,
      occasionDate: occasionDate,

      // Message
      message: message,

      totalDonation:
        Number(amount) + Number(gauSevaAmount) + Number(bhojanAmount),
      // Timestamp
      submissionDate: new Date().toISOString(),
    };

    const formBody = new URLSearchParams(formData).toString();

    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbyiqjnMeo9RXippkxJkO43vkWPwV6FgLUKfi6--DEheQ7g1au06ra-Vh-HTH3EZlLWfEA/exec",
        {
          method: "POST",
          body: formBody,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      // alert("submitted successfully");
      setSelectedDonation("A General Donation");
      setAmount("");
      setMobileNumber("");
      setEmail("");
      setName("");
      setPan("");
      setAddress("");
      setPinCode("");
      setCowQuantity(0);
      setBhojanAmount("");
      setShowMemorialFields(false);
      setMemorialTitle("");
      setMemorialName("");
      setMemorialRelation("");
      setMemorialMobile("");
      setMemorialOccasion("");
      setOccasionDate("");
      setMessage("");
      setGauSevaAmount(0);
    } catch (error) {
      console.error("Form submission error:", error);
      // alert("Some error occured");
    } finally {
      setIsSubmitting(false);
    }
  };

  const getTotalAmount = () => {
    return Number(amount) + Number(gauSevaAmount) + Number(bhojanAmount);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const increaseCowQuantity = () => {
    setCowQuantity((prev) => {
      setGauSevaAmount((prev + 1) * 120);
      return prev + 1;
    });
  };

  const decreaseCowQuantity = () => {
    setCowQuantity((prev) => {
      const quant = prev > 0 ? prev - 1 : 0;
      setGauSevaAmount(quant * 120);
      return quant;
    });
  };

  const donationTypes = {
    "A General Donation": {
      image: GeneralDonation,
      extraFields: [],
    },
    "Deity Worship": {
      image: DeityWorship,
      additionalFields: (
        <div className="max-w-[100%] mx-auto space-y-6 ">
          {/* Goseva Section */}
          <div className="space-y-2">
            <div className="flex items-start">
              <span className="text-gray-700">
                Perform Goseva by feeding the cows.
                <span className="text-gray-500 ml-1">(Optional)</span>
              </span>
            </div>

            <div className="border border-black rounded-sm p-2 bg-white">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <img
                    loading="lazy"
                    src={cow}
                    alt="Cow"
                    width={50}
                    height={50}
                    className="object-contain"
                  />
                  <span className="text-lg">₹ {gauSevaAmount}.00</span>
                </div>
                <div className="flex items-center border border-black rounded">
                  <button
                    onClick={decreaseCowQuantity}
                    className="px-3 py-1 text-xl border-r border-black hover:bg-gray-100"
                  >
                    −
                  </button>
                  <input
                    type="number"
                    value={cowQuantity}
                    onChange={(e) =>
                      setCowQuantity(Math.max(0, parseInt(e.target.value) || 0))
                    }
                    className="w-12 text-center py-1 focus:outline-none"
                  />
                  <button
                    onClick={increaseCowQuantity}
                    className="px-3 py-1 text-xl border-l border-black hover:bg-gray-100"
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
            <p className="text-xs text-gray-500 mt-2">
              Feed a Cow @ Rs. 120 for a day. Select no. of Cows to feed
            </p>
          </div>

          {/* Sadhu Bhojan Seva Section */}
          <div className="space-y-2">
            <div className="flex items-start">
              <span className="text-gray-700">
                Perform Sadhu Bhojan Seva.
                <span className="text-gray-500 ml-1">(Optional)</span>
              </span>
            </div>

            <div className="border border-black rounded-sm p-1 bg-white">
              <div className="flex items-center gap-3 justify-center">
                <img
                  loading="lazy"
                  src={bhojan}
                  alt="Sadhu Bhojan"
                  width={50}
                  height={50}
                  className="object-contain rounded-full"
                />
                <input
                  type="text"
                  placeholder="Enter Amount"
                  value={bhojanAmount}
                  onChange={(e) => setBhojanAmount(e.target.value)}
                  className="flex-1 p-2 focus:outline-none text-lg"
                />
              </div>
            </div>
            <div className="text-xs text-gray-500 mt-2">
              <p className="!mb-0">Sponsor Bhojan Seva for Brahmanas.</p>
              <p>Contribute any amount</p>
            </div>
          </div>
        </div>
      ),
    },
    "Food for life": {
      image: FoorForLife,
      additionalFields: null,
    },
    "Krishna's Cows": {
      image: KrishnaCow,
      additionalFields: (
        <div className="space-y-2">
          <div className="flex items-start">
            <span className="text-gray-700">
              Perform Goseva by feeding the cows.
              <span className="text-gray-500 ml-1">(Optional)</span>
            </span>
          </div>

          <div className="border border-black rounded-sm p-2 bg-white">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <img
                  loading="lazy"
                  src={cow}
                  alt="Cow"
                  width={50}
                  height={50}
                  className="object-contain"
                />
                <span className="text-lg">₹ {gauSevaAmount}.00</span>
              </div>
              <div className="flex items-center border border-black rounded">
                <button
                  onClick={decreaseCowQuantity}
                  className="px-3 py-1 text-xl border-r border-black hover:bg-gray-100"
                >
                  −
                </button>
                <input
                  type="number"
                  value={cowQuantity}
                  onChange={(e) =>
                    setCowQuantity(Math.max(0, parseInt(e.target.value) || 0))
                  }
                  className="w-12 text-center py-1 focus:outline-none"
                />
                <button
                  onClick={increaseCowQuantity}
                  className="px-3 py-1 text-xl border-l border-black hover:bg-gray-100"
                >
                  +
                </button>
              </div>
            </div>
          </div>
          <p className="text-xs text-gray-500 mt-2">
            Feed a Cow @ Rs. 120 for a day. Select no. of Cows to feed
          </p>
        </div>
      ),
    },
    "Sponsor book distribution": {
      image: sponsorBook,
      additionalFields: null,
    },
    "Offer a pooja": {
      image: OfferPooja,
      additionalFields: null,
    },
    "Sponsor sankirtan": {
      image: SponsorSankritan,
      additionalFields: null,
    },
    "Tulsi worship": {
      image: TulsiWorship,
      additionalFields: null,
    },
  };

  const navigate = useNavigate();
  return (
    <>
      <Header />
      <div className="bg-[#FEEEDC]">
        {/* Banner */}
        <div className="relative w-full  bg-no-repeat flex items-center justify-center quickdonatebanner">
          <h2
            className=" text-[#bc3521] text-4xl md:text-8xl font-bold "
            style={{
              WebkitTextStroke: "1px white",
              "-webkit-text-stroke": "2px white",
            }}
          >
            DONATION FORM
          </h2>
        </div>

        <div className="w-[90%] mx-auto">
          <h2 className="text-center text-[#DE7D0D] pt-14 pb-4">
            QUICK DONATE
          </h2>
          <p className="text-black text-lg md:text-2xl font-normal text-justify md:text-center pb-6">
            If you would like to make a donation towards a particular area of
            activity, please select an option from below. ISKCON relies entirely
            on voluntary donations and so every donation counts. Please note
            that donation is processed on a secure site.
          </p>
          <div className="w-full h-2 bg-[#bc3521]"></div>
        </div>
      </div>

      <div
        className="w-full bg-cover items-center p-6 md:pl-8 md:pr-8 md:pt-16"
        style={{ backgroundImage: `url(${UpperImageBG})` }}
      >
        <div className=" mx-auto max-w-[90rem]">
          <div className="grid md:grid-cols-3 gap-8">
            {/* Left Column with Fixed Size img */}
            <div>
              <div className="rounded-lg overflow-hidden border-4 border-red h-[350px] w-full">
                <img
                  loading="lazy"
                  src={donationTypes[selectedDonation].image}
                  alt={selectedDonation}
                  className="w-full h-full object-cover"
                />
              </div>
              <p className="text-base mt-4 text-center">
                Please Note:
                <span className="text-gray-500">
                  {" "}
                  Complete Address with PIN-Code and PAN is mandatory for an 80G
                  Receipt
                </span>
              </p>
            </div>

            {/* Right Column with Form */}
            <div className="space-y-4 md:col-span-2">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-gray-700 mb-1">
                    Donation For <span className="text-red font-bold">*</span>
                  </label>
                  <select
                    className="w-full p-2 border ring-1 rounded-2xl focus:outline-none focus:ring focus:ring-blue-200 m-0 ring-[#bc3521]"
                    value={selectedDonation}
                    onChange={(e) => setSelectedDonation(e.target.value)}
                  >
                    {Object.keys(donationTypes).map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                  <div
                    onClick={() => {
                      navigate("/donation-temp");
                    }}
                    className="text-xs text-red text-right mt-1 cursor-pointer"
                  >
                    view more donation option
                  </div>
                </div>
                <div>
                  <label className="block text-gray-700 mb-1">
                    Amount <span className="text-red font-bold">*</span>
                  </label>
                  <input
                    type="text"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    placeholder="Enter Amount"
                    className="w-full p-2 border ring-1 rounded-2xl focus:outline-none focus:ring focus:ring-blue-200 m-0 ring-[#bc3521] font-semibold"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-gray-700 mb-1">
                    Mobile Number <span className="text-red font-bold">*</span>
                  </label>
                  <input
                    type="tel"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    placeholder="Mobile number"
                    className="w-full p-2 border ring-1 rounded-2xl focus:outline-none focus:ring focus:ring-blue-200 m-0 ring-[#bc3521] font-semibold"
                  />
                </div>
                <div>
                  <label className="block text-gray-700 mb-1">
                    Email <span className="text-red font-bold">*</span>
                  </label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    className="w-full p-2 border ring-1 rounded-2xl focus:outline-none focus:ring focus:ring-blue-200 m-0 ring-[#bc3521] font-semibold"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-gray-700 mb-1">
                    Name <span className="text-red font-bold">*</span>
                  </label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Name"
                    className="w-full p-2 border ring-1 rounded-2xl focus:outline-none focus:ring focus:ring-blue-200 m-0 ring-[#bc3521] font-semibold"
                  />
                </div>
                <div>
                  <label className="block text-gray-700 mb-1">
                    PAN (Optional)
                  </label>
                  <input
                    type="text"
                    value={pan}
                    onChange={(e) => setPan(e.target.value)}
                    placeholder="PAN"
                    className="w-full p-2 border ring-1 rounded-2xl focus:outline-none focus:ring focus:ring-blue-200 m-0 ring-[#bc3521] font-semibold"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-gray-700 mb-1">
                    Address (Optional)
                  </label>
                  <input
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder="Address"
                    className="w-full p-2 border ring-1 rounded-2xl focus:outline-none focus:ring focus:ring-blue-200 m-0 ring-[#bc3521] font-semibold"
                  />
                </div>
                <div>
                  <label className="block text-gray-700 mb-1">
                    Pin Code (Optional)
                  </label>
                  <input
                    type="text"
                    value={pinCode}
                    onChange={(e) => setPinCode(e.target.value)}
                    placeholder="Pin Code"
                    className="w-full p-2 border ring-1 rounded-2xl focus:outline-none focus:ring focus:ring-blue-200 m-0 ring-[#bc3521] font-semibold"
                  />
                </div>
              </div>

              {donationTypes[selectedDonation].additionalFields}

              <div className="flex items-center gap-2 mb-2">
                <input
                  type="checkbox"
                  className="w-4 h-4"
                  onChange={(e) => setShowMemorialFields(e.target.checked)}
                  checked={showMemorialFields}
                />
                <span className="text-sm text-gray-700">
                  This Donation in the memory/honor of someone or performed on a
                  specific occasion
                </span>
              </div>

              {showMemorialFields && (
                <>
                  <div className="grid md:grid-cols-3 gap-4 mb-4">
                    <div>
                      <label className="block text-gray-700 mb-1">Title</label>
                      <select
                        value={memorialTitle}
                        onChange={(e) => setMemorialTitle(e.target.value)}
                        className="w-full text-gray-400 p-2 border ring-1 rounded-2xl focus:outline-none focus:ring focus:ring-blue-200 m-0 ring-[#bc3521] font-semibold"
                      >
                        <option>Select Title</option>
                        <option>Mr</option>
                        <option>Mrs</option>
                        <option>Late</option>
                      </select>
                    </div>
                    <div>
                      <label className="block text-gray-700 mb-1">Name</label>
                      <input
                        type="text"
                        value={memorialName}
                        onChange={(e) => setMemorialName(e.target.value)}
                        placeholder="In Name Of"
                        className="w-full p-2 border ring-1 rounded-2xl focus:outline-none focus:ring focus:ring-blue-200 m-0 ring-[#bc3521] font-semibold"
                      />
                    </div>
                    <div>
                      <label className="block text-gray-700 mb-1">
                        Relation
                      </label>
                      <input
                        type="text"
                        value={memorialRelation}
                        onChange={(e) => setMemorialRelation(e.target.value)}
                        placeholder="Relation"
                        className="w-full p-2 border ring-1 rounded-2xl focus:outline-none focus:ring focus:ring-blue-200 m-0 ring-[#bc3521] font-semibold"
                      />
                    </div>
                  </div>

                  <div className="grid md:grid-cols-3 gap-4 mb-4">
                    <div>
                      <label className="block text-gray-700 mb-1">
                        Relative Mobile No.
                      </label>
                      <input
                        type="tel"
                        value={memorialMobile}
                        onChange={(e) => setMemorialMobile(e.target.value)}
                        placeholder="Phone Number"
                        className="w-full p-2 border ring-1 rounded-2xl focus:outline-none focus:ring focus:ring-blue-200 m-0 ring-[#bc3521] font-semibold"
                      />
                    </div>
                    <div>
                      <label className="block text-gray-700 mb-1">
                        Occasion
                      </label>
                      <select
                        value={memorialOccasion}
                        onChange={(e) => setMemorialOccasion(e.target.value)}
                        className="w-full text-gray-400 p-2 border ring-1 rounded-2xl focus:outline-none focus:ring focus:ring-blue-200 m-0 ring-[#bc3521] font-semibold"
                      >
                        <option value="">Occasion</option>
                        <option value="Birthday">Birthday</option>
                        <option value="Marriage Anniversary">
                          Marriage Anniversary
                        </option>
                        <option value="Death Anniversary">
                          Death Anniversary
                        </option>
                        <option value="Other">Any Other Occasion</option>
                      </select>
                    </div>
                    <div>
                      <label className="block text-gray-700 mb-1">
                        Occasion Date
                      </label>
                      <div className="relative">
                        <input
                          type="date"
                          value={occasionDate}
                          onChange={(e) => setOccasionDate(e.target.value)}
                          placeholder="DD-MM-YYYY"
                          className="w-full text-gray-400 p-2 border ring-1 rounded-2xl focus:outline-none focus:ring focus:ring-blue-200 m-0 ring-[#bc3521] font-semibold"
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          {/* Full Width Message Field */}
          <div className="mt-2">
            <div>
              <label className="block text-gray-700 mb-1">
                Message/remark/prayer
              </label>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="w-full p-2 border border-black focus:outline-none focus:ring focus:ring-blue-200 rounded-md h-32 font-semibold"
                maxLength={250}
              />
              <p className="text-xs text-gray mt-1">
                Maximum 250 characters allowed
              </p>
            </div>

            <div className="flex justify-center md:justify-center">
              <SpinnerButton isLoading={isSubmitting} onClick={handleSubmit}>
                Donate Now
              </SpinnerButton>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DonationForm;
