import img1 from "./assets/img1.webp";
import img2 from "./assets/img2.webp";
import img3 from "./assets/img3.webp";
import img4 from "./assets/img4.webp";
import img5 from "./assets/img5.webp";
import img6 from "./assets/img6.webp";

const paths = [
  {
    id: 1,
    title: "YOUTH PREACHING",
    Image: img1,
    link: "/YouthSeminar",
  },
  {
    id: 2,
    title: "CONGREGATION PREACHING",
    Image: img2,
    link: "/BhaktiVriksha",
  },
  {
    id: 3,
    title: "BHAGVAD GITA COURSES",
    Image: img3,
    link: "/GitaForYou",
  },
  {
    id: 4,
    title: "CHILDREN PREACHING",
    Image: img4,
    link: "/GopalaFunSchool",
  },
  {
    id: 5,
    title: "CORPORATE PROGRAMMES",
    Image: img5,
    link: "/CorporateProgrammes",
  },
  {
    id: 6,
    title: "SOCIAL WORK",
    Image: img6,
    link: "#",
  },
];

export default paths;
