import React, { useEffect, useState } from "react";
import "./styles.css";
import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer/Footer";
import Page1 from "./page1/page1";
import Page3 from "./page3/page3";
import bgImg from "./assets/background.webp";

function BhaktiVriksha() {
  // form data Storing
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    specialNote: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormData({
      name: "",
      email: "",
      phone: "",
      address: "",
      specialNote: "",
    });
    // For future use
  };

  useEffect(() => {
    // Scroll to top on component mount
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      style={{ backgroundImage: `url(${bgImg})` }}
      className="w-full bg-cover bg-center bg-no-repeat"
    >
      <Header />
      <Page1 />
      <Page3 />
      <div className="w-[97%] lg:w-[77%] flex flex-col justify-center my-10 mx-auto rounded-md border-2 border-red md:text-3xl">
        <h2 className="text-center bg-red text-white font-bold text-lg p-2 md:p-8 md:text-4xl lg:mb-5">
          Join Us And Be A Part Of This Growing Tree Of Devotion!
        </h2>

        <div className="grid grid-cols-2 gap-4 p-1 lg:w-[90%] lg:mb-5 mx-auto">
          <div>
            <label className="block font-medium mb-1 ">Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full lg:w-[90%] bg-transparent p-2 border-2 border-solid border-gray-300 lg:border-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-red"
            />
          </div>
          <div>
            <label className="block font-medium mb-1">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full lg:w-[90%] p-2 bg-transparent  border-2 border-solid border-gray-300 lg:border-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-red"
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4 p-1 lg:w-[90%] lg:mb-5 mx-auto">
          <div>
            <label className="block font-medium mb-1 ml-1 ">Phone</label>
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="w-full lg:w-[90%] p-2 bg-transparent  border-2 border-solid border-gray-300 lg:border-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-red"
            />
          </div>
          <div>
            <label className="block font-medium mb-1">Address</label>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              className="w-full lg:w-[90%] p-2 bg-transparent  border-2 border-solid border-gray-300 lg:border-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-red"
            />
          </div>
        </div>

        <div className="p-1 lg:w-[90%] mx-auto">
          <label className="block font-medium mb-1 ml-1">Special Note</label>
          <textarea
            name="specialNote"
            value={formData.specialNote}
            onChange={handleChange}
            className="w-full lg:mb-5 p-2 bg-transparent border-2 border-solid border-gray-300 lg:border-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-red"
            rows="3"
          ></textarea>
        </div>

        <button
          onClick={handleSubmit}
          className="w-full lg:w-[90%] mx-auto lg:mb-5 bg-red lg:text-4xl text-white font-bold py-2 px-2 lg:rounded-md"
        >
          Submit
        </button>
      </div>
      <Footer />
    </div>
  );
}

export default BhaktiVriksha;
