import { useState } from "react";
import img1 from "./assets/img1.webp";

const StepsToBhakti = ({ loading }) => {
  return (
<div className="flex justify-center items-center bg-white h-[500px] w-full">
  <p className="text-4xl font-bold">Coming Soon</p> 
</div>
  );
};


  
export default StepsToBhakti;
