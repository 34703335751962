import React from 'react'
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'

import backimg from "./assets/image.png";
import rightpng from "./assets/image21456.png"
import leftpng from "./assets/image21455.png"

import grpImg from "./assets/grp_img.webp"
import offeringImg from "./assets/pro.webp"
import bgimage2 from "./assets/bgimage2.png"
import lamp from "./assets/Vector.png"
import floral from "./assets/floral.png"
import floralleft from "./assets/floralleft.png"






import "./style.css"


const CorporateProgram2 = () => {
    return (
        <div>

            <Header />

            <div className="w-full relative md:mt-12 "  >

                <div className=' bg-cover bg-no-repeat bg-center'
                    style={{ backgroundImage: `url(${backimg})` }}>

                    <img src={leftpng} className='firstflo absolute -z-1 left-0 '></img>
                    <img src={rightpng} className='firstflo absolute -z-1 right-0 '></img>



                    <div className="relative z-10 max-w-[100%] md:max-w-[90%] mx-auto px-4 py-2">

                        <div className="flex justify-center mb-8">
                            <div className="text-center bg-red text-white px-1   py-3  text-1xl md:text-4xl sm:text-1xl font-bold border-2 border-white rounded-b-xl">
                                ISKCON Corporate Services & Employee Engagement Program

                            </div>
                        </div>

                        {/* Main Title */}


                        <div className="text-center mb-6">
                            <h2 className="text-2xl md:text-6xl font-semibold text-red mb-1">
                                Elevate Workplace Culture with Spiritual Values
                            </h2>
                        </div>


                        <div className="mb-8 leading-relaxed text-gray-800 flex justify-center">
                            <img
                                loading="lazy"
                                src={grpImg}
                                className="h-56 md:h-[513px] md:w-[1000px] sm:h-[50%] rounded-xl shadow-[18px_20px_10px_#e09c89,_0_6px_6px_rgba(0,0,0,0.1),_0_0_0_1px_rgba(255,228,214,0.7)]"
                            />
                        </div>

                        <div className=" text-lg md:text-3xl leading-relaxed text-gray-800 mb-2">
                            <p className="text-justify py-2">
                                At ISKCON, we believe that a workplace thrives when its employees are engaged,
                                motivated, and aligned with ethical values. Our Corporate Services and Employee
                                Engagement Program is designed to enhance professional and personal well-being through
                                timeless spiritual principles. These transformative sessions are conducted by top IITians
                                who have embraced the monastic life as Brahmachari monks of ISKCON,
                                bringing a unique blend of intellectual depth and spiritual wisdom.
                            </p>

                        </div>
                    </div>

                </div>



                <div>


                    {/* section 2 with img */}
                    <div className='flex justify-center p-1 bg-cover bg-no-repeat bg-center '
                        style={{ backgroundImage: `url(${bgimage2})` }}>

                        <div className='ourmission rounded-b-xl border-3  border-red m-5' >

                            <h1 className='text-center bg-red w-full text-[#FFDEBD] py-2 text4xl'>Our Mission</h1>

                            <div className='d-flex flex-wrap justify-content-evenly'>
                                <img className="md:w-[710px] md:h-[610px] mt-4 w-[280px] h-[250px] md:mb-4" src={offeringImg}></img>

                                <div className='w-[662px] md:p-5 px-4'>

                                    <p className='text-justify text-red  mb-4' >
                                        <strong> integrate ethical values, holistic well-being,
                                            and spiritual wisdom into corporate environments,
                                            fostering a harmonious and productive workplace culture.
                                        </strong>
                                    </p>

                                </div>
                            </div>

                        </div>

                    </div>


                    {/* section 3 offerings */}



                    <div className="relative bg-[#c61d21] text-white min-h-[30vh] overflow-hidden w-full box-border px-2 md:px-4 py-4">
                        <div className="relative z-10 max-w-[100%] md:max-w-[85%] mx-auto">
                            <div className="w-full">
                                <h1 className="text-center font-['Source_Serif_4'] font-semibold text-[#f3b01b] mb-3 text-[32px] leading-[1.2] sm:text-[54px] lg:text-[70px]">
                                    Our Offerings
                                </h1>

                                <ul className="list-none mx-auto my-2 px-4 md:px-5 leading-[1.5] text-[#f3b01b] text-[16px]  sm:text-[26px] sm:leading-[1.8] sm:px-10 lg:text-[32px] lg:leading-[2] lg:my-[30px] lg:max-w-[1600px]">
                                    <li className="my-[15px] sm:my-[20px]">
                                        <strong className="text-[#f3b01b]">
                                            <img src={lamp} alt='lamp img' className='lampimg h-[27px] w-[44.5px] float-left mr-3'></img>

                                            1. Self-Management Workshops{" "}
                                        </strong>
                                        <span className="text-[#ffdebd] w-full">
                                            <ul className=' text-white '>
                                                <dl>In today's fast-paced corporate world, managing oneself effectively is the key to success.
                                                    Our self-management workshops provide employees with tools to:</dl>

                                                <li>●	Handle workplace stress efficiently.</li>
                                                <li>●	Cultivate a balanced approach towards work and life.</li>
                                                <li>●	Enhance productivity through disciplined habits and focus techniques.</li>
                                            </ul>
                                        </span>
                                    </li>

                                    <li className="my-[15px] sm:my-[20px]">
                                        <strong className="text-[#f3b01b]">
                                            <img src={lamp} alt='lamp img' className='lampimg h-[27px] w-[44.5px] float-left mr-3'></img>

                                            2. Open-Eyed Yoga & Meditation{" "}
                                        </strong>{" "}

                                        <span className="text-[#ffdebd] w-full">
                                            <ul className=' text-white '>
                                                <dl>Unlike conventional meditation, our open-eyed yoga sessions are designed to be
                                                    practiced in real-world scenarios, enhancing mindfulness and concentration while
                                                    performing daily tasks. Employees will learn:</dl>

                                                <li>●	Practical techniques for emotional regulation.</li>
                                                <li>●	The art of staying focused and calm under pressure.</li>
                                                <li>●	Strengthening interpersonal communication and workplace harmony.</li>
                                                <li>●	Real-time application of mindfulness for better decision-making.</li>

                                            </ul>
                                        </span>
                                    </li>
                                    <li className="my-[15px] sm:my-[20px]">
                                        <strong className="text-[#f3b01b]">
                                            <img src={lamp} alt='lamp img' className='lampimg h-[27px] w-[44.5px] float-left mr-3'></img>

                                            3. Behavioral Training Seminars{" "} </strong>{" "}
                                        <span className="text-[#ffdebd] w-full">
                                            <ul className='text-white'>
                                                <dl>Understanding and improving behavior is essential for a
                                                    cooperative and ethical workplace. These seminars focus on:</dl>

                                                <li>●	Fostering ethical leadership and integrity in decision-making.</li>
                                                <li>●	Building trust and transparency among teams.</li>
                                                <li>●	Enhancing cooperation and conflict resolution skills.</li>
                                                <li>●	Cultivating a sense of purpose and responsibility in employees.</li>

                                            </ul>
                                        </span>
                                    </li>

                                    <li className="my-[15px] sm:my-[20px]">
                                        <strong className="text-[#f3b01b]">
                                            <img src={lamp} alt='lamp img' className='lampimg '></img>

                                            4. Experiential Learning Programs{" "} </strong>{" "}
                                        <span className="text-[#ffdebd] w-full">
                                            <ul className='text-white'>
                                                <dl>Hands-on learning fosters deep engagement and retention of knowledge.
                                                    Our interactive activities are designed to:</dl>

                                                <li>●	Strengthen collaboration and team bonding through real-life scenarios.</li>
                                                <li>●	Improve clarity in roles and responsibilities within an organization.</li>
                                                <li>●	Encourage a sense of accountability and ownership.</li>
                                                <li>●	Bridge the gap between spiritual wisdom and corporate success.</li>

                                            </ul>
                                        </span>
                                    </li>
                                </ul>

                            </div>
                        </div>
                        <div className="absolute top-0 left-0 right-0 bottom-0 pointer-events-none z-5">
                            <img
                                loading="lazy"
                                className="absolute top-[-25px] left-[-25px] w-[123px] h-[116px] md:w-[500px] md:h-[470px] sm:top-[-50px] sm:left-[-50px] sm:w-[280px] lg:top-[-75px] lg:left-[-75px] lg:w-[420px]"
                                src={floral}
                                alt="Top Left Design"
                            />
                            <img
                                loading="lazy"
                                className="absolute top-[150px] right-[-80px] w-[222px] h-[208px] md:w-[898px] md:h-[844px] md:right-[-150px] sm:bottom-[56px] sm:right-[-39px] sm:w-[466px] sm:h-[422px] "
                                src={floral}
                                alt="Bottom Right Design"
                            />

                            <img
                                loading="lazy"
                                className="absolute top-[500px] left-[-50px] w-[222px] h-[208px]  d-md-none d-sm-none d-lg-none"
                                src={floral}
                                alt="Bottom Right Design"
                            />



                        </div>



                    </div>

                    {/* section 4*/}


                    <div className=" mx-auto py-5 px-[5%] bg-cover bg-no-repeat bg-center d-flex justify-center"
                        style={{ backgroundImage: `url(${bgimage2})` }}>
                        <div className="border-2 border-red rounded-2xl p-2 bg-[#f4dec6ae] shadow-md md:w-[1442px] md:h-[700px] md:my-7 ">
                            
                            <h2 className="text-3xl md:text-6xl md:py-5  font-semibold text-red my-4 text-center font-['Source Serif Pro']">
                                Why Choose ISKCON Corporate Services?
                            </h2>

                            <ul className="space-y-6 list-disc pl-6 mt-6 text-xl md:text-4xl md:m-6 md:space-y-9 ">
                                <li>
                                    <span className="font-bold">Boost Employee Retention:</span> A values-driven workplace fosters loyalty and reduces attrition.
                                </li>
                                <li>
                                    <span className="font-bold">Enhance Team Collaboration:</span>{" "}
                                    Strengthened interpersonal relationships lead to improved teamwork and productivity.
                                </li>
                                <li>
                                    <span className="font-bold">Increase Workplace Efficiency:</span>{" "}
                                    Stress reduction techniques and ethical leadership contribute to better decision-making and output.
                                </li>
                                <li>
                                    <span className="font-bold">Promote Holistic Well-being:</span>{" "}
                                    Balanced personal and professional lives lead to a more motivated workforce.
                                </li>
                            </ul>


                        </div>
                    </div>

                    {/* ---- */}


                    {/* section 5 */}



                    <div className="relative bg-[#c61d21] text-white min-h-[30vh] overflow-hidden w-full box-border px-0 md:px-4  py-5">
                        <img
                            loading="lazy"
                            // className="absolute top-[187px] left-[-25px]  h-[370px] opacity-100 sm:top-[-50px] sm:left-[-50px] sm:w-[280px] lg:top-[187px] lg:left-[-75px] lg:w-[420px]"
                            className="absolute  h-[370px] opacity-100 sm:top-[-50px] sm:left-[0px]   md:left-[0px] md:top-[20%]"

                            src={floralleft}
                            alt="Top Left Design"

                        />

                        <div className="relative z-10 max-w-[100%] md:max-w-[85%] mx-auto">
                            <div className="w-full">
                                <h1 className="text-center font-['Source_Serif_4'] font-semibold text-[#f3b01b] mb-3 text-[32px] leading-[1.2] sm:text-[54px] lg:text-[70px]">
                                    How It Works
                                </h1>
                                <ul className=" space-y-6 list-none grid md:justify-center  mx-auto my-2 px-4 md:px-5 leading-[1.5] md:text-[#FFDEBD]  text-[#f3b01b] text-[16px]  sm:text-[26px] sm:leading-[1.8] sm:px-10 lg:text-[32px] lg:leading-[2] lg:my-[30px] lg:max-w-[1600px]">
                                    <li className="my-[10px] sm:my-[10px]">
                                        <span className=" mt-1">
                                            <img src={lamp} alt='lamp img' className='lampimg'></img>

                                        </span>
                                        <div className='sm:ml-[50px]'>

                                            1.	Needs Assessment – Understanding corporate challenges and employee well-being concerns.

                                        </div>
                                    </li>
                                    <li className="my-[10px] sm:my-[10px]">
                                        <span className=" mt-1">
                                            <img src={lamp} alt='lamp img' className='lampimg'></img>


                                        </span>
                                        <div className='sm:ml-[50px]'>
                                            2.	Customized Program Development – Tailoring workshops and activities to align with your company’s goals.

                                        </div>
                                    </li>
                                    <li className="my-[10px] sm:my-[10px]">
                                        <span className=" mt-1">
                                            <img src={lamp} alt='lamp img' className='lampimg'></img>


                                        </span>
                                        <div className='sm:ml-[50px]'>

                                            3.	Implementation – Conducting interactive sessions and immersive learning experiences.
                                        </div>
                                    </li>
                                    <li className="my-[10px] sm:my-[10px]">
                                        <span className=" mt-1">
                                            <img src={lamp} alt='lamp img' className='lampimg'></img>


                                        </span>
                                        <div className='sm:ml-[50px]'>

                                            4.	Evaluation & Follow-Up – Measuring impact through feedback and making necessary refinements.
                                        </div>
                                    </li>

                                </ul>
                            </div>
                        </div>


                    </div>


                    {/* section 6 */}


                    <div className=" space-y-8 md:py-4 px-2  d-flex flex-column align-items-center bg-cover bg-no-repeat bg-center "
                        style={{ backgroundImage: `url(${bgimage2})` }}>
                        {/* Get Started Today */}
                        <div className="text-center">
                            <h2 className="text-3xl md:text-6xl m-5 font-semibold text-red ">
                                Get Started Today
                            </h2>
                        </div>

                        <p className="mb-5 font-semibold text-black text-xl md:text-3xl text-center w-[90%] md:w-[70%] sm:w-[90%] d-flex flex-column align-items-center">
                            <span>
                                Empower your employees with spiritual wisdom and ethical leadership. To partner with us for your corporate well-being programs,
                            </span>

                            <span className='my-2'>
                                contact us at <strong> 9281109457.</strong>
                            </span>

                            <span className='my-2'>

                                Join hands with ISKCON to create a workplace where values, engagement, and productivity thrive together.
                            </span>
                        </p>
                    </div>




                </div>




            </div>

            <Footer />

        </div>
    )
}

export default CorporateProgram2