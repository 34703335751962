import React, { useEffect } from "react";
import Header from "../Header/Header.js";
import Footer from "../Footer/Footer.js";
import banner from "./assets/banner.webp";
import bg from "./assets/bg.webp";
import paths from "./course.js";
import "./course.css";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";


function Courses() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCardClick = (link) => {
    navigate(link);
  };
  return (
    <div>
      <Helmet>
        {/* Primary Meta Tags */}
        <title>
          Learn Bhagavad Gita Teachings | ISKCON Vijayawada Discourses
        </title>
        <meta
          name="description"
          content="Explore Krishna’s teachings from the Bhagavad Gita. Join spiritual discourses at ISKCON Vijayawada and deepen your understanding of Vedic wisdom."
        />
        <meta
          name="keywords"
          content="Bhagavad Gita teachings, ISKCON discourses, spiritual wisdom, Krishna philosophy"
        />
        <meta name="author" content="ISKCON Vijayawada" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.iskconvijayawada.org/courses"
        />
        <meta
          property="og:title"
          content="Learn Bhagavad Gita Teachings | ISKCON Vijayawada Discourses"
        />
        <meta
          property="og:description"
          content="Explore Krishna’s teachings from the Bhagavad Gita. Join spiritual discourses at ISKCON Vijayawada and deepen your understanding of Vedic wisdom."
        />
        <meta property="og:image" content="https://www.iskconvijayawada.org/static/media/logo.467e4780dde7b11ae4cf.png" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Learn Bhagavad Gita Teachings | ISKCON Vijayawada Discourses"
        />
        <meta
          name="twitter:description"
          content="Explore Krishna’s teachings from the Bhagavad Gita. Join spiritual discourses at ISKCON Vijayawada and deepen your understanding of Vedic wisdom."
        />
        <meta name="twitter:image" content="https://www.iskconvijayawada.org/static/media/logo.467e4780dde7b11ae4cf.png" />
      </Helmet>
      
      <Header />
      {/* Banner Section */}
      <div className="w-full relative">
        <img loading="lazy" src={banner} alt="banner" className="w-full " />
        <div className="absolute w-[40%]  bg-[#c61d21]/50 top-[15%] md:top-[30%]  left-[30%] flex justify-center items-center p-4 ">
          <h2 className="text-center text-white text-2xl md:text-9xl font-normal font-['DM Serif Display'] m-0 uppercase ">
            Courses
          </h2>
        </div>
      </div>

      <div
        className="main"
        style={{
          background: `url(${bg})`,
          backgroundSize: "cover",
        }}
      >
        <div className="w-[85%] mx-auto text-center relative flex flex-col justify-center items-center subheading">
          <h3 className="text-black text-xl md:text-[82px] md:font-normal font-['DM Serif Display'] font-bold ">
            PROGRESS ON THE PATH
            <br />
          </h3>
          <p className="text-center md:text-center text-black text-base md:text-4xl font-normal font-['Open Sans'] md:leading-[44px] m-0">
            No matter where you are on your spiritual journey we offer a wide
            cross-section of seminars and courses to help you progress on the
            path of Bhakti and Krishna Consciousness. Our courses are all free
            of cost and open to all.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 box gap-5 md:gap-14">
          {paths.map((path) => (
            <div
              onClick={() => handleCardClick(path.link)}
              className="bg-[#f6f2e8] rounded-xl md:rounded-[25px] shadow-[-1px_6px_10.6px_7px_rgba(0,0,0,0.25)] cursor-pointer transition-transform hover:scale-105"
              key={path.id}
            >
              <img
                loading="lazy"
                src={path.Image}
                alt={path.title}
                className="w-[110%]"
              />
              <p className="text-[#c61d21] text-base md:text-4xl font-semibold font-['Poppins'] content m-0">
                {path.title}
              </p>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Courses;
