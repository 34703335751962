import { useState, useEffect } from "react";
import axios from "axios";

const SubmitButton = ({ loading }) => {
  return (
    <button
      type="submit"
      disabled={loading}
      className="w-full bg-[#c61d21] text-white py-2 rounded-md font-bold hover:bg-red-700 transition duration-300 relative disabled:opacity-70 disabled:cursor-not-allowed"
    >
      <span className={`${loading ? "opacity-0" : "opacity-100"}`}>Register Now</span>
      {loading && (
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
        </div>
      )}
    </button>
  );
};

const SevaForm = ({ sevaData, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    dateOfBirth: "",
    sevaType: sevaData.title,
    sevaAmount: sevaData.amount.toString(),
    paymentStatus: "initiated",
    paymentId: "",
    paymentMethod: "Razorpay"
  });

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.style.overflow = 'auto';
      document.body.removeChild(script);
    };
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData(prev => ({ ...prev, [id]: value }));
  };

  const submitFormData = async (paymentStatus, paymentId = "") => {
    const submissionData = {
      ...formData,
      paymentStatus,
      paymentId,
      timestamp: new Date().toISOString()
    };
    console.log("Submitting to Google Sheets:", {
      ...submissionData,
      // Redact sensitive data for logs
      phone: submissionData.phone.substring(0, 3) + '...',
      paymentId: submissionData.paymentId.substring(0, 4) + '...'
    });
    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbxIiNYbQXeq09d6XjJ8oAmOoHpGTWDHQP2z5Lx8aFZKCrO_Gjiix12Jrdkj_RaKQqfRKg/exec",
        {
          method: "POST",
          body: new URLSearchParams(submissionData),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the form.");
      }
      return true;
    } catch (error) {
      console.error("Error submitting form:", error);
      return false;
    }
  };

  const handlePayment = async () => {
    try {
      // Create order on backend
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/payment/create-order`,
        {
          amount: sevaData.amount * 100,
          currency: "INR",
          receipt: `seva_${Date.now()}`,
          notes: {
            sevaType: sevaData.title,
            customerName: formData.name,
            customerEmail: formData.email
          }
        }
      );

      const order = response.data;

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: sevaData.amount * 100,
        currency: "INR",
        name: "ISKCON Seva",
        description: sevaData.title,
        order_id: order.id,
        handler: async function (response) {
          try {
            // Verify payment
            const verificationResponse = await axios.post(
              `${process.env.REACT_APP_API_URL}/api/payment/verify-payment`,
              {
                razorpay_order_id: response.razorpay_order_id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
              }
            );

            if (verificationResponse.data.success) {
              // Update payment status and ID
              setFormData(prev => ({
                ...prev,
                paymentStatus: "success",
                paymentId: response.razorpay_payment_id
              }));
              
              // Submit final form data
              await submitFormData("success", response.razorpay_payment_id);
              alert("Payment Successful! Your seva has been registered.");
              onClose();
            } else {
              await submitFormData("verification_failed", response.razorpay_payment_id);
              alert("Payment verification failed. Please contact support.");
            }
          } catch (err) {
            console.error(err);
            await submitFormData("verification_error", response.razorpay_payment_id);
            alert("Payment verification error. Please contact support.");
          } finally {
            setIsLoading(false);
          }
        },
        theme: { color: "#C61D21" },
      };

      const paymentObject = new window.Razorpay(options);
      
      paymentObject.on("payment.failed", async function (response) {
        await submitFormData(
          "failed", 
          response.error.metadata?.payment_id || ""
        );
        alert(`Payment Failed: ${response.error.description}`);
        setIsLoading(false);
      });

      // Submit initial form data before opening payment dialog
      await submitFormData("initiated");
      paymentObject.open();
    } catch (error) {
      console.error("Error initiating payment:", error);
      await submitFormData("Payment Failed");
      alert("Failed to initiate payment. Please try again.");
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.name.trim() ||
      !formData.email.trim() ||
      !formData.phone.trim() ||
      !formData.dateOfBirth
    ) {
      alert("Please enter all fields!");
      return;
    }

    setIsLoading(true);
    handlePayment();
  };

  return (
<div className="flex flex-col md:flex-row bg-white shadow-lg rounded-lg overflow-hidden 
    w-[300px] sm:w-[95%] md:w-[90%] max-w-5xl 
    mx-auto 
    min-h-[500px] md:h-[500px]
    relative"
>
      {/* Left Side - Image (hidden on mobile, visible on md and above) */}
      <div className="hidden md:block w-full md:w-[40%] h-full overflow-hidden">
        <img
          loading="lazy"
          src={sevaData.image}
          alt="ISKCON Seva"
          className="object-cover w-full h-full"
          style={{ objectPosition: 'center' }}
        />
      </div>
  
      {/* Right Side - Form */}
      <div className="w-full md:w-[60%] p-4 md:p-6 
        flex flex-col justify-between 
        overflow-y-auto"
      >
        <div>
          <h2 className="text-[#1b1616] text-xl md:text-2xl font-bold font-['Poppins'] mb-4">
            {sevaData.title} 
            <hr className="w-full border-t-2 border-black my-2" />
          </h2>
  
          <form className="space-y-4 sm:p-4" onSubmit={handleSubmit}>
            <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-3">
              <div>
                <label htmlFor="name" className="text-black text-sm font-bold font-['Inter'] block mb-1">
                  Name :
                </label>
                <input
                  type="text"
                  placeholder="Name"
                  id="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800 w-full h-10 text-sm"
                />
              </div>
              <div>
                <label htmlFor="email" className="text-black text-sm font-bold font-['Inter'] block mb-1">
                  Email :
                </label>
                <input
                  type="email"
                  placeholder="Email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800 w-full h-10 text-sm"
                />
              </div>
  
              <div>
                <label htmlFor="phone" className="text-black text-sm font-bold font-['Inter'] block mb-1">
                  Phone :
                </label>
                <input
                  type="tel"
                  placeholder="Phone"
                  id="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800 h-10 text-sm"
                />
              </div>
  
              <div>
                <label htmlFor="dateOfBirth" className="text-black text-sm font-bold font-['Inter'] block mb-1">
                  Date of Birth :
                </label>
                <input
                  type="date"
                  id="dateOfBirth"
                  value={formData.dateOfBirth}
                  onChange={handleChange}
                  required
                  className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800 h-10 text-sm"
                />
              </div>
            </div>
            
            <div className="pt-2">
              <p className="text-md font-semibold">
                Seva Amount: ₹{sevaData.amount.toLocaleString()}
              </p>
            </div>
            
            <SubmitButton loading={isLoading} />
          </form>
        </div>
      </div>
    </div>
  );
};

export default SevaForm;