import React, { useState, useEffect } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Banner from "./assets/images/MainBanner.webp";
import FoodSlider from "./Components/FoodSlider/FoodSlider";
import axios from "axios";
import "./FoodForLife.css";
import { Helmet } from "react-helmet";

const FoodForLife = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    annaDaanSeva: true,
    gitaDaanSeva: false,
    sevaDate: "",
    honoureeName: "",
    relationship: "",
    donorName: "",
    email: "",
    mobile: "",
    dob: "",
    receive80G: false,
    panNumber: "",
    receiveMahaPrasadam: false,
    selectedAnnaDaanAmount: null,
    selectedGitaDaanAmount: null,
    mahaPrasadamDetails: {
      doorNumber: "",
      houseName: "",
      streetName: "",
      area: "",
      country: "",
      state: "",
      city: "",
      pincode: "",
    },
  });

  const [errors, setErrors] = useState({});
  const [show80GDetails, setShow80GDetails] = useState(false);
  const [showMahaPrasadamDetails, setShowMahaPrasadamDetails] = useState(false);
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      };
      if (name === "customAnnaDaanAmount" && value) {
        updatedData.selectedAnnaDaanAmount = parseFloat(value);
      } else if (name === "customGitaDaanAmount" && value) {
        updatedData.selectedGitaDaanAmount = parseFloat(value);
      }

      return updatedData;
    });
  };

  const handle80GCheckboxChange = (e) => {
    setShow80GDetails(e.target.checked);
    handleInputChange(e);
  };

  const handleMahaPrasadamCheckboxChange = (e) => {
    setShowMahaPrasadamDetails(e.target.checked);
    handleInputChange(e);
  };

  const handleAmountSelect = (section, amount) => {
    setFormData((prevData) => {
      if (section === "annaDaan") {
        const newAmount =
          prevData.selectedAnnaDaanAmount === amount ? null : amount;
        return { ...prevData, selectedAnnaDaanAmount: newAmount };
      } else if (section === "gitaDaan") {
        // Deselect if the same amount is clicked again
        const newAmount =
          prevData.selectedGitaDaanAmount === amount ? null : amount;
        return { ...prevData, selectedGitaDaanAmount: newAmount };
      }
      return prevData;
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    if (
      formData.donorName === "" ||
      formData.email === "" ||
      formData.mobile === "" ||
      formData.dob === "" ||
      (formData.want80G && formData.panNumber === "")
    ) {
      alert("Please enter all required fields");
      return;
    }

    const totalAmount = getTotalAmount();
    let isFormDataStored = false; // Flag to prevent duplicate storage

    try {
      // Create order on backend
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/payment/create-order`,
        {
          amount: totalAmount,
          currency: "INR",
        }
      );

      const order = response.data;
      let sevaDescription = "Donation Payment";

      // Determine the seva description
      if (formData.annaDaanSeva && formData.gitaDaanSeva) {
        sevaDescription = "Anna Daan Seva & Gita Daan Seva";
      } else if (formData.annaDaanSeva) {
        sevaDescription = "Anna Daan Seva";
      } else if (formData.gitaDaanSeva) {
        sevaDescription = "Gita Daan Seva";
      }

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: totalAmount * 100,
        currency: "INR",
        name: "ISKCON Maha Kumbh Mela",
        description: sevaDescription,
        order_id: order.id,
        notes: {
          donorName: formData.donorName,
          donorPhoneNumber: formData.mobile,
          donorEmail: formData.email,
          sevaDate: formData.sevaDate,
          honoureeName: formData.honoureeName,
          relationship: formData.relationship,
          ...(formData.receive80G && { panNumber: formData.panNumber }),
        },
        handler: async function (response) {
          try {
            // Verify payment
            const verificationResponse = await axios.post(
              `${process.env.REACT_APP_API_URL}/api/payment/verify-payment`,
              {
                razorpay_order_id: response.razorpay_order_id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
                donationDetails: { ...formData, amount: totalAmount },
              }
            );

            if (verificationResponse.data.success) {
              alert("Payment Successful!");
              if (!isFormDataStored) {
                await storeFormData("Payment Successful");
                isFormDataStored = true; // Mark data as stored
              }
            }
          } catch (err) {
            console.error(err);
            alert("Payment verification failed");
            if (!isFormDataStored) {
              await storeFormData("Payment Failed");
              isFormDataStored = true; // Mark data as stored
            }
          }
        },
        prefill: {
          name: formData.donorName,
          email: formData.email,
          contact: formData.mobile,
        },
        theme: { color: "#C61D21" },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();

      paymentObject.on("payment.failed", async function (response) {
        alert("Payment Failed: " + response.error.description);
        if (!isFormDataStored) {
          await storeFormData("Payment Failed");
          isFormDataStored = true; // Mark data as stored
        }
      });
    } catch (error) {
      console.error("Error initiating payment:", error);
      alert("Failed to initiate payment. Please try again.");
      if (!isFormDataStored) {
        await storeFormData("Payment Failed");
        isFormDataStored = true; // Mark data as stored
      }
    }
  };

  const storeFormData = async (paymentStatus) => {
    const sheetDbURL = "https://sheetdb.io/api/v1/8y5xejo71udvy";
    const totalDonationAmount =
      (formData.selectedAnnaDaanAmount || 0) +
      (formData.selectedGitaDaanAmount || 0);

    const dataToStore = {
      paymentStatus, // New column
      annaDaanSeva: formData.annaDaanSeva ? "Yes" : "",
      gitaDaanSeva: formData.gitaDaanSeva ? "Yes" : "",
      selectedAnnaDaanAmount: formData.selectedAnnaDaanAmount || 0,
      selectedGitaDaanAmount: formData.selectedGitaDaanAmount || 0,
      totalDonationAmount,
      sevaDate: formData.sevaDate,
      honoureeName: formData.honoureeName,
      relationship: formData.relationship,
      donorName: formData.donorName,
      email: formData.email,
      mobile: formData.mobile,
      dob: formData.dob,
      receive80G: formData.receive80G,
      panNumber: formData.panNumber,
      receiveMahaPrasadam: formData.receiveMahaPrasadam,
      ...(formData.receiveMahaPrasadam && {
        mahaPrasadamDetails: formData.mahaPrasadamDetails,
      }),
    };

    try {
      const response = await fetch(sheetDbURL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ data: dataToStore }),
      });

      if (response.ok) {
        console.log("Data stored successfully.");
      } else {
        alert("Error storing donor data.");
      }
    } catch (error) {
      console.error("Error storing data: ", error);
      alert("An error occurred while storing details. Please try again.");
    }
  };

  const getTotalAmount = () => {
    const annaAmount =
      formData.selectedAnnaDaanAmount ||
      parseFloat(formData.customAnnaDaanAmount) ||
      0;
    const gitaAmount =
      formData.selectedGitaDaanAmount ||
      parseFloat(formData.customGitaDaanAmount) ||
      0;
    return annaAmount + gitaAmount;
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // const [isReadMoreVisible, setIsReadMoreVisible] = useState(false);

  // const toggleReadMore = () => {
  //   setIsReadMoreVisible((prev) => !prev);
  // };
  const validateForm = () => {
    return true;
  };
  return (
    <>
      <Helmet>
        {/* Basic SEO */}
        <title>
          Annadan Seva at ISKCON Vijayawada | Free Food Distribution
        </title>
        <meta
          name="description"
          content="Join ISKCON Vijayawada’s Annadan Seva. Help distribute free meals to the needy and support our food charity program."
        />
        <meta
          name="keywords"
          content="ISKCON Annadan Seva, free food donation, Krishna temple charity, food for the needy"
        />
        <meta name="author" content="ISKCON Vijayawada" />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://www.iskconvijayawada.org/food-for-life"
        />

        {/* Open Graph (Facebook & LinkedIn) */}
        <meta
          property="og:title"
          content="Annadan Seva at ISKCON Vijayawada | Free Food Distribution"
        />
        <meta
          property="og:description"
          content="Join ISKCON Vijayawada’s Annadan Seva. Help distribute free meals to the needy and support our food charity program."
        />
        <meta property="og:image" content={Banner} />
        <meta
          property="og:url"
          content="https://www.iskconvijayawada.org/food-for-life"
        />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Annadan Seva at ISKCON Vijayawada | Free Food Distribution"
        />
        <meta
          name="twitter:description"
          content="Join ISKCON Vijayawada’s Annadan Seva. Help distribute free meals to the needy and support our food charity program."
        />
        <meta name="twitter:image" content={Banner} />

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "ISKCON Vijayawada",
            url: "https://www.iskconvijayawada.org",
            logo: "https://www.iskconvijayawada.org/path-to-logo.jpg",
            sameAs: [
              "https://www.facebook.com/iskconvijayawada",
              "https://twitter.com/iskconvijayawada",
              "https://www.instagram.com/iskconvijayawada",
            ],
            contactPoint: {
              "@type": "ContactPoint",
              telephone: "+91-8006880038",
              email: "seva@iskconvijayawada.org",
              contactType: "customer service",
            },
            address: {
              "@type": "PostalAddress",
              streetAddress: "ISKCON House, Skew Bridge, KrishnaLanka",
              addressLocality: "Vijayawada",
              postalCode: "520013",
              addressCountry: "IN",
            },
            department: {
              "@type": "NGO",
              name: "Food for Life",
              url: "https://www.iskconvijayawada.org/food-for-life",
              description:
                "Food for Life is ISKCON's initiative to distribute free vegetarian meals to underprivileged individuals, aiming to eradicate hunger and promote equality.",
              parentOrganization: {
                "@type": "Organization",
                name: "ISKCON Vijayawada",
                url: "https://www.iskconvijayawada.org",
              },
            },
          })}
        </script>
      </Helmet>

      <Header />
      <img
        loading="lazy"
        src={Banner}
        alt="Banner for Food for Life"
        className="food-life-banner"
      />
      <div className="food-life-page">
        <div className="content-section">
          <h1 className="food-life-head">HELP CREATE A HUNGER-FREE WORLD</h1>
          <h5 className="food-life-text">
            At ISKCON Vijayawada, our Food for Life program began as a heartfelt
            mission during the COVID-19 pandemic, aimed at nourishing not just
            the bodies, but the spirits of those facing hunger and hardship. As
            lockdowns left many in desperate need, we stepped in to serve
            hundreds of warm, freshly prepared vegetarian meals daily, offering
            more than just food — we offered hope, dignity, and love. In 2020
            alone, we distributed over 50,000 meals to families affected by the
            pandemic.
          </h5>
          <h5 className="food-life-text">
            When the devastating floods struck Vijayawada, our commitment
            deepened. We mobilized quickly, providing essential relief to those
            impacted, serving over 20,000 meals to families who lost their homes
            and livelihoods. Each meal, lovingly prepared as prasadam, carries
            the blessings of Lord Krishna, reminding those we serve that they
            are not forgotten, that someone cares.
          </h5>
          <h5 className="food-life-text">
            This simple act of sharing food has become a powerful message of
            compassion, restoring faith and lighting up lives touched by
            struggle. At Food for Life, we believe that no one should face
            hunger alone, and together, we can create a community bound by
            kindness and care.
          </h5>

          <FoodSlider />

          <h5 className="food-life-text pt-4">
            All it takes is ₹30 to free someone of hunger. That’s the cost of
            one meal where the highest quality ingredients are sourced and
            cooked to the highest standards and distributed as prasad. Just
            thirty rupees from you can provide a wholesome meal to someone in
            need of sustenance.
          </h5>
          <h5 className="food-life-text">
            Srila Prabhupada encouraged free prasad distribution right from 1966
            when he founded the Hare Krishna Movement. He would often say that
            by generous prasad and sankirtan distribution the whole world can
            attain peace and prosperity.
          </h5>
          <h5 className="food-life-text">
            Everything you offer on the altar becomes Prasad, in that it becomes
            spiritualised. The Lord enters into the offerings and the prasad is
            no different from Him. Lord Chaitanya Mahaprabhu said that remnants
            of food that Krishna has eaten surpass heavenly nectar and only
            persons who have the full mercy of Krishna can receive such
            remnants. Hare Krishna!
          </h5>
        </div>
        <div className="contribute-section">
          <div className="d-flex justify-content-center">
            <div className="food-life-span">
              <span>It takes only ₹30 to free someone of hunger</span>
            </div>
          </div>
          <div className="secHead">
            <span className="food-life-span">Contribute Now</span>
          </div>

          {/* <div className="contribution">
            <div className="item first">
              <h3>Feed 5000 People</h3>
              <button>Donate ₹150000</button>
            </div>
            <div className="item second">
              <h3>Feed 500 People</h3>
              <button>Donate ₹15000</button>
            </div>
            <div className="item third">
              <h3>Feed 2000 People</h3>
              <button>Donate ₹60000</button>
            </div>
            <div className="item fourth">
              <h3>Feed 250 People</h3>
              <button>Donate ₹7500</button>
            </div>
            <div className="item fifth">
              <h3>Feed 1000 People</h3>
              <button>Donate ₹30000</button>
            </div>
            <div className="item sixth">
              <h3>Feed 100 People</h3>
              <button>Donate ₹3000</button>
            </div>
            <div className="item seventh">
              <h3>Donate as your wish</h3>
              <button>Donate</button>
            </div>
          </div> */}
          <div className="xl:flex flex-row-reverse justify-content-center">
            <div className=" xl:w-[72%] xl:pb-24">
              <div className="donationDetail mx-2 bg-[#C61D21] py-10 px-2 rounded-3xl xl:h-full">
                <form onSubmit={handleSubmit} className="text-white">
                  <h2 className="text-center text-2xl xl:text-4xl">
                    Donation Details
                  </h2>

                  <div className="innerForm sevaContainer">
                    {/* Anna Daan Seva */}
                    <label>
                      <div
                        // type="checkbox"
                        name="annaDaanSeva"
                        checked={formData.annaDaanSeva}
                        onChange={handleInputChange}
                        style={{ accentColor: "blue" }}
                      />
                      Anna Daan Seva
                    </label>
                    {formData.annaDaanSeva && (
                      <div className="grid grid-cols-2 -mt-4">
                        {[
                          { amount: 60000, text: "(Feed 2000 People)" },
                          { amount: 30000, text: "(Feed 1000 People)" },
                          { amount: 15000, text: "(Feed 500 People)" },
                          { amount: 7500, text: "(Feed 250 People)" },
                          { amount: 3000, text: "(Feed 100 People)" },
                        ].map((item) => (
                          <button
                            className="col-span-1 font-semibold p-3 bg-[#F9E7D0] rounded-xl text-black"
                            type="button"
                            key={item.amount}
                            onClick={() =>
                              handleAmountSelect("annaDaan", item.amount)
                            }
                            style={{
                              backgroundColor:
                                formData.selectedAnnaDaanAmount === item.amount
                                  ? "#fac785"
                                  : "",
                              margin: "5px",
                            }}
                          >
                            ₹ {item.amount.toLocaleString("en-IN")} {item.text}
                          </button>
                        ))}
                        <input
                          type="number"
                          name="customAnnaDaanAmount"
                          placeholder="Donate as you wish"
                          value={formData.customAnnaDaanAmount || ""}
                          onChange={handleInputChange}
                          className="col-span-2 p-3 border rounded-xl my-1 mx-1 customAmount"
                        />
                      </div>
                    )}

                    {/* Gita Daan Seva */}
                    {/* <label>
                    <input
                      type="checkbox"
                      name="gitaDaanSeva"
                      checked={formData.gitaDaanSeva}
                      onChange={handleInputChange}
                    />
                    Gita Daan Seva
                  </label>
                  {formData.gitaDaanSeva && (
                    <div className="grid grid-cols-2 -mt-4">
                      {[28080, 14040, 5460, 2860].map((amount) => (
                        <button
                          className="col-span-1 font-semibold p-3 bg-[#F9E7D0] rounded-xl text-black"
                          type="button"
                          key={amount}
                          onClick={() => handleAmountSelect("gitaDaan", amount)}
                          style={{
                            backgroundColor:
                              formData.selectedGitaDaanAmount === amount
                                ? "#fac785"
                                : "",
                            margin: "5px",
                          }}
                        >
                          ₹ {amount.toLocaleString("en-IN")}
                        </button>
                      ))}
                      <input
                        type="number"
                        name="customGitaDaanAmount"
                        placeholder="Donate as you wish"
                        value={formData.customGitaDaanAmount || ""}
                        onChange={handleInputChange}
                        className="col-span-2 p-3 border rounded-xl my-1 mx-1 customAmount"
                      />
                    </div>
                  )} */}

                    {/* Seva Date */}

                    <div>
                      <input
                        type="text"
                        name="sevaDate"
                        value={formData.sevaDate}
                        onChange={handleInputChange}
                        placeholder="Seva Date"
                        onFocus={(e) => (e.target.type = "date")}
                        onBlur={(e) => (e.target.type = "text")}
                      />
                      {errors.sevaDate && <span>{errors.sevaDate}</span>}
                    </div>
                  </div>

                  {/* <div className="innerForm">
                  <div className="text-center">
                    <h3>In memory of</h3>
                    <p className="text-white -mt-2">
                      I want my donation to be dedicated to:
                    </p>
                  </div>

                  <label className="xl:w-full">
                    <input
                      type="text"
                      name="honoureeName"
                      value={formData.honoureeName}
                      onChange={handleInputChange}
                      placeholder="Honouree Name"
                    />
                  </label>
                  <label className="xl:w-full">
                    <input
                      type="text"
                      name="relationship"
                      value={formData.relationship}
                      onChange={handleInputChange}
                      placeholder="Relationship"
                    />
                  </label>
                </div> */}

                  <div className="innerForm">
                    <h3 className="text-center">Personal Details</h3>
                    <label className="xl:w-full">
                      <input
                        type="text"
                        name="donorName"
                        value={formData.donorName}
                        onChange={handleInputChange}
                        placeholder="Donor Name"
                        className="mt-2"
                      />
                      {errors.donorName && (
                        <span className="block -mt-2 ml-2">
                          {errors.donorName}
                        </span>
                      )}
                    </label>
                    <label className="xl:w-full">
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        placeholder="Email ID"
                      />
                      {errors.email && (
                        <span className="block -mt-2 ml-2">{errors.email}</span>
                      )}
                    </label>
                    <div className="flex gap-2">
                      <label className="flex-1">
                        <input
                          type="tel"
                          name="mobile"
                          value={formData.mobile}
                          onChange={handleInputChange}
                          placeholder="Mobile Number"
                          className="p-[10px] md:w-full"
                        />
                        {errors.mobile && (
                          <span className="block mt-1 ml-2">
                            {errors.mobile}
                          </span>
                        )}
                      </label>
                      <label className="flex-1">
                        <input
                          type="text"
                          name="dob"
                          value={formData.dob}
                          onChange={handleInputChange}
                          className="text-[#909192] p-2 border rounded-md"
                          placeholder="Date Of Birth"
                          onFocus={(e) => (e.target.type = "date")}
                          onBlur={(e) => (e.target.type = "text")}
                        />
                        {errors.dob && (
                          <span className="block -mt-2 ml-2">{errors.dob}</span>
                        )}
                      </label>
                    </div>
                  </div>
                  <p className="text-white mt-2 text-[10px]">
                    (Sankalpa and Archana will be performed for you on your
                    birthday)
                  </p>

                  <p className="text-white -mt-2 text-xs">
                    By continuing, you are agreeing to{" "}
                    <a className="underline text-[#EEF9A3]">
                      Terms of Use and Privacy Policy
                    </a>
                  </p>

                  {/* Additional checkboxes */}
                  <div className="px-3 tandd mt-8">
                    <label>
                      <input
                        type="checkbox"
                        name="receive80G"
                        checked={formData.receive80G}
                        onChange={handle80GCheckboxChange}
                        style={{ accentColor: "blue" }}
                      />
                      I would like to receive 80(G) Certificate
                    </label>
                    {show80GDetails && (
                      <div className="mb-3">
                        <p className="text-white text-[10px] text-left ml-2">
                          Finance Act 2021 mandates uploading donation details
                          for 80(G) deductions. Ensure PAN and address details
                          are provided.
                        </p>
                        <input
                          type="text"
                          name="panNumber"
                          value={formData.panNumber}
                          onChange={handleInputChange}
                          placeholder="Pan Number *"
                          className="w-full bg-white text-black px-4 py-2 rounded-lg outline-none focus:ring-2 focus:ring-yellow-400"
                          required
                        />
                      </div>
                    )}

                    <label>
                      <input
                        type="checkbox"
                        name="receiveMahaPrasadam"
                        checked={formData.receiveMahaPrasadam}
                        onChange={handleMahaPrasadamCheckboxChange}
                        style={{ accentColor: "blue" }}
                      />
                      I would like to receive Maha prasadam (Only within India)
                    </label>
                    {showMahaPrasadamDetails && (
                      <div className="mt-3">
                        <input
                          type="text"
                          name="doorNumber"
                          placeholder="Door Number"
                          className="text-black"
                          value={formData.mahaPrasadamDetails.doorNumber}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              mahaPrasadamDetails: {
                                ...prev.mahaPrasadamDetails,
                                doorNumber: e.target.value,
                              },
                            }))
                          }
                        />
                        <input
                          type="text"
                          className="text-black"
                          name="houseName"
                          placeholder="House / Apartment / Building Name"
                          value={formData.mahaPrasadamDetails.houseName}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              mahaPrasadamDetails: {
                                ...prev.mahaPrasadamDetails,
                                houseName: e.target.value,
                              },
                            }))
                          }
                        />
                        <input
                          type="text"
                          className="text-black"
                          name="streetName"
                          placeholder="Street Name"
                          value={formData.mahaPrasadamDetails.streetName}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              mahaPrasadamDetails: {
                                ...prev.mahaPrasadamDetails,
                                streetName: e.target.value,
                              },
                            }))
                          }
                        />
                        <input
                          type="text"
                          className="text-black"
                          name="area"
                          placeholder="Location / Area"
                          value={formData.mahaPrasadamDetails.area}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              mahaPrasadamDetails: {
                                ...prev.mahaPrasadamDetails,
                                area: e.target.value,
                              },
                            }))
                          }
                        />
                        <input
                          type="text"
                          className="text-black"
                          name="country"
                          placeholder="Country"
                          value={formData.mahaPrasadamDetails.country}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              mahaPrasadamDetails: {
                                ...prev.mahaPrasadamDetails,
                                country: e.target.value,
                              },
                            }))
                          }
                        />
                        <input
                          type="text"
                          className="text-black"
                          name="state"
                          placeholder="State"
                          value={formData.mahaPrasadamDetails.state}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              mahaPrasadamDetails: {
                                ...prev.mahaPrasadamDetails,
                                state: e.target.value,
                              },
                            }))
                          }
                        />
                        <input
                          type="text"
                          className="text-black"
                          name="city"
                          placeholder="City"
                          value={formData.mahaPrasadamDetails.city}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              mahaPrasadamDetails: {
                                ...prev.mahaPrasadamDetails,
                                city: e.target.value,
                              },
                            }))
                          }
                        />
                        <input
                          type="text"
                          className="text-black"
                          name="pincode"
                          placeholder="Pincode"
                          value={formData.mahaPrasadamDetails.pincode}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              mahaPrasadamDetails: {
                                ...prev.mahaPrasadamDetails,
                                pincode: e.target.value,
                              },
                            }))
                          }
                        />
                      </div>
                    )}
                  </div>

                  <div className="flex justify-center mt-8">
                    <button
                      type="submit"
                      className="bg-[#FAE29C] text-black px-12 py-3 rounded-2xl font-bold"
                    >
                      Donate{" "}
                      {getTotalAmount() > 0
                        ? "₹ " + getTotalAmount().toLocaleString("en-IN")
                        : ""}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FoodForLife;
