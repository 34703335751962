import React, { useEffect } from "react";
import { Star } from "lucide-react";
import scrollImage from "./assets/scroll.png";
import starImage from "./assets/Star 1.png";
import bgImage from "./assets/bg.png"; // Import your background image
import spirit1 from "./assets/sprit1.png";
import spirit2 from "./assets/spirit2.png";
import spirit3 from "./assets/sprit-2.png";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

function Gita4YouRegistration() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleClick = () => {
    window.location.href = "https://www.iskconvjagita4you.org/";
  };
  const images = [
    {
      src: spirit1,
      alt: "First spiritual image",
    },
    {
      src: spirit2,
      alt: "Second spiritual image",
    },
    {
      src: spirit3,
      alt: "Third spiritual image",
    },
  ];
  return (
    <>
      <Header />
      <div className="w-full md:mt-8">
        <div className="flex flex-row gap-0 ">
          {images.map((image, index) => (
            <div
              key={index}
              className="flex-1 h-[350px] md:h-[440px] lg:h-[580px]"
            >
              <img
                src={image.src}
                alt={image.alt}
                className="w-full h-full object-cover object-top"
              />
            </div>
          ))}
        </div>
      </div>
      <div
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          minHeight: "100vh",
        }}
        className="relative flex flex-col items-center lg:px-4 py-8 w-[100%]"
      >
        {/* Optional overlay for better text readability */}
        <div className="absolute inset-0 bg-[#ffeede] bg-opacity-30 pointer-events-none"></div>

        {/* Content wrapper */}
        <div className="relative z-10 w-full flex flex-col items-center">
          {/* Top Banner */}
          <div className="w-full max-w-[95%] bg-[#E92C04] text-white text-center py-3 rounded-xl mb-8">
            <h1 className="text-sm md:text-4xl font-semibold">
              Understand Bhagavat Gita From Home in 18 Days Online
            </h1>
          </div>

          {/* Main Title */}
          <h2 className="text-4xl md:text-6xl font-bold text-[#E83A0C] mb:mb-12">
            GITA 4 YOU
          </h2>

          {/* Scrolls Container */}
          <div className="flex flex-col md:flex-row justify-center md:gap-8 mb-12 w-full md:max-w-[85rem]">
            {/* Left Scroll */}
            <div className="flex-1">
              <div
                className="relative min-h-[28rem] md:min-h-[36rem] bg-no-repeat bg-contain bg-center flex items-center justify-center"
                style={{ backgroundImage: `url(${scrollImage})` }}
              >
                <div className="text-white text-center p-6 absolute top-1/2 left-1/2 transform -translate-x-[49%] -translate-y-[50%] w-[80%]">
                  <h3 className="font-semibold lg:mb-4 text-2xl xl:text-4xl">
                    Feb 19 - March 8
                  </h3>
                  <div className="space-y-2 lg:space-y-5">
                    <h4 className="font-bold text-xl lg:text-3xl">
                      Telugu Batches
                    </h4>
                    <p className="text-lg lg:text-2xl font-bold">6 AM - 7 AM</p>
                    <p className="text-lg lg:text-2xl font-bold">3 PM - 4 PM</p>
                    <p className="text-lg lg:text-2xl font-bold">8 PM - 9 PM</p>
                    <div className="mt-4">
                      <h4 className="font-bold lg:text-3xl">Hindi Batch</h4>
                      <p className="text-lg lg:text-2xl font-bold">
                        3 PM - 4 PM
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Center Star */}
            <div className="flex items-center justify-center">
              <div className="relative w-[12rem] h-[12rem] lg:w-53 lg:h-53">
                <img
                  src={starImage}
                  alt="Star background"
                  className="w-full h-full object-contain"
                />
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="text-white text-center">
                    <h6 className="font-bold text-lg md:text-xl m-0">No</h6>
                    <h6 className="font-bold text-lg md:text-xl m-0">
                      Registration
                    </h6>
                    <h6 className="font-bold text-lg md:text-xl m-0">Fee!</h6>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Scroll */}
            <div className="flex-1">
              <div
                className="relative min-h-[28rem] md:min-h-[36rem] bg-no-repeat bg-contain bg-center flex items-center justify-center"
                style={{ backgroundImage: `url(${scrollImage})` }}
              >
                <div className="text-white text-center p-6 absolute top-1/2 left-[49%] transform -translate-x-[49%] -translate-y-[52%] w-[100%]">
                  <h3 className="font-semibold mb-4 text-3xl lg:text-4xl">
                    Highlights
                  </h3>
                  <ul className="space-y-4 ml-0 pl-[6rem] pr-[4rem] lg:pl-[8rem] lg:pr-[5rem] text-left text-xl lg:text-3xl font-semibold">
                    <li>• Online Certificates</li>
                    <li>• Power Point Presentations</li>
                    <li>• Free E-Course Material</li>
                    <li>• Interactive Quiz</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* Bottom Section */}
          <div className="text-center space-y-4 max-w-3xl w-full pb-6 px-2">
            <div className="bg-[#E83A0C] py-3 px-6 rounded-3xl">
              <span className="text-lg lg:text-3xl font-semibold text-white">
                Completed Course For 40,000+ Participants
              </span>
            </div>
          </div>

          <p className="text-xl text-center lg:text-3xl text-black">
            Facilitator Team lead by{" "}
            <span className="text-[#E83A0C] font-semibold">
              HG Chakradhari Das
            </span>
            , Temple President, B.Tech, IIT KGP
          </p>

          <p className="text-xl lg:text-3xl text-center font-bold text-black pb-8">
            Classes Start from 19 February 2025
          </p>

          <button
            onClick={handleClick}
            className="bg-[#E83A0C] text-white px-20 py-3 rounded-lg text-xl font-semibold hover:bg-[#d33508] transition-colors"
          >
            Register Now
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Gita4YouRegistration;
