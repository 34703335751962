import React from "react";
import "./page3.css";

const Page3 = () => {
  return (
    <>
      <div className="page3">
        <div className="benefits-section">
          <div className="max-w-[100%] md:max-w-[90%] mx-auto">
            <h2 className="benefits-title font-bold">Benefits:</h2>

            <div className="benefits-card">
              <ul className="benefits-list">
                <li>
                  <strong>Scriptural Mastery:</strong>
                  <span className="semi-bold">
                    {" "}
                    Gain A Deep Understanding Of Core Vaishnava Texts.
                  </span>
                </li>
                <li>
                  <strong>Spiritual Growth:</strong>
                  <span className="semi-bold">
                    {" "}
                    Strengthen Your Personal Practice Of Bhakti-Yoga Through The
                    Application Of Scriptural Knowledge.
                  </span>
                </li>
                <li>
                  <strong>Preaching And Leadership:</strong>
                  <span className="semi-bold">
                    {" "}
                    Equip Yourself To Become A Preacher Or Leader In Your Local
                    Congregation.
                  </span>
                </li>
                <li>
                  <strong>Community Support:</strong>{" "}
                  <span className="semi-bold">
                    {" "}
                    Be Part Of A Supportive Group Of Devotees Working Together
                    To Grow Spiritually.
                  </span>
                </li>
              </ul>

              <p className="benefits-description">
                The Bhakti Shastri Program At ISKCON Vijayawada Is An
                Opportunity For Serious Students Of Krishna Consciousness To
                Advance Their Knowledge, Improve Their Devotional Practice, And
                Share The Teachings With Others.
              </p>

              {/* <p className="benefits-cta">
                Join Us And Be A Part Of This Growing Tree Of Devotion!
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Page3;
