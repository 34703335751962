import React, { useState, useEffect } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import "./GauSeva.css";
import DonationForm from "./donationForm";
import banner from "./assets/banner.webp";
import cow from "./assets/cow.webp";
import krishna from "./assets/krishna.webp";
import flower1 from "./assets/flower1.webp";
import flower2 from "./assets/flower2.webp";
import barcode1 from "./assets/barcode1.webp";
import barcode2 from "./assets/barcode2.webp";
import gaumobbg from "./assets/mobile/gaumobbg.webp";

const GauSeva = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [copiedText, setCopiedText] = useState(null);

  const copyToClipboard = async (text, id) => {
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(text);
        setCopiedText(id);
        setTimeout(() => setCopiedText(null), 2000);
      } else {
        alert("Copy functionality is not supported in this browser.");
      }
    } catch (error) {
      console.error("Failed to copy text: ", error);
      alert("Failed to copy. Please try again.");
    }
  };

  return (
    <div>
      <Header />
      {/* banner */}
      <div className="relative bg-yellow-200 md:bg-none">
        <img loading="lazy" src={banner} alt="banner" />
        <div className="flex flex-col justify-center items-start absolute w-[50%] top-[4%] md:top-[18%] left-[5%] md:left-[15%] md:gap-4">
          <h3 className='text-black text-[6px] md:text-3xl font-semibold font-["Open Sans"] m-0 w-[110%] md:w-full'>
            "Whoever feeds the cow with grass and water every day derives the
            benefit equivalent to performing Ashwamedha Yajna. There is no doubt
            about this."
          </h3>
          <p className="text-black text-[8px] md:text-3xl font-normal font-['Open Sans'] m-0">
            (Brhat Parasara Smriti 5.26-27){" "}
          </p>
          <p className="text-black text-[8px] md:text-3xl font-normal font-['Open Sans'] m-0 text-start leading-[8px]">
            Express your care & devotion for cows by offering{" "}
          </p>
          <h2 className="text-[#c61d21] text-base md:text-6xl font-bold font-['Open Sans'] m-0 hidden md:block">
            Gau Poshana Seva{" "}
          </h2>
          <p className="text-black text-[8px] md:text-3xl font-normal font-['Open Sans '] m-0 hidden md:block">
            Avail 80G exemption for your donation
          </p>
        </div>
        <div className="absolute  bottom-[28%]  left-[12%] md:hidden">
          <p className="inline text-[#c61d21] text-xl font-bold font-['Open Sans'] font-bold  text-start m-0">
            Gau Poshana Seva
          </p>
          <br />
          <p className="text-black text-[10px] font-normal font-['Open Sans '] m-0 text-start ">
            Avail 80G Exemption for your donation
          </p>
        </div>
      </div>
      <div className="page w-[100%] pb-[5%] pt-[5%]">

      <div className="hidden md:flex w-[90%] mx-auto gap-2 border-2 border-[#c61d21] bg-[#fff2e2] px-10 rounded-lg">
          <img
            loading="lazy"
            src={krishna}
            alt="krishna"
            className="w-[30%] "
          />
          <div className="flex flex-col items-start justify-center ">
            <h3 className="text-[#c61d21] text-[56px] font-bold font-['Open Sans'] text-start">
              Lord Krishna is described as
            </h3>
            <p className="text-[#c61d21] text-4xl font-normal font-['Open Sans'] text-start">
              "namo brahmanya devaya go-brahmana hitaya ca"
            </p>
            <p className="text-black text-3xl font-normal font-['Open Sans'] text-start">
              (Vishnu Purana 1.19.65), the well-wisher of cows. This was
              well-reflected in His care for cows as a cowherd boy in
              Vrindavana. In fact, Lord Krishna's other names, Govinda and
              Gopala mean, “friend and protector of cows”
            </p>
          </div>
        </div>

        {/* krishna Description Mobile */}
        <div
          className="w-[90%] mx-auto border-2 border-[#c61d21] rounded-2xl mt-[30px] py-2 px-3 md:hidden"
          style={{
            boxShadow: "0 0 20px #df744d",
          }}
        >
          <div>
            <h3 className="text-[#c61d21] text-lg font-bold font-['Open Sans'] text-center m-0">
              Lord Krishna is described as{" "}
            </h3>
            <p className="text-[#c61d21] text-[10px] font-normal font-['Open Sans'] text-center my-1">
              "namo brahmanya devaya go-brahmana hitaya ca"
            </p>
          </div>
          <div className="flex gap-1 items-center">
            <img loading="lazy" src={krishna} alt="" className="w-[30%]" />
            <p className="text-black text-[9px] font-normal font-['Open Sans'] text-start m-0">
              (Vishnu Purana 1.19.65), the well-wisher of cows. This was
              well-reflected in His care for cows as a cowherd boy in
              Vrindavana. In fact, Lord Krishna's other names, Govinda and
              Gopala mean, “friend and protector of cows”
            </p>
          </div>
        </div>
        <DonationForm />
        {/* Krishna Description */}

        {/* Gaushala */}
        {/* <h3
          className=" text-[#c61d21] text-2xl md:text-[64px] font-bold font-['Open Sans'] text-center my-[3%]"
          style={{
            textShadow: "0 0 20px rgb(255, 255, 0, 0.9)",
          }}
        >
          Our Gaushala at a glance
        </h3> */}
        {/* <div className="md:flex  justify-between w-full gau p-5 hidden  ">
          <div className="w-[70%]  ">
            <h4 className="text-[#c61d21] text-5xl font-bold font-['Open Sans'] m-0 text-start">
              Care:
            </h4>
            <p className="text-black text-[40px] font-normal font-['Open Sans'] m-0 text-start">
              Daily cow care, medical care, once a month routine check-up,
              emergency care
            </p>
            <h4 className="text-[#c61d21] text-5xl font-bold font-['Open Sans'] m-0 text-end">
              Facilities for the cows:
            </h4>
            <p className="text-black text-[40px] font-normal font-['Open Sans'] m-0 text-end">
              {" "}
              Clean, hygienic indoor & outdoor sheds, gutters for proper
              cleanliness, 24 hours clean water connection, large grazing field,
              lush green trees for cool breeze
            </p>
          </div>
          <img loading="lazy" src={cow} alt="" className="w-[30%]" />
        </div> */}

        {/* Gaushal Mobile */}
        {/* <div
          className="w-full py-3 mb-5 md:hidden"
          style={{
            boxShadow: "0 0 20px #f9c0ab",
            backgroundImage: `url(${gaumobbg})`,
            backgroundSize: "cover",
          }}
        >
          <div className="w-[80%]  mx-auto mb-[15px]">
            <p className="text-[#c61d21] text-[11px] font-bold font-['Open Sans'] m-0 ">
              Care:{" "}
              <span className="text-black font-normal">
                Daily cow care , medical care, once a month routine check-up ,
                emergency care
              </span>
            </p>
          </div>
          <div className="w-[90%] ml-[10%] flex ">
            <div className="flex flex-col gap-2">
              <h2 className="text-[#c61d21] text-xs font-bold font-['Open Sans'] m-0 text-end">
                Facilities for the cows:
              </h2>
              <p className="text-black text-[10px] font-normal font-['Open Sans'] m-0 text-end">
                Clean, hygienic indoor & outdoor sheds, gutters for proper
                cleanliness, 24 hours clean water connection, large grazing
                field, lush green trees for cool breeze
              </p>
            </div>
            <img loading="lazy" src={cow} alt="" className="w-[30%]" />
          </div>
        </div> */}

        {/* Donatation */}
        {/* <div className="w-[95%] md:w-full mx-auto donate md:pb-[2%]">
          <div className="w-full md:w-[70%] flex justify-between mx-auto items-center">
            <img loading="lazy" src={flower1} alt="" className="w-[20%]" />
            <h2 className="text-[#c61d21] text-base md:text-6xl font-bold font-['Open Sans'] m-0">
              Donate as your wish
            </h2>
            <img loading="lazy" src={flower2} alt="" className="w-[20%]" />
          </div>
        </div> */}
        {/* donation section */}
        <div className="w-[90%] md:w-[70%] border-solid border-2 border-[#C61D21] rounded-lg mx-auto">
          <h2 className="md:py-4 py-3 bg-[#C61D21] text-white text-center md:text-3xl text-base">
            Donation Through Bank (NEFT/RTGS){" "}
          </h2>
          <ul className=" flex flex-col justify-center xl:pl-80 md:pl-40 pl-0 md:items-start md:space-y-5 space-y-2 leading-4 my-4 items-center ">
            <li className="w-full md:text-2xl text-base  leading-2 tracking-none font-bold text-black px-2 md:px-0 flex justify-between md:justify-normal">
              <span className="md:text-2xl text-base text-[#C61D21] ">
                Bank Name:<span className="text-black"> HDFC Bank</span>
              </span>{" "}
              <button
                class="bg-gray-200 md:text-xs text-[10px] px-2 md:py-0.5 border-1 border-[#C61D21] ml-2 rounded-full"
                onClick={() => copyToClipboard("HDFC Bank", "bankName")}
              >
                {copiedText === "bankName" ? "Copied!" : "Copy"}
              </button>
            </li>
            <li className="w-full md:text-2xl text-base  leading-2 tracking-none font-bold text-black px-2 md:px-0 flex justify-between md:justify-normal">
              <span className="md:text-2xl text-base text-[#C61D21]">
                Account Name:<span className="text-black"> ISKCON</span>
              </span>{" "}
              <button
                class="bg-gray-200 md:text-xs text-[10px] px-2 md:py-0.5  border-1 border-[#C61D21] ml-2 rounded-full"
                onClick={() => copyToClipboard("ISKCON", "accountName")}
              >
                {copiedText === "accountName" ? "Copied!" : "Copy"}
              </button>
            </li>
            <li className="w-full md:text-2xl text-base  leading-2 tracking-none font-bold text-black px-2 md:px-0 flex justify-between md:justify-normal">
              <span className=" text-[#C61D21]">
                Account Number:{" "}
                <span className="text-black">50100436709020</span>
              </span>{" "}
              <button
                class="bg-gray-200 md:text-xs text-[10px] px-2 md:py-0.5 border-1 border-[#C61D21] ml-2 rounded-full"
                onClick={() =>
                  copyToClipboard("50100436709020", "accountNumber")
                }
              >
                {copiedText === "accountNumber" ? "Copied!" : "Copy"}
              </button>
            </li>
            <li className="w-full md:text-2xl text-base  leading-2 tracking-none font-bold text-black px-2 md:px-0 flex justify-between md:justify-normal ">
              <span className=" text-[#C61D21]">
                IFSC Code: <span className="text-black">HDFC000568</span>
              </span>
              <button
                class="bg-gray-200 md:text-xs text-[10px] px-2 md:py-0.5 border-1 border-[#C61D21] ml-2 rounded-full"
                onClick={() => copyToClipboard("HDFC000568", "ifscCode")}
              >
                {copiedText === "ifscCode" ? "Copied!" : "Copy"}
              </button>
            </li>
            <li className="w-full md:text-2xl text-base leading-2 tracking-none font-bold text-black px-2 md:px-0 flex justify-between md:justify-normal">
              <span className=" text-[#C61D21]">
                Branch: <span className="text-black">ONE TOWN VIJAYAWADA </span>
              </span>{" "}
              <button
                class="bg-gray-200 md:text-xs text-[10px] px-2 md:py-0.5 border-1 border-[#C61D21] ml-2 rounded-full"
                onClick={() => copyToClipboard("ONE TOWN VIJAYAWADA", "branch")}
              >
                {copiedText === "branch" ? "Copied!" : "Copy"}
              </button>
            </li>
          </ul>
        </div>

        <div className="mb-3 w-[90%] md:w-[70%] md:mx-44  md:border-5 border-2 md:rounded-2xl rounded-lg border-[#C61D21] mt-10 overflow-hidden mx-auto">
          <h2 className="md:py-4 py-2 bg-[#C61D21] text-white text-center md:text-3xl text-base">
            Donate Through UPI
          </h2>

          <div className="flex md:flex-row flex-col justify-around items-center pt-3 md:pb-3 pb-4 gap-4">
            <div className="md:block flex gap-2">
              <h2 className="md:text-2xl text-base text-[#C61D21]">UPI ID:</h2>
              <p className="md:text-xl text-base  text-black">
                {" "}
                ISCKONVIJAYAWADA@IDFCBANK
              </p>
            </div>
            <img
              loading="lazy"
              src={barcode1}
              alt=""
              width="200px"
              height="200px"
            />
            <p className="lg:text-3xl text-lg font-semibold text-[#C61D21]">
              OR
            </p>
            <img
              loading="lazy"
              src={barcode2}
              alt=""
              width="200px"
              height="200px"
            />
          </div>
        </div>

        <div className="text-left md:mx-44 pb-10 p-2 mb-5">
          <h2 className="text-3xl  text-[#C61D21] text-left font-bold">
            After Donation:
          </h2>
          <p className="md:text-2xl mt-4 text-base text-black w-full text-justify font-semibold">
            Once you have donated, please share the transaction details or
            receipt via WhatsApp at{" "}
            <span className="text-red">+91 9955685568</span>. This helps us
            acknowledge your contribution and issue a receipt for tax exemption
            under Section 80G of the Income Tax Act.
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default GauSeva;
