"use client"

import { useState, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import "bootstrap/dist/css/bootstrap.min.css"
import { House, Building, CreditCard, ChevronDown, ChevronUp } from "react-bootstrap-icons"

const BottomNavigation = () => {
  const [activeTab, setActiveTab] = useState("home")
  const [openSubmenu, setOpenSubmenu] = useState(null)
  const [isMounted, setIsMounted] = useState(false)
  const navigate = useNavigate()
  const submenuRef = useRef(null)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  // Add event listener to close submenu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        openSubmenu && 
        submenuRef.current && 
        !submenuRef.current.contains(event.target)
      ) {
        setOpenSubmenu(null)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [openSubmenu])

  // Navigation configuration
  const navConfig = {
    home: {
      icon: House,
      path: "/",
      hasSubmenu: false
    },
    temple: {
      icon: Building,
      path: null,
      hasSubmenu: true,
      submenu: [
        { name: "Arti", path: "/arti" },
        { name: "Saturday Satsang", path: "/SaturdaySatsang" },
        { name: "Sunday Feast", path: "/SundayFeast" },
      ]
    },
    donate: {
      icon: CreditCard,
      path: "/donation-temp",
      hasSubmenu: false
    }
  }

  const handleTabClick = (tabName) => {
    const tabConfig = navConfig[tabName]
    
    // Close other menus when a new tab is clicked
    if (activeTab !== tabName) {
      setOpenSubmenu(null)
    }

    // Set active tab
    setActiveTab(tabName)
    
    // Handle navigation or submenu
    if (tabConfig.path) {
      navigate(tabConfig.path)
      return
    }
    
    if (tabConfig.hasSubmenu) {
      setOpenSubmenu(prevOpen => prevOpen === tabName ? null : tabName)
    }
  }

  const handleSubmenuClick = (path) => {
    navigate(path)
    setOpenSubmenu(null)
  }

  return (
    <>
      {/* Submenu Panel */}
      {isMounted && openSubmenu && (
        <div 
          ref={submenuRef}
          className="submenu-container fixed-bottom shadow-lg pb-3 animate-submenu-mobile" 
          style={{ 
            bottom: "56px",
            zIndex: 1029,
            maxHeight: "calc(100vh - 112px)", 
            overflowY: "auto",
            backgroundColor: "#c61d21", 
            color: "white", 
            borderTopLeftRadius: "15px",
            borderTopRightRadius: "15px",
            transform: "translateY(0)",
            opacity: 1,
            transition: "transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 0.4s ease"
          }}
        >
          <div className="p-3">
            <div>
              <div className="d-flex justify-content-between align-items-center mb-3 px-2">
                <h6 className="mb-0 fw-bold text-white">
                  {openSubmenu.charAt(0).toUpperCase() + openSubmenu.slice(1)}
                </h6>
                <button 
                  className="btn btn-sm p-0" 
                  onClick={() => setOpenSubmenu(null)} 
                  style={{ 
                    color: "white",
                    transition: "transform 0.2s ease"
                  }}
                  onMouseEnter={(e) => e.currentTarget.style.transform = "scale(1.1)"}
                  onMouseLeave={(e) => e.currentTarget.style.transform = "scale(1)"}
                >
                  <ChevronDown size={20} />
                </button>
              </div>
              <div className="list-group">
                {navConfig[openSubmenu].submenu.map((item, index) => (
                  <button
                    key={index}
                    className="list-group-item list-group-item-action border-0 py-3 text-start submenu-item-mobile"
                    style={{
                      backgroundColor: "rgba(255,255,255,0.1)", 
                      color: "white",
                      marginBottom: "8px",
                      borderRadius: "8px",
                      transition: "all 0.3s ease",
                      transform: "translateY(0)",
                      opacity: 1,
                      animationDelay: `${index * 0.1}s`
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = "rgba(255,255,255,0.2)";
                      e.target.style.transform = "translateY(-5px)";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "rgba(255,255,255,0.1)";
                      e.target.style.transform = "translateY(0)";
                    }}
                    onClick={() => handleSubmenuClick(item.path)}
                  >
                    {item.name}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Bottom Navigation */}
      <div 
        className="fixed-bottom shadow-lg d-md-none d-block" 
        style={{ 
          backgroundColor: "#c61d21", 
          zIndex: 1030 
        }}
      >
        <div 
          className="d-flex justify-content-around " 
        >
          {Object.entries(navConfig).map(([tabName, config]) => {
            const Icon = config.icon
            return (
              <div
                key={tabName}
                className={`nav-item d-flex flex-column align-items-center ${activeTab === tabName ? "active" : ""}`}
                onClick={() => handleTabClick(tabName)}
                style={{
                  cursor: "pointer",
                  padding: "8px 16px",
                  color: activeTab === tabName ? "white" : "#ffcdd2",
                  transition: "all 0.3s ease",
                  borderRadius: "10px",
                }}
                onMouseEnter={(e) => {
                  if (activeTab !== tabName) {
                    e.currentTarget.style.backgroundColor = "rgba(255,255,255,0.1)"
                  }
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "transparent"
                }}
              >
                <Icon size={24} />
                <span className="nav-text small mt-1">
                  {tabName.charAt(0).toUpperCase() + tabName.slice(1)}
                </span>
                {activeTab === tabName && (
                  <div
                    className="nav-indicator bg-white"
                    style={{ 
                      height: "3px", 
                      width: "100%", 
                      marginTop: "4px", 
                      borderRadius: "2px",
                      animation: "slideIn 0.3s ease-out"
                    }}
                  />
                )}
              </div>
            )
          })}
        </div>
      </div>

      {/* Add a style tag for animations */}
      <style jsx>{`
     

        @keyframes submenuEnter {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .submenu-item-mobile {
          animation: submenuEnter 0.4s ease forwards;
          opacity: 0;
        }

        .animate-submenu-mobile {
          animation: submenuEnter 0.4s ease forwards;
        }
      `}</style>
    </>
  )
}

export default BottomNavigation