import React, { useEffect, useState } from "react";
import "./Philosophy.css";
import "@fontsource/source-sans-pro";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import MainBanner from "./components/MainBanner/MainBanner";
import img from "./assets/images/img.webp";
import img1 from "./assets/images/img1.webp";
import Banner from "./components/Banner/Banner";

const Philosophy = () => {
  useEffect(() => {
    // Scroll to top on component mount
    window.scrollTo(0, 0);
  }, []);

  const [activeLine, setActiveLine] = useState(0);
  const lines = [
    "Harer Nāma Harer Nāma",
    "Harer Nāmaiva Kevalam",
    "Kalau Nāsty Eva Nāsty Eva",
    "Nāsty Eva Gatir Anyathā",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveLine((prev) => (prev + 1) % lines.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Header />
      <div className="philosophy-page md:mt-8">
        <MainBanner />
        <div className="d-flex justify-content-center philosophy-img">
          <div className="text-center">
            <div className="d-flex justify-content-center">
              <img
                loading="lazy"
                src={img}
                alt="philosophy-1"
                className="philosophy"
                style={{
                  padding: "5rem",
                }}
              />
            </div>
            <div className="headline font-serif">
              {lines.map((line, index) => (
                <p
                  key={index}
                  className={`text-2xl transition-all duration-700 ease-in-out my-4
            ${
              activeLine === index
                ? "text-[#c61d21] text-4xl"
                : "text-black scale-100"
            }
          `}
                  style={{
                    transformOrigin: "left center",
                  }}
                >
                  {line}
                </p>
              ))}
            </div>
            <div className="d-flex justify-content-center">
              <img
                loading="lazy"
                src={img1}
                alt="philosophy-2"
                className="philosophy"
                style={{
                  padding: "5rem",
                }}
              />
            </div>
          </div>
        </div>
        <Banner />
      </div>
      <Footer />
    </>
  );
};

export default Philosophy;
