import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./index.css";
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import Founder from "./Pages/Founder/Founder";
import Philosophy from "./Pages/Philosophy/Philosophy";
import Mission from "./Pages/Mission/Mission";
import MoreAbout from "./Pages/MoreAbout/MoreAbout";
import NityaSeva from "./Pages/NityaSeva/NityaSeva";
import Arti from "./Pages/Temple/Arti/Arti";
import SaturdaySatsang from "./Pages/Temple/SaturdaySatsang/SaturdaySatsang";
import SundayFeast from "./Pages/Temple/SundayFeast/SundayFeast";
import SriLanka from "./Pages/SriLanka/SriLanka";
import LifeMembership from "./Pages/LifeTimeMembership/lifeMembershipPage";
import GauSeva from "./Pages/GauSeva/GauSeva";
import FoodForLife from "./Pages/FoodForLife/FoodForLife";
import BhaktiVriksha from "./Pages/OurProgram/BhaktiVriskha/BhaktiVriksha";
import YouthSeminar from "./Pages/OurProgram/YouthSeminar/YouthSeminar";
import GitaForYou from "./Pages/OurProgram/GitaForYou/GitaForYou";
import GopalaFunSchool from "./Pages/OurProgram/GopalaForSchool/GopalaFunSchool";
import Counselling from "./Pages/OurProgram/Counselling/Counselling";
// import CorporateProgrammes from "./Pages/OurProgram/CorporateProgrammes/Program";
import SocialWork from "./Pages/OurProgram/Social Work/SocialWork";
import PoliciesUsage from "./Components/Footer/PoliciesUsage";
import PolicyDetail from "./Components/Footer/PolicyDetail";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import KirtanBookingPage from "./Pages/samskara/bookAKirtan/KirtanBookingPage";
import BookYagna from "./Pages/samskara/BookYagna/BookYagna";
import Fastival from "./Components/Fastival/Fastival";
import FormPage from "./Components/Fastival/FormPage";
import Courses from "./Components/Courses/Courses";
import HowToReach from "./Pages/How to Reach/HowToReach";
import QuickDonate from "./Components/Quick Donate/QuickDonate";
import VaikuntaEkadashi from "./Pages/Vaikunta Ekadashi/VaikuntaEkadashi";
// import DonationForm from "./Pages/Vaikunta Ekadashi/DonateForm";
import WhatsAppScroll from "./Components/whatsAppScroll/WhatsappScroll";
import MahaKumbhMela from "./Pages/MahaKumbhMela/MahaKumbhMela";
import DonationTemp from "./Pages/Donation-temp/DonationTemp";
import Gita4You from "./Pages/Gita4You/Gita4You";
import AnvIskcon from "./Pages/23ANV/ANVIscon";
import BhaktiShashtri from "./Pages/BhaktiShashtri/BhaktiShashtri";
import Gita4YouRegistration from "./Pages/Gita4You/Gita4YouRegistration";
import GauraPurnima from "./Pages/GuruPurnima/GauraPurnima";
import JointheFamily from "./Pages/JoinTheFamily/JointheFamily"
import CorporateProgram2 from "./Pages/CorporateProgram2/CorporateProgram2";
import RamNavami from "./Pages/RamNavami/Ramnavami";
import BottomNavigation from "./Components/BottomNavigation/BottomNavigation"
// import VisiterCounter from './Components/visiterCounter/visiterCounter'


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/founder" element={<Founder />} />
          <Route path="/philosophy" element={<Philosophy />} />
          <Route path="/mission" element={<Mission />} />
          <Route path="/more-about-iskcon" element={<MoreAbout />} />
          <Route path="/nitya-seva" element={<NityaSeva />} />
          <Route path="/GauSeva" element={<GauSeva />} />
          <Route path="/MahaKumbhMela" element={<MahaKumbhMela />} />
          <Route path="/Arti" element={<Arti />} />
          <Route path="/SaturdaySatsang" element={<SaturdaySatsang />} />
          <Route path="/SundayFeast" element={<SundayFeast />} />
          <Route path="/srilanka-ramayana-yatra" element={<SriLanka />} />
          <Route path="/LifeMembership" element={<LifeMembership />} />
          <Route path="/food-for-life" element={<FoodForLife />} />
          <Route path="/BhaktiSastri" element={<BhaktiShashtri />} />
          <Route path="/BhaktiVriksha" element={<BhaktiVriksha />} />
          <Route path="/YouthSeminar" element={<YouthSeminar />} />
          <Route path="/GitaForYou" element={<GitaForYou />} />
          <Route path="/GopalaFunSchool" element={<GopalaFunSchool />} />
          <Route path="/Counselling" element={<Counselling />} />
          <Route path="/SocialWork" element={<SocialWork />} />
          
          {/* Old corporate Porgram Route */}

          {/* <Route
            path="/CorporateProgrammes"
            element={<CorporateProgrammes />}
          /> */}
            {/* temp page rahul */}
            <Route
            path="/CorporateProgrammes"
            element={<CorporateProgram2 />}
          />
          <Route path="/policies-usage" element={<PoliciesUsage />} />
          <Route path="/policies-usage/:slug" element={<PolicyDetail />} />
          <Route path="/kirtan-booking" element={<KirtanBookingPage />} />
          <Route path="/yagna-booking" element={<BookYagna />} />
          <Route path="/festival" element={<Fastival />} />
          <Route path="/donation-form/:slug" element={<FormPage />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/HowToReach" element={<HowToReach />} />
          <Route path="/QuickDonate" element={<QuickDonate />} />

          {/*Temp Route */}
          <Route path="/donation-temp" element={<DonationTemp />} />
          <Route path="/gita4You" element={<Gita4YouRegistration />} />
          <Route path="/anniversary" element={<AnvIskcon />} />
          <Route path="/GauraPurnima" element={<GauraPurnima />} />

          <Route path="/VaikuntaEkadashi" element={<VaikuntaEkadashi />} />
          <Route path="/JointheFamily" element={<JointheFamily />} />
          <Route path="/RamNavami" element={<RamNavami />} />
          {/* <Route path="/DonationForm/:id" element={<DonationForm />} /> */}
        </Routes>
        {/* <VisiterCounter/> */}
        <WhatsAppScroll />
        <BottomNavigation/>
      </div>
    </Router>
  </React.StrictMode>
);
