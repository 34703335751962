import React from "react";
import Slider from "react-slick";
import "./YtSlider.css";
import ArrowRight from "./ArrowRight.svg";
import ArrowLeft from "./ArrowLeft.svg";

const ytIdeas = [
  {
    id: "ayBApn0SnS0",
    title: "BHAKTI SHASTRI Upadeshamrtam Sloka 1 PART 1",
    thumbnail: "https://img.youtube.com/vi/ayBApn0SnS0/maxresdefault.jpg",
  },
  {
    id: "zLro71sgg6s",
    title: "BHAKTI SHASTRI Upadeshamrtam Sloka 1 PART 2",
    thumbnail: "https://img.youtube.com/vi/zLro71sgg6s/maxresdefault.jpg",
  },
  {
    id: "R2kfRjYaHfw",
    title: "BHAKTI SHASTRI Upadeshamrtam Sloka 1 PART 3",
    thumbnail: "https://img.youtube.com/vi/R2kfRjYaHfw/maxresdefault.jpg",
  },
  {
    id: "SeDxW8CLlC4",
    title: "BHAKTI SHASTRI Upadeshamrtam Sloka 2",
    thumbnail: "https://img.youtube.com/vi/SeDxW8CLlC4/maxresdefault.jpg",
  },
  {
    id: "0RHUMLEgSb0",
    title: "BHAKTI SHASTRI Upadeshamrtam Sloka 3",
    thumbnail: "https://img.youtube.com/vi/0RHUMLEgSb0/maxresdefault.jpg",
  },
  {
    id: "8v2HBz-amRA",
    title: "BHAKTI SHASTRI Upadeshamrtam Sloka 4",
    thumbnail: "https://img.youtube.com/vi/8v2HBz-amRA/maxresdefault.jpg",
  },
  {
    id: "OIa47XmcmsU",
    title:
      '"Monks on Mission" -- A heart-touching documentary on Vijayawada flood relief activities by ISKCON',
    thumbnail: "https://img.youtube.com/vi/OIa47XmcmsU/maxresdefault.jpg",
  },
];

const NextArrow = (props) => {
  const { style, onClick } = props;
  return (
    <button
      className="btn ytBtn"
      style={{
        ...style,
        position: "absolute",
        top: "35%",
        right: "-5%",
      }}
      onClick={onClick}
    >
      <img
        loading="lazy"
        src={ArrowRight}
        alt="Next"
        style={{ width: "25px" }}
      />
    </button>
  );
};

const PrevArrow = (props) => {
  const { style, onClick } = props;
  return (
    <button
      className="btn ytBtn"
      style={{
        ...style,
        position: "absolute",
        top: "35%",
        left: "-5%",
        zIndex: "2",
      }}
      onClick={onClick}
    >
      <img
        loading="lazy"
        src={ArrowLeft}
        alt="Previous"
        style={{ width: "25px" }}
      />
    </button>
  );
};

const YouTubeSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="youtube-slider-container pb-4">
      <Slider {...settings}>
        {ytIdeas.map((video, index) => (
          <div key={index} className="video-slide">
            <div className="ytCard">
              <a
                href={`https://www.youtube.com/watch?v=${video.id}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <img
                  loading="lazy"
                  src={video.thumbnail}
                  alt={video.title}
                  className="video-thumbnail"
                />
                <div className="bottomCard">
                  <h3 className="video-title">{video.title}</h3>
                </div>
              </a>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default YouTubeSlider;
