import React from "react";
import "./ImageSlider.css";
import image1 from "./assets/images/image1.webp";
import { NavLink } from "react-router-dom";
import Ramnavami from "./assets/images/ramnavami.webp";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const ImageSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);

  const slides = [
    {
      img: Ramnavami,
      link: "/RamNavami",
    },
    {
      img: image1,
      link: "https://www.iskconvjagita4you.org",
    },
  ];

  useEffect(() => {
    let interval;
    if (isAutoPlaying) {
      interval = setInterval(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
      }, 6000);
    }
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative w-full mx-auto overflow-hidden max-h-full">
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`transition-opacity duration-100 ${
            index === currentSlide
              ? "opacity-100"
              : "opacity-0 absolute inset-0 -z-10"
          }`}
        >
          <Link to={slide.link} className="block w-full">
            <div className="aspect-[16/9] sm:aspect-[16/10] md:aspect-[16/9] lg:aspect-[16/8]">
              <img
                loading="lazy"
                src={slide.img}
                alt={`Slide ${index + 1}`}
                className="w-full h-full object-fill"
              />
            </div>
          </Link>
        </div>
      ))}

      {/* Slide Indicators */}
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2 z-20">
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => {
              setIsAutoPlaying(false);
              setCurrentSlide(index);
            }}
            className={`w-2 h-2 rounded-full transition-all duration-300 
           ${index === currentSlide ? "bg-white w-4" : "bg-white/50"}`}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;