import React, { useEffect } from "react";
import Footer from "../../../Components/Footer/Footer";
import Header from "../../../Components/Header/Header";
import { IoMdCall } from "react-icons/io";
// import grpImg from "./assets/grp_img.webp";
import background from "./assets/background.webp";
import banner from "./assets/socialWorkdummy.webp";
import headingImg from "./assets/firstThreeCombinedDesktopBg.webp";
import top from "./assets/floral-lotus-mandala-clipart-black-vector-art-600x600-removebg-preview 1.webp";
import bottom from "./assets/floral-lotus-mandala-clipart-black-vector-art-600x600-removebg-preview 2.webp";
import diya from "./assets/Vector (10).webp";
import { Helmet } from "react-helmet";

function SocialWork() {
  useEffect(() => {
    // Scroll to top on component mount
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        {/* Basic SEO */}
        <title>
          Volunteer at ISKCON Vijayawada | Seva & Service Opportunities
        </title>
        <meta
          name="description"
          content="Contribute to ISKCON Vijayawada’s mission by volunteering. Serve the temple, distribute food, and participate in spiritual programs."
        />
        <meta
          name="keywords"
          content="volunteer opportunities, ISKCON Vijayawada, temple service, food distribution, spiritual programs, seva work"
        />
        <meta name="author" content="ISKCON Vijayawada" />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://www.iskconvijayawada.org/SocialWork"
        />

        {/* Open Graph for Social Media */}
        <meta
          property="og:title"
          content="Volunteer at ISKCON Vijayawada | Seva & Service Opportunities"
        />
        <meta
          property="og:description"
          content="Join ISKCON Vijayawada as a volunteer and contribute to spiritual and social upliftment. Engage in temple service, food distribution, and seva programs."
        />
        <meta property="og:image" content={banner} />
        <meta
          property="og:url"
          content="https://www.iskconvijayawada.org/SocialWork"
        />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Volunteer at ISKCON Vijayawada | Seva & Service Opportunities"
        />
        <meta
          name="twitter:description"
          content="Join ISKCON Vijayawada’s volunteer programs and serve through food distribution, spiritual events, and community service."
        />
        <meta name="twitter:image" content={banner} />
      </Helmet>

      <Header />

      <div className="w-full relative md:mt-12">
        {/* Background Pattern Container */}

        <div
          className="absolute inset-0 bg-cover bg-no-repeat bg-center -z-30"
          style={{ backgroundImage: `url(${headingImg})` }}
        />

        {/* Content Container */}
        <div className="relative z-10 max-w-[100%] md:max-w-[90%] mx-auto px-4 py-2">
          {/* Seminar Badge */}
          <div className="flex  text-center justify-center mb-8">
            <div className="bg-red text-white px-8 py-3 rounded-b-xl text-xl md:text-2xl font-bold border-2 border-white">
              Corporate Social Responsibility (CSR)
            </div>
          </div>

          {/* Main Title */}
          <div className="text-center mb-6">
            <h2 className="text-4xl md:text-6xl font-semibold text-red mb-1">
              Partner with Us <span> for a</span> <span>Better Tomorrow</span>
            </h2>
          </div>

          {/* First Paragraph */}
          <div className="mb-8 leading-relaxed text-gray-800 flex justify-center">
            <img
              loading="lazy"
              src={banner}
              className="h-56 md:h-[90%] rounded-xl shadow-[18px_20px_10px_#e09c89,_0_6px_6px_rgba(0,0,0,0.1),_0_0_0_1px_rgba(255,228,214,0.7)]"
            />
          </div>
          <div className="mb-8 text-lg md:text-3xl leading-relaxed text-gray-800">
            <p className="text-justify">
              At ISKCON Vijayawada, we believe that businesses thrive when they
              contribute to society. Our CSR initiatives are designed to uplift
              communities, empower youth, eradicate hunger, and create a
              drug-free, value-driven society.
            </p>
            <p className="text-justify py-3">
              {" "}
              We invite forward-thinking organizations to collaborate with us in
              making a lasting social impact while fulfilling their CSR mandates
              under the Companies Act, 2013 (Schedule VII).
            </p>
          </div>
        </div>
      </div>
      {/* <div
  className="max-w-[100%] h-[30px] gradientdiv"
 
></div> */}

      {/* <img src={gradient} alt="" /> */}
      <div
        class=" md:px-60 px-20 flex md:flex-row flex-col items-center justify-between md:py-2 py-1.5 md:gap-10 no-underline md:pt-4 py"
        style={{
          background:
            "linear-gradient(to right, #FEEEDC 0%, #C34C41 16%, #BC3521 84%, #FEEEDC 100%)",
          textDecoration: "none",
        }}
      ></div>

      <div className="relative pb-3 md:pb-10">
        <div
          className="absolute inset-0 bg-cover bg-no-repeat bg-center opacity-70 -z-30"
          style={{ backgroundImage: `url(${background})` }}
        />

        <div className="max-w-[100%] md:max-w-[85%] mx-auto p-6">
          <div className="border-2 border-red rounded-lg p-6 bg-[#f4dec6] shadow-md">
            <h2
              className="text-2xl md:text-3xl font-semibold text-red my-4 text-center font-['
                 Source Serif Pro']"
            >
              Anti-Addiction Awareness – Walkathons & Cyclothons
            </h2>

            <div className="space-y-2 list-disc md:pl-6 mb-6 text-xl md:text-2xl">
              <ul className="pl-0 my-10">
                Drug and alcohol abuse are among the biggest challenges facing
                today’s youth. Through walkathons, cyclothons, and large-scale
                awareness drives, we are mobilizing thousands of students and
                professionals to promote a drug-free society.
              </ul>
              <ul className="pl-0 my-10">
                <span className="font-bold ">Impact:</span> Engaged thousands of
                youth, strong media coverage, and behavioral transformation.
              </ul>
              <ul className="pl-0 my-10">
                <span className="font-bold">How You Can Help:</span>{" "}
                <li> • Sponsor city-wide awareness campaigns.</li>
                <li>
                  • Encourage employee participation in wellness initiatives.
                </li>
                <li>
                  • Fund de-addiction programs and rehabilitation efforts.
                </li>
              </ul>
            </div>

            {/* <p className="mb-4 text-red font-bold text-xl md:text-2xl">
              Elevate your corporate culture and invest in the well-being of
              your employees with our transformative corporate programs.
            </p> */}
          </div>
        </div>

        <div className="max-w-[100%] md:max-w-[85%] mx-auto p-6">
          <div className="border-2 border-red rounded-lg p-6 bg-[#f4dec6] shadow-md">
            <h2
              className="text-2xl md:text-3xl font-semibold text-red my-4 text-center font-['
                 Source Serif Pro']"
            >
              Value-Based Education – Parivartan Project
            </h2>

            <div className="space-y-2 list-disc md:pl-6 mb-6 text-xl md:text-2xl">
              <ul className="my-10 pl-0">
                The Parivartan Project is our flagship initiative aimed at
                shaping young minds through character-building workshops, life
                skills training, and de-addiction programs in schools and
                colleges. How You Can Help:
              </ul>
              <ul className="my-10 pl-0 ">
                <span className="font-bold ">Impact:</span> Thousands of
                students empowered with moral values, leadership skills, and
                self-discipline.
              </ul>
              <ul className="my-10 pl-0">
                <span className="font-bold">How You Can Help:</span>{" "}
                <li> • Fund educational resources and outreach programs.</li>
                <li>• Sponsor student scholarships and leadership training.</li>
                <li>
                  • Partner with us for employee-driven mentoring initiatives.
                </li>
              </ul>
            </div>

            {/* <p className="mb-4 text-red font-bold text-xl md:text-2xl">
              Elevate your corporate culture and invest in the well-being of
              your employees with our transformative corporate programs.
            </p> */}
          </div>
        </div>

        <div className="max-w-[100%] md:max-w-[85%] mx-auto p-6">
          <div className="border-2 border-red rounded-lg p-6 bg-[#f4dec6] shadow-md">
            <h2
              className="text-2xl md:text-3xl font-semibold text-red my-4 text-center font-['
                 Source Serif Pro']"
            >
              Food for Life – Annadaan Seva (Hunger Eradication)
            </h2>

            <div className="space-y-2 list-disc md:pl-6 mb-6 text-xl md:text-2xl">
              <ul className="my-10 pl-0">
                Hunger is still a stark reality for millions. ISKCON
                Vijayawada’s Food for Life initiative serves nutritious,
                vegetarian meals to underprivileged communities, daily wage
                workers, and school children{" "}
              </ul>
              <ul className="my-10 pl-0">
                <span className="font-bold ">Impact:</span> Over 50,000 meals
                served annually, transforming lives one meal at a time.
              </ul>
              <ul className="pl-0 my-10">
                <span className="font-bold">How You Can Help:</span>{" "}
                <li>
                  {" "}
                  • Sponsor daily meal distribution or adopt a feeding program.
                </li>
                <li>• Fund food trucks to expand outreach.</li>
                <li>• Volunteer as an organization to serve meals.</li>
              </ul>
            </div>

            {/* <p className="mb-4 text-red font-bold text-xl md:text-2xl">
              Elevate your corporate culture and invest in the well-being of
              your employees with our transformative corporate programs.
            </p> */}
          </div>
        </div>

        <div className="max-w-[100%] md:max-w-[85%] mx-auto p-6">
          <div className="border-2 border-red rounded-lg p-6 bg-[#f4dec6] shadow-md">
            <h2
              className="text-2xl md:text-3xl font-semibold text-red my-4 text-center font-['
                 Source Serif Pro']"
            >
              Health & Wellness – Stress-Free Living & Rehabilitation{" "}
            </h2>

            <div className="space-y-2 list-disc md:pl-6 mb-6 text-xl md:text-2xl">
              <ul className="my-10 pl-0">
                In today’s fast-paced world, mental and physical health issues
                are rising. We conduct stress management workshops, de-addiction
                programs, and health camps for students, professionals, and
                underserved communities..{" "}
              </ul>
              <ul className="my-10 pl-0">
                <span className="font-bold ">Impact:</span> Thousands benefited
                through holistic well-being programs.
              </ul>
              <ul className="pl-0 my-10">
                <span className="font-bold">How You Can Help:</span>{" "}
                <li> • Sponsor health check-up camps and yoga workshops</li>
                <li>
                  • Support de-addiction and stress management initiatives.
                </li>
                <li>
                  • Organize employee wellness programs in collaboration with
                  us..
                </li>
              </ul>
            </div>

            {/* <p className="mb-4 text-red font-bold text-xl md:text-2xl">
              Elevate your corporate culture and invest in the well-being of
              your employees with our transformative corporate programs.
            </p> */}
          </div>
        </div>
        <div className="max-w-[100%] md:max-w-[85%] mx-auto p-6">
          <div className="border-2 border-red rounded-lg p-6 bg-[#f4dec6] shadow-md">
            <h2
              className="text-2xl md:text-3xl font-semibold text-red my-4 text-center font-['
                 Source Serif Pro']"
            >
              Environmental Sustainability – Tree Plantation & Cow
              Protection(Gau Seva){" "}
            </h2>

            <div className="space-y-2 list-disc md:pl-6 mb-6 text-xl md:text-2xl">
              <ul className="my-10 pl-0">
                We believe in sustainable living and are actively involved in
                tree plantation drives, organic farming, and cow protection (Gau
                Seva) to restore ecological balance.{" "}
              </ul>
              <ul className="my-10 pl-0">
                <span className="font-bold ">Impact:</span> Hundreds of trees
                planted, cows sheltered, and sustainable farming promoted.
              </ul>
              <ul className="pl-0 my-10">
                <span className="font-bold">How You Can Help:</span>{" "}
                <li>
                  {" "}
                  • Sponsor tree plantation and eco-friendly initiatives.
                </li>
                <li>• Support organic farming and cow care projects.</li>
                <li>
                  • Partner with us for corporate sustainability programs.
                </li>
              </ul>
            </div>

            {/* <p className="mb-4 text-red font-bold text-xl md:text-2xl">
              Elevate your corporate culture and invest in the well-being of
              your employees with our transformative corporate programs.
            </p> */}
          </div>
        </div>
        <div className="max-w-[100%] md:max-w-[85%] mx-auto p-6">
          <div className="border-2 border-red rounded-lg p-6 bg-[#f4dec6] shadow-md">
            <h2
              className="text-2xl md:text-3xl font-semibold text-red my-4 text-center font-['
                 Source Serif Pro']"
            >
              Disaster Relief & Humanitarian Aid{" "}
            </h2>

            <div className="space-y-2 list-disc md:pl-6 mb-6 text-xl md:text-2xl">
              <ul className="my-10 pl-0">
                ISKCON Vijayawada has been on the ground during natural
                calamities, providing food, clothing, and medical aid to
                disaster victims.{" "}
              </ul>
              <ul className="my-10 pl-0">
                <span className="font-bold ">Impact:</span> ISKCON Vijayawada
                has been on the ground during natural calamities, providing
                food, clothing, and medical aid to disaster victims.
              </ul>
              <ul className="pl-0 my-10">
                <span className="font-bold">How You Can Help:</span>{" "}
                <li> • Fund emergency relief kits and logistics.</li>
                <li>• Support long-term rehabilitation programs.</li>
                <li>• Volunteer with us in relief efforts.</li>
              </ul>
            </div>

            {/* <p className="mb-4 text-red font-bold text-xl md:text-2xl">
              Elevate your corporate culture and invest in the well-being of
              your employees with our transformative corporate programs.
            </p> */}
          </div>
        </div>

        <div className="relative bg-[#c61d21] text-white min-h-[30vh] overflow-hidden w-full box-border px-2 md:px-4 py-4">
          <div className="relative z-10 max-w-[100%] md:max-w-[85%] mx-auto">
            <div className="w-full">
              <h1 className="text-center font-['Source_Serif_4'] font-semibold text-[#f3b01b] mb-3 text-[32px] leading-[1.2] sm:text-[54px] lg:text-[70px]">
                Why Choose ISKCON Vijayawada for CSR?
              </h1>
              <ul className="list-none mx-auto my-2 px-4 md:px-5 leading-[1.5] text-[#f3b01b] text-[16px] text-justify sm:text-[26px] sm:leading-[1.8] sm:px-10 lg:text-[28px] lg:leading-[2] lg:my-[30px] lg:max-w-[1600px]">
                {[
                  "A Trusted Name in Social Service: Decades of experience in humanitarian work.",
                  "CSR Compliant & Transparent: 100% aligned with Companies Act, 2013 (Schedule VII).",
                  "Measurable Impact & Reporting: Detailed reports and audits for corporate partners.",
                  "Brand Recognition & Media Exposure: Corporate partners receive certifications,media coverage,and branding opportunities.",
                ].map((text, index) => (
                  <li
                    key={index}
                    className="my-[15px] sm:my-[20px] flex items-center gap-4"
                  >
                    <img
                      className="h-[1em] sm:h-[1.2em] lg:h-[1em] w-auto object-contain"
                      src={diya}
                      alt=""
                    />
                    <span className="text-[#ffdebd]">{text}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="absolute top-0 left-0 right-0 bottom-0 pointer-events-none">
            <img
              loading="lazy"
              className="absolute top-[-25px] left-[-25px] w-[140px] h-auto opacity-100 sm:top-[-50px] sm:left-[-50px] sm:w-[280px] lg:top-[-75px] lg:left-[-75px] lg:w-[420px]"
              src={top}
              alt="Top Left Design"
            />
            <img
              loading="lazy"
              className="absolute bottom-[-28px] right-[-20px] w-[233px] h-auto opacity-100 sm:bottom-[-56px] sm:right-[-39px] sm:w-[466px] lg:bottom-[-84px] lg:right-[-58px] lg:w-[699px]"
              src={bottom}
              alt="Bottom Right Design"
            />
          </div>
        </div>

        <div className="max-w-[100%] md:max-w-[85%] mx-auto p-6">
          <div className="text-center mb-6">
            <h2 className="text-2xl md:text-5xl font-semibold text-red  ">
              Join Us <span> in Making</span> <span>a Difference!</span>
            </h2>
            <p className="md:my-10  text-1xl md:text-3xl">
              Join Us in Making a Difference!
            </p>
            <div className="md:my-10 my-2">
              <p className="text-lg md:text-3xl">
                📞 <span className="font-semibold">Call:</span>
                <a href="tel:8006880038" className="text-black no-underline">
                  8006880038
                </a>
              </p>
              <p className="text-lg md:text-3xl">
                {" "}
                📲 <span className="font-semibold">WhatsApp:</span>
                <a
                  href="https://wa.me/9955685568"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-black no-underline"
                >
                  9955685568
                </a>
              </p>
              <p className="text-lg md:text-3xl">
                {" "}
                📧 <span className="font-semibold">Email:</span>
                <a
                  href="mailto:csr@iskconvijayawada.org"
                  className="text-black no-underline"
                >
                  csr@iskconvijayawada.org
                </a>
              </p>
              <p className="text-lg md:text-3xl  font-semibold">
                {" "}
                Your CSR partnership with ISKCON Vijayawada is more than a
                donation—it’s an investment in a better future.
              </p>
              <p className="text-lg md:text-3xl">
                {" "}
                🚀<span>Let’s create impact together.</span> Contact us today!
              </p>
            </div>
          </div>
        </div>

        {/* <div className="max-w-[100%] md:max-w-[80%] mx-auto p-6"></div> */}

        {/* <div className="relative bg-[#c61d21] text-white min-h-[30vh] overflow-hidden w-full box-border px-2 md:px-4 py-4">
          <div className="relative z-10 max-w-[100%] md:max-w-[85%] mx-auto">
            <div className="w-full">
              <h1 className="text-center font-['Source_Serif_4'] font-semibold text-[#f3b01b] mb-3 text-[32px] leading-[1.2] sm:text-[54px] lg:text-[70px]">
                Benefits
              </h1>
              <ul className="list-disc mx-auto my-2 px-4 md:px-5 leading-[1.5] text-[#f3b01b] text-[16px] text-justify sm:text-[26px] sm:leading-[1.8] sm:px-10 lg:text-[32px] lg:leading-[2] lg:my-[30px] lg:max-w-[1600px]">
                <li className="my-[15px] sm:my-[20px] ">
                  <strong className="text-[#f3b01b]">
                    Enhanced Employee Engagement:{" "}
                  </strong>
                  <span className="text-[#ffdebd]">
                    Motivated workforce through spiritual enrichment.
                  </span>
                </li>
                <li className="my-[15px] sm:my-[20px] ">
                  <strong className="text-[#f3b01b]">
                    Improved Team Dynamics:{" "}
                  </strong>{" "}
                  <span className="text-[#ffdebd]">
                    Stronger relationships for better collaboration.
                  </span>
                </li>
                <li className="my-[15px] sm:my-[20px]">
                  <strong className="text-[#f3b01b]">
                    Increased Productivity:{" "}
                  </strong>
                  <span className="text-[#ffdebd]">
                    Empowered employees in a positive environment.
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="absolute top-0 left-0 right-0 bottom-0 pointer-events-none">
            <img
              loading="lazy"
              className="absolute top-[-25px] left-[-25px] w-[140px] h-auto opacity-100 sm:top-[-50px] sm:left-[-50px] sm:w-[280px] lg:top-[-75px] lg:left-[-75px] lg:w-[420px]"
              src={top}
              alt="Top Left Design"
            />
            <img
              loading="lazy"
              className="absolute bottom-[-28px] right-[-20px] w-[233px] h-auto opacity-100 sm:bottom-[-56px] sm:right-[-39px] sm:w-[466px] lg:bottom-[-84px] lg:right-[-58px] lg:w-[699px]"
              src={bottom}
              alt="Bottom Right Design"
            />
          </div>
        </div> */}

        {/* <div className="w-[97%] lg:w-[82%] flex flex-col justify-center mt-10 mx-auto rounded-md border-2 border-red md:text-3xl">
          <h2 className="text-center bg-red text-white font-bold text-lg p-4 md:p-8 md:text-4xl lg:mb-5">
            Elevate your corporate culture and invest in the well-being of your
            employees with our transformative corporate programs.{" "}
          </h2>

          <div className="grid grid-cols-2 gap-4 p-1 lg:w-[90%] lg:mb-5 mx-auto">
            <div>
              <label className="block font-medium mb-1 ">Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full lg:w-[90%] bg-transparent p-2 border-2 border-solid border-gray-300 lg:border-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-red"
              />
            </div>
            <div>
              <label className="block font-medium mb-1">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full lg:w-[90%] p-2 bg-transparent  border-2 border-solid border-gray-300 lg:border-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-red"
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4 p-1 lg:w-[90%] lg:mb-5 mx-auto">
            <div>
              <label className="block font-medium mb-1 ml-1 ">Phone</label>
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="w-full lg:w-[90%] p-2 bg-transparent  border-2 border-solid border-gray-300 lg:border-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-red"
              />
            </div>
            <div>
              <label className="block font-medium mb-1">Address</label>
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                className="w-full lg:w-[90%] p-2 bg-transparent  border-2 border-solid border-gray-300 lg:border-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-red"
              />
            </div>
          </div>

          <div className="p-1 lg:w-[90%] mx-auto">
            <label className="block font-medium mb-1 ml-1">Special Note</label>
            <textarea
              name="specialNote"
              value={formData.specialNote}
              onChange={handleChange}
              className="w-full lg:mb-5 p-2 bg-transparent border-2 border-solid border-gray-300 lg:border-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-red"
              rows="3"
            ></textarea>
          </div>

          <button
            onClick={handleSubmit}
            className="w-full lg:w-[90%] mx-auto lg:mb-5 bg-red lg:text-4xl text-white font-bold py-2 px-2 lg:rounded-md"
          >
            Submit
          </button>
        </div> */}
        {/* <div className="max-w-[100%] md:max-w-[85%] mx-auto p-6">
          <div className="border-2 border-[#C61D21] rounded-xl overflow-hidden">
           

            <div className="bg-[#C61D21] text-white text-center py-4 text-xl md:text-2xl font-semibold">
              For More Information and Booking:
            </div>

          

            <div className="bg-[#feead2] border-2 border-red p-6 md:p-12 rounded-b-lg">
              <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0 md:space-x-4 font-bold">
                <div className="flex items-center space-x-2">
                  <Phone className="text-red w-8 h-8" />
                  <span className="text-lg">8006880038</span>
                </div>

                <div className="flex items-center space-x-2">
                  <Mail className="text-red w-8 h-8" />
                  <span className="text-lg">seva@iskconvijayawada.org</span>
                </div>

                <div className="flex items-center space-x-2">
                  <MapPin className="text-red w-8 h-8" />
                  <span className="text-lg">Iskcon Vijayawada</span>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <Footer />
    </div>
  );
}

export default SocialWork;
