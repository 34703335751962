import React, { useState, useEffect } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Banner from "./assets/banner.webp";
import background from "./assets/background.webp";
import { Container, Form, Button, Row, Col, Card, Alert } from "react-bootstrap";
import "./JointheFamily.css";

const JointheFamily = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [relatives, setRelatives] = useState([]);
  const [formData, setFormData] = useState({
    yourName: "",
    yourDOB: "",
    yourContact: "",
    spouseName: "",
    spouseDOB: "",
    spouseContact: "",
    marriageAnniversary: "",
  });
  const [showAlert, setShowAlert] = useState(false);

  const addRelative = () => {
    setRelatives([...relatives, { id: Date.now(), name: "", relation: "", occasion: "", anniversary: "", address: "", pin: "", phone: "" }]);
  };

  const removeRelative = (id) => {
    setRelatives(relatives.filter((relative) => relative.id !== id));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRelativeInputChange = (id, field, value) => {
    setRelatives(relatives.map(relative =>
      relative.id === id ? { ...relative, [field]: value } : relative
    ));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if all required fields are filled
    const isFormValid = Object.values(formData).every((value) => value.trim() !== "");

    if (!isFormValid) {
      setShowAlert(true);
      return;
    }

    // Prepare the data to be sent
    const data = {
      ...formData,
      relatives: relatives.map(relative => ({
        name: relative.name || "",
        relation: relative.relation || "",
        occasion: relative.occasion || "",
        anniversary: relative.anniversary || "",
        address: relative.address || "",
        pin: relative.pin || "",
        phone: relative.phone || ""
      }))
    };

    try {
      const response = await fetch('https://script.google.com/macros/s/AKfycbxJ_3GXpbSq44DUe2t9r67In9Qm0CMIGLqDrxCOLhWAssOcgtn5yexannccTylBhqso/exec', {
        method: 'POST',
        body: JSON.stringify(data),
        mode: "no-cors",
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        alert("Form submitted successfully!");
        // Reset form and relatives
        setFormData({
          yourName: "",
          yourDOB: "",
          yourContact: "",
          spouseName: "",
          spouseDOB: "",
          spouseContact: "",
          marriageAnniversary: "",
        });
        setRelatives([]);
      } else {
        alert("Failed to submit form. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <>
      <Header />
      <div className="banner-container">
        <h1 className="banner-heading">JOIN THE FAMILY OF LORD KRISHNA</h1>
        <img
          loading="lazy"
          src={Banner}
          alt="Banner for Join the Family"
          className="Join-family-banner"
        />
      </div>
      <div className=" inset-0 bg-cover bg-no-repeat bg-center -z-30" style={{ backgroundImage: `url(${background})` }}>
        <h4 className="text-center fw-bold text-danger py-7">JOIN THE FAMILY OF LORD KRISHNA</h4>
        <Container className="d-flex justify-content-center pb-5">
          <Card className="p-4" style={{ width: "50rem", background: "transparent", border: "2px solid red" }}>
            <Form onSubmit={handleSubmit}>
              {showAlert && (
                <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
                  Please enter all the details.
                </Alert>
              )}
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label className="form-label-red">Your Name</Form.Label>
                    <Form.Control
                      className="bg-transparent text-dark"
                      style={{ border: "2px solid red" }}
                      type="text"
                      name="yourName"
                      value={formData.yourName}
                      onChange={handleInputChange}
                      placeholder="Your Name"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label className="form-label-red">Your Date of Birth</Form.Label>
                    <Form.Control
                      className="bg-transparent text-dark"
                      style={{ border: "2px solid red" }}
                      type="date"
                      name="yourDOB"
                      value={formData.yourDOB}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={6}>
                  <Form.Group>
                    <Form.Label className="form-label-red">Your Contact Number</Form.Label>
                    <Form.Control
                      className="bg-transparent text-dark"
                      style={{ border: "2px solid red" }}
                      type="text"
                      name="yourContact"
                      value={formData.yourContact}
                      onChange={handleInputChange}
                      placeholder="Your Contact Number"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label className="form-label-red">Spouse Name</Form.Label>
                    <Form.Control
                      className="bg-transparent text-dark"
                      style={{ border: "2px solid red" }}
                      type="text"
                      name="spouseName"
                      value={formData.spouseName}
                      onChange={handleInputChange}
                      placeholder="Spouse Name"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={6}>
                  <Form.Group>
                    <Form.Label className="form-label-red">Spouse Date of Birth</Form.Label>
                    <Form.Control
                      className="bg-transparent text-dark"
                      style={{ border: "2px solid red" }}
                      type="date"
                      name="spouseDOB"
                      value={formData.spouseDOB}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label className="form-label-red">Spouse Contact Number</Form.Label>
                    <Form.Control
                      className="bg-transparent text-dark"
                      style={{ border: "2px solid red" }}
                      type="text"
                      name="spouseContact"
                      value={formData.spouseContact}
                      onChange={handleInputChange}
                      placeholder="Spouse Contact Number"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={6}>
                  <Form.Group>
                    <Form.Label className="form-label-red">Marriage Anniversary</Form.Label>
                    <Form.Control
                      className="bg-transparent text-dark"
                      style={{ border: "2px solid red" }}
                      type="date"
                      name="marriageAnniversary"
                      value={formData.marriageAnniversary}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Label className="form-label-red mt-3">Would you like to add relatives?</Form.Label>
              <Button
                className="mt-2 addRelativebtn"
                variant="danger"
                size="xs"
                style={{ padding: "2px 6px", fontSize: "10px", minWidth: "auto" }}
                onClick={addRelative}
              >
                Add Relatives
              </Button>

              {relatives.map((relative) => (
                <Card className="mt-3 p-3" style={{ background: "transparent", border: "none" }} key={relative.id}>
                  <Row>
                    <Col md={5}>
                      <Form.Group>
                        <Form.Label className="form-label-red">Name of the Relative</Form.Label>
                        <Form.Control
                          className="bg-transparent text-dark"
                          style={{ border: "2px solid red" }}
                          type="text"
                          placeholder="Relative Name"
                          value={relative.name}
                          onChange={(e) => handleRelativeInputChange(relative.id, 'name', e.target.value)}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col md={5}>
                      <Form.Group>
                        <Form.Label className="form-label-red">Relation</Form.Label>
                        <Form.Control
                          className="bg-transparent text-dark"
                          style={{ border: "2px solid red" }}
                          type="text"
                          placeholder="Relation"
                          value={relative.relation}
                          onChange={(e) => handleRelativeInputChange(relative.id, 'relation', e.target.value)}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col md={2} className="d-flex align-items-end">
                      <Button
                        className="removeRelativebtn"
                        variant="danger"
                        size="xs"
                        style={{ padding: "2px 8px", fontSize: "12px" }}
                        onClick={() => removeRelative(relative.id)}
                      >
                        X
                      </Button>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label className="form-label-red">Birth/Marriage/Death Anniversary</Form.Label>
                        <Form.Control
                          className="bg-transparent text-dark"
                          style={{ border: "2px solid red" }}
                          type="date"
                          value={relative.anniversary}
                          onChange={(e) => handleRelativeInputChange(relative.id, 'anniversary', e.target.value)}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label className="form-label-red">Specify Occasion</Form.Label>
                        <div className="d-flex gap-3 align-items-center">
                          <Form.Check
                            type="radio"
                            label="Birthday"
                            name={`occasion-${relative.id}`}
                            checked={relative.occasion === "Birthday"}
                            onChange={() => handleRelativeInputChange(relative.id, 'occasion', "Birthday")}
                            required
                          />
                          <Form.Check
                            type="radio"
                            label="Marriage"
                            name={`occasion-${relative.id}`}
                            checked={relative.occasion === "Marriage"}
                            onChange={() => handleRelativeInputChange(relative.id, 'occasion', "Marriage")}
                            required
                          />
                          <Form.Check
                            type="radio"
                            label="Death"
                            name={`occasion-${relative.id}`}
                            checked={relative.occasion === "Death"}
                            onChange={() => handleRelativeInputChange(relative.id, 'occasion', "Death")}
                            required
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label className="form-label-red">Address</Form.Label>
                        <Form.Control
                          className="bg-transparent text-dark"
                          style={{ border: "2px solid red" }}
                          type="text"
                          placeholder="Address"
                          value={relative.address}
                          onChange={(e) => handleRelativeInputChange(relative.id, 'address', e.target.value)}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label className="form-label-red">PIN</Form.Label>
                        <Form.Control
                          className="bg-transparent text-dark"
                          style={{ border: "2px solid red" }}
                          type="text"
                          placeholder="PIN"
                          value={relative.pin}
                          onChange={(e) => handleRelativeInputChange(relative.id, 'pin', e.target.value)}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label className="form-label-red">Relative Phone Number</Form.Label>
                        <Form.Control
                          className="bg-transparent text-dark"
                          style={{ border: "2px solid red" }}
                          type="text"
                          placeholder="Relative Phone Number"
                          value={relative.phone}
                          onChange={(e) => handleRelativeInputChange(relative.id, 'phone', e.target.value)}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card>
              ))}
              <div className="text-center mt-3">
                <Button
                  className="submitbtn"
                  variant="danger"
                  size="xs"
                  style={{ padding: "4px 12px", fontSize: "12px" }}
                  type="submit"
                >
                  Submit
                </Button>
              </div>
            </Form>
          </Card>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default JointheFamily;