import React, { useState, useEffect } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import heroImg from "./assets/hero.webp";
import bgImg from "./assets/background.webp";
import barcode1 from "./assets/barcode1.webp";
import barcode2 from "./assets/barcode2.webp";
import Annadan from "./assets/Annadan.webp";
import gitaclass from "./assets/class.webp";
import Kirtan from "./assets/Kirtan.webp";
import mahaabhishek from "./assets/Mahaabhishekam.webp";
import axios from "axios";
import "./GuruPurnima.css";

const GauraPurnima = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  const [formData, setFormData] = useState({
    vastraAlankaranaSeva: false,
    mahaSankhabhisekhaSeva: false,
    mahaAnnadaanSeva: false,
    mahaNivedanaSeva: false,
    sevaDate: "",
    honoureeName: "",
    relationship: "",
    donorName: "",
    email: "",
    mobile: "",
    dob: "",
    receive80G: false,
    panNumber: "",
    receiveMahaPrasadam: false,
    selectedVastraAlankaranaAmount: null,
    selectedMahaSankhabhisekhaAmount: null,
    selectedMahaAnnadaanAmount: null,
    selectedMahaNivedanaAmount: null,
    customVastraAlankaranaAmount: "",
    customMahaSankhabhisekhaAmount: "",
    customMahaAnnadaanAmount: "",
    customMahaNivedanaAmount: "",
    mahaPrasadamDetails: {
      doorNumber: "",
      houseName: "",
      streetName: "",
      area: "",
      country: "",
      state: "",
      city: "",
      pincode: "",
    },
  });

  const eventData = [
    {
      id: 1,
      title: "ANNADAN ",
      image: Annadan,
    },
    {
      id: 2,
      title: "BHAGAVAT GITA CLASS ",
      image: gitaclass,
    },
    {
      id: 3,
      title: "KIRTAN",
      image: Kirtan,
    },
    {
      id: 4,
      title: "MAHA ABHISEKHAM",
      image: mahaabhishek,
    },
  ];
  const [copiedText, setCopiedText] = useState(null);

  const copyToClipboard = async (text, id) => {
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(text);
        setCopiedText(id);
        setTimeout(() => setCopiedText(null), 2000);
      } else {
        alert("Copy functionality is not supported in this browser.");
      }
    } catch (error) {
      console.error("Failed to copy text: ", error);
      alert("Failed to copy. Please try again.");
    }
  };

  const [errors, setErrors] = useState({});
  const [show80GDetails, setShow80GDetails] = useState(false);
  const [showMahaPrasadamDetails, setShowMahaPrasadamDetails] = useState(false);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      };

      // Handle custom amounts for each seva
      if (name === "customVastraAlankaranaAmount" && value) {
        updatedData.selectedVastraAlankaranaAmount = parseFloat(value);
      } else if (name === "customMahaSankhabhisekhaAmount" && value) {
        updatedData.selectedMahaSankhabhisekhaAmount = parseFloat(value);
      } else if (name === "customMahaAnnadaanAmount" && value) {
        updatedData.selectedMahaAnnadaanAmount = parseFloat(value);
      } else if (name === "customMahaNivedanaAmount" && value) {
        updatedData.selectedMahaNivedanaAmount = parseFloat(value);
      }

      return updatedData;
    });
  };

  const handle80GCheckboxChange = (e) => {
    setShow80GDetails(e.target.checked);
    handleInputChange(e);
  };

  const handleMahaPrasadamCheckboxChange = (e) => {
    setShowMahaPrasadamDetails(e.target.checked);
    handleInputChange(e);
  };

  const handleAmountSelect = (section, amount) => {
    setFormData((prevData) => {
      switch (section) {
        case "vastraAlankarana":
          return {
            ...prevData,
            selectedVastraAlankaranaAmount:
              prevData.selectedVastraAlankaranaAmount === amount
                ? null
                : amount,
            customVastraAlankaranaAmount: "",
          };
        case "mahaSankhabhisekha":
          return {
            ...prevData,
            selectedMahaSankhabhisekhaAmount:
              prevData.selectedMahaSankhabhisekhaAmount === amount
                ? null
                : amount,
            customMahaSankhabhisekhaAmount: "",
          };
        case "mahaAnnadaan":
          return {
            ...prevData,
            selectedMahaAnnadaanAmount:
              prevData.selectedMahaAnnadaanAmount === amount ? null : amount,
            customMahaAnnadaanAmount: "",
          };
        case "mahaNivedana":
          return {
            ...prevData,
            selectedMahaNivedanaAmount:
              prevData.selectedMahaNivedanaAmount === amount ? null : amount,
            customMahaNivedanaAmount: "",
          };
        default:
          return prevData;
      }
    });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.donorName) newErrors.donorName = "Donor name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.mobile) newErrors.mobile = "Mobile number is required";
    if (!formData.dob) newErrors.dob = "Date of birth is required";
    if (formData.receive80G && !formData.panNumber)
      newErrors.panNumber = "PAN number is required for 80G certificate";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const getTotalAmount = () => {
    const vastraAmount =
      formData.selectedVastraAlankaranaAmount ||
      parseFloat(formData.customVastraAlankaranaAmount) ||
      0;
    const sankhabhisekhaAmount =
      formData.selectedMahaSankhabhisekhaAmount ||
      parseFloat(formData.customMahaSankhabhisekhaAmount) ||
      0;
    const annadaanAmount =
      formData.selectedMahaAnnadaanAmount ||
      parseFloat(formData.customMahaAnnadaanAmount) ||
      0;
    const nivedanaAmount =
      formData.selectedMahaNivedanaAmount ||
      parseFloat(formData.customMahaNivedanaAmount) ||
      0;
    return (
      vastraAmount + sankhabhisekhaAmount + annadaanAmount + nivedanaAmount
    );
  };

  // const storeFormData = async (paymentStatus) => {
  //   const sheetDbURL = "https://sheetdb.io/api/v1/8y5xejo71udvy";
  //   const totalDonationAmount = getTotalAmount();

  //   const dataToStore = {
  //     paymentStatus,
  //     vastraAlankaranaSeva: formData.vastraAlankaranaSeva ? "Yes" : "",
  //     mahaSankhabhisekhaSeva: formData.mahaSankhabhisekhaSeva ? "Yes" : "",
  //     mahaAnnadaanSeva: formData.mahaAnnadaanSeva ? "Yes" : "",
  //     mahaNivedanaSeva: formData.mahaNivedanaSeva ? "Yes" : "",
  //     selectedVastraAlankaranaAmount:
  //       formData.selectedVastraAlankaranaAmount ||
  //       formData.customVastraAlankaranaAmount ||
  //       0,
  //     selectedMahaSankhabhisekhaAmount:
  //       formData.selectedMahaSankhabhisekhaAmount ||
  //       formData.customMahaSankhabhisekhaAmount ||
  //       0,
  //     selectedMahaAnnadaanAmount:
  //       formData.selectedMahaAnnadaanAmount ||
  //       formData.customMahaAnnadaanAmount ||
  //       0,
  //     selectedMahaNivedanaAmount:
  //       formData.selectedMahaNivedanaAmount ||
  //       formData.customMahaNivedanaAmount ||
  //       0,
  //     totalDonationAmount,
  //     sevaDate: formData.sevaDate,
  //     honoureeName: formData.honoureeName,
  //     relationship: formData.relationship,
  //     donorName: formData.donorName,
  //     email: formData.email,
  //     mobile: formData.mobile,
  //     dob: formData.dob,
  //     receive80G: formData.receive80G,
  //     panNumber: formData.panNumber,
  //     receiveMahaPrasadam: formData.receiveMahaPrasadam,
  //     ...(formData.receiveMahaPrasadam && {
  //       mahaPrasadamDetails: formData.mahaPrasadamDetails,
  //     }),
  //   };

  //   try {
  //     const response = await fetch(sheetDbURL, {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({ data: dataToStore }),
  //     });

  //     if (response.ok) {
  //       console.log("Data stored successfully.");
  //     } else {
  //       alert("Error storing donor data.");
  //     }
  //   } catch (error) {
  //     console.error("Error storing data: ", error);
  //     alert("An error occurred while storing details. Please try again.");
  //   }
  // };
  // const storeFormData = async (paymentStatus) => {
  //   const totalDonationAmount = getTotalAmount();

  //   // Create a detailed data object with all form fields
  //   const dataToStore = {
  //     timestamp: new Date().toISOString(),
  //     paymentStatus: paymentStatus,
  //     // Seva Selections and Amounts
  //     vastraAlankaranaSeva: formData.vastraAlankaranaSeva ? "Yes" : "No",
  //     vastraAlankaranaAmount: formData.selectedVastraAlankaranaAmount || formData.customVastraAlankaranaAmount || 0,
  //     mahaSankhabhisekhaSeva: formData.mahaSankhabhisekhaSeva ? "Yes" : "No",
  //     mahaSankhabhisekhaAmount: formData.selectedMahaSankhabhisekhaAmount || formData.customMahaSankhabhisekhaAmount || 0,
  //     mahaAnnadaanSeva: formData.mahaAnnadaanSeva ? "Yes" : "No",
  //     mahaAnnadaanAmount: formData.selectedMahaAnnadaanAmount || formData.customMahaAnnadaanAmount || 0,
  //     mahaNivedanaSeva: formData.mahaNivedanaSeva ? "Yes" : "No",
  //     mahaNivedanaAmount: formData.selectedMahaNivedanaAmount || formData.customMahaNivedanaAmount || 0,
  //     totalDonationAmount: totalDonationAmount,

  //     // Personal Details
  //     sevaDate: formData.sevaDate,
  //     honoureeName: formData.honoureeName || "",
  //     relationship: formData.relationship || "",
  //     donorName: formData.donorName,
  //     email: formData.email,
  //     mobile: formData.mobile,
  //     dob: formData.dob,

  //     // 80G Details
  //     receive80G: formData.receive80G ? "Yes" : "No",
  //     panNumber: formData.receive80G ? formData.panNumber : "",

  //     // Maha Prasadam Details
  //     receiveMahaPrasadam: formData.receiveMahaPrasadam ? "Yes" : "No",
  //     address: formData.receiveMahaPrasadam || formData.receive80G ? {
  //       doorNumber: formData.mahaPrasadamDetails.doorNumber || "",
  //       houseName: formData.mahaPrasadamDetails.houseName || "",
  //       streetName: formData.mahaPrasadamDetails.streetName || "",
  //       area: formData.mahaPrasadamDetails.area || "",
  //       city: formData.mahaPrasadamDetails.city || "",
  //       state: formData.mahaPrasadamDetails.state || "",
  //       country: formData.mahaPrasadamDetails.country || "",
  //       pincode: formData.mahaPrasadamDetails.pincode || ""
  //     } : {}
  //   };

  //   try {
  //     // Convert nested objects to flat structure for Google Sheets
  //     const flattenedData = {
  //       ...dataToStore,
  //       address_doorNumber: dataToStore.address.doorNumber,
  //       address_houseName: dataToStore.address.houseName,
  //       address_streetName: dataToStore.address.streetName,
  //       address_area: dataToStore.address.area,
  //       address_city: dataToStore.address.city,
  //       address_state: dataToStore.address.state,
  //       address_country: dataToStore.address.country,
  //       address_pincode: dataToStore.address.pincode,
  //     };

  //     // Remove the nested address object
  //     delete flattenedData.address;

  //     const response = await fetch(
  //       "https://script.google.com/macros/s/AKfycbz3lAQmBlZV2Vy3OzO5JfdXWa4Nxkk8PJd6twCk2MmhZLLCDn240zBRQbl_bhv2zQH0fg/exec",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(flattenedData)
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! status: ${response.status}`);
  //     }

  //     const result = await response.json();
  //     console.log("Data stored successfully in Google Sheets:", result);
  //     return true;
  //   } catch (error) {
  //     console.error("Error storing data:", error);
  //     alert("An error occurred while storing your donation details. Please try again.");
  //     return false;
  //   }
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    const totalAmount = getTotalAmount();
    let isFormDataStored = false;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/payment/create-order`,
        {
          amount: totalAmount,
          currency: "INR",
        }
      );

      const order = response.data;
      let sevaDescription = "Donation Payment";

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: totalAmount * 100,
        currency: "INR",
        name: "ISKCON Guru Purnima Donation",
        description: sevaDescription,
        order_id: order.id,
        notes: {
          donorName: formData.donorName,
          donorPhoneNumber: formData.mobile,
          donorEmail: formData.email,
        },
        handler: async function (response) {
          try {
            const verificationResponse = await axios.post(
              `${process.env.REACT_APP_API_URL}/api/payment/verify-payment`,
              {
                razorpay_order_id: response.razorpay_order_id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
                donationDetails: { ...formData, amount: totalAmount },
              }
            );

            if (verificationResponse.data.success) {
              alert("Payment Successful!");
              if (!isFormDataStored) {
                await storeFormData("Payment Successful");
                isFormDataStored = true;
              }
            }
          } catch (err) {
            console.error(err);
            alert("Payment verification failed");
            if (!isFormDataStored) {
              await storeFormData("Payment Failed");
              isFormDataStored = true;
            }
          }
        },
        prefill: {
          name: formData.donorName,
          email: formData.email,
          contact: formData.mobile,
        },
        theme: { color: "#C61D21" },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();

      paymentObject.on("payment.failed", async function (response) {
        alert("Payment Failed: " + response.error.description);
        if (!isFormDataStored) {
          await storeFormData("Payment Failed");
          isFormDataStored = true;
        }
      });
    } catch (error) {
      console.error("Error initiating payment:", error);
      alert("Failed to initiate payment. Please try again.");
      if (!isFormDataStored) {
        await storeFormData("Payment Failed");
        isFormDataStored = true;
      }
    }
  };

  const storeFormData = async (paymentStatus) => {
    const totalDonationAmount = getTotalAmount();

    const dataToStore = {
      paymentStatus: paymentStatus,
      vastraAlankaranaSeva: formData.vastraAlankaranaSeva ? "Yes" : "No",
      vastraAlankaranaAmount:
        formData.selectedVastraAlankaranaAmount ||
        formData.customVastraAlankaranaAmount ||
        0,
      mahaSankhabhisekhaSeva: formData.mahaSankhabhisekhaSeva ? "Yes" : "No",
      mahaSankhabhisekhaAmount:
        formData.selectedMahaSankhabhisekhaAmount ||
        formData.customMahaSankhabhisekhaAmount ||
        0,
      mahaAnnadaanSeva: formData.mahaAnnadaanSeva ? "Yes" : "No",
      mahaAnnadaanAmount:
        formData.selectedMahaAnnadaanAmount ||
        formData.customMahaAnnadaanAmount ||
        0,
      mahaNivedanaSeva: formData.mahaNivedanaSeva ? "Yes" : "No",
      mahaNivedanaAmount:
        formData.selectedMahaNivedanaAmount ||
        formData.customMahaNivedanaAmount ||
        0,
      totalDonationAmount: totalDonationAmount,
      sevaDate: formData.sevaDate,
      honoureeName: formData.honoureeName || "",
      relationship: formData.relationship || "",
      donorName: formData.donorName,
      email: formData.email,
      mobile: formData.mobile,
      dob: formData.dob,
      receive80G: formData.receive80G ? "Yes" : "No",
      panNumber: formData.receive80G ? formData.panNumber : "",
      receiveMahaPrasadam: formData.receiveMahaPrasadam ? "Yes" : "No",
      doorNumber: formData.mahaPrasadamDetails.doorNumber || "",
      houseName: formData.mahaPrasadamDetails.houseName || "",
      streetName: formData.mahaPrasadamDetails.streetName || "",
      area: formData.mahaPrasadamDetails.area || "",
      country: formData.mahaPrasadamDetails.country || "",
      state: formData.mahaPrasadamDetails.state || "",
      city: formData.mahaPrasadamDetails.city || "",
      pincode: formData.mahaPrasadamDetails.pincode || "",
    };

    console.log("Data to store:", dataToStore);

    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbyx6CtT_9hugxYxneq_Eh5uMzKjHqMRnkTNodNIBsgeybS5bGDQBmORe-_ft6yTPioz3w/exec", // Replace with your Google Script URL
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          mode: "no-cors",
          body: JSON.stringify(dataToStore),
        }
      );

      const result = await response.json();
      console.log("Response from server:", result);

      if (result.status === "success") {
        console.log("Data stored successfully in Google Sheets");
        return true;
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      console.error("Error storing data:", error);
      alert(
        "An error occurred while storing your donation details. Please try again."
      );
      return false;
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <Header />
      <div
        className="w-full bg-cover bg-center bg-no-repeat space-y-4 lg:space-y-8"
        style={{ backgroundImage: `url(${bgImg})` }}
      >
        <img src={heroImg} alt="heroImg" className="w-full" />
        <div className="font-josefin text-center space-y-3 lg:space-y-5 text-xs lg:text-xl lg:p-4 p-1">
          <p className="lg:p-2 gauraP">
            Sri Gaura Purnima is the most auspicious appearance day of Sri
            Chaitanya Mahaprabhu, the golden avatar of Lord Krishna, who
            descended to establish the yuga-dharma—congregational chanting of
            the holy names of the Lord
          </p>
          <p className="lg:p-2 gauraP">
            Understanding the suffering of souls in this age of Kali, Lord
            Krishna appeared as Sri Chaitanya Mahaprabhu, not with weapons, but
            with the greatest gift—Harinaam Sankirtan. He mercifully distributed
            love of God to everyone, irrespective of caste, creed, or
            qualification. To this day, He continues to bless all those who
            sincerely chant the Hare Krishna maha-mantra:
          </p>
          <h2 className="font-garamond text-sm lg:text-4xl lg:p-4 text-[#681D18] font-semibold">
            <em>
              Hare Krishna Hare Krishna Krishna Krishna Hare Hare <br />
              Hare Rama Hare Rama Rama Rama Hare Hare
            </em>
          </h2>
          <p className="lg:p-2 gauraP">
            Join us on March 14, 2025, at ISKCON Vijayawada to celebrate this
            most divine occasion. The festival will feature a grand abhishekam
            of Sri Sri Nitai Gauranga with panchamrita, panchagavya, and a
            variety of fruit juices, followed by an ecstatic kirtan and arati.
            Devotees will immerse themselves in blissful kirtans and spiritual
            discourses, glorifying the magnanimity of Lord Chaitanya. Take this
            golden opportunity to sponsor a seva and receive the unlimited
            blessings of Sri Chaitanya Mahaprabhu. As a token of gratitude, we
            will send special mahaprasadam to your home.
          </p>
          <h2 className="font-garamond text-sm lg:text-4xl lg:p-4 text-[#681D18] font-semibold">
            <em>
              Come, celebrate Gaura Purnima with us and experience <br />
              transcendental joy!
            </em>
          </h2>
          <h1 className="text-[#681D18] text-lg lg:text-4xl lg:p-4 lg:mt-3 font-bold">
            OFFER YOUR SEVA AND RECEIVE THE BLESSINGS OF LORD CHAITANYA
            MAHAPRABHU
          </h1>
        </div>
        <div>
  <h1 className="text-black text-center text-3xl md:text-4xl font-bold mb-4 p-2">
    Event Highlights
  </h1>
  <div className="max-w-6xl mx-auto w-full space-y-8 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-8 flex flex-col items-center justify-center px-3">
    {eventData.map((event) => (
      <div
        className="w-[85%] lg:w-full bg-[#C61D21] flex flex-col items-center p-2 rounded-xl lg:rounded-2xl border-3 border-solid border-[#FFB700] aspect-square"
        key={event.id}
      >
        <div className="w-full h-full relative">
          <img
            loading="lazy"
            src={event.image}
            alt={event.title}
            className="absolute inset-0 w-full h-full object-cover rounded-xl"
          />
        </div>
        <p className="text-white text-lg lg:text-xl font-semibold py-2 mt-4 px-1 text-center">
          {event.title}
        </p>
      </div>
    ))}
  </div>
</div>

        {/* <!-- Donation Form Section --> */}
        {/* <div className="w-[80%] bg-[#C61D21] mx-auto rounded-lg p-2 text-white">
          <h2>Donation Details</h2>
        </div> */}
        <div className="xl:flex flex-row-reverse justify-content-center pb-4">
          <div className="xl:w-[72%] xl:pb-24 xl:flex xl:justify-center">
            <div className="donationDetail  bg-[#C61D21] py-10 px-2 rounded-3xl xl:h-full xl:p-8 xl:max-w-[1400px] w-full">
              <form onSubmit={handleSubmit} className="text-white">
                <h2 className="text-center text-2xl xl:text-4xl">
                  Donation Details
                </h2>

                <div className="innerForm sevaContainer">
                  {/* Vastra Alankarana Seva */}
                  <label>
                    <input
                      type="checkbox"
                      name="vastraAlankaranaSeva"
                      checked={formData.vastraAlankaranaSeva}
                      onChange={handleInputChange}
                      style={{ accentColor: "blue" }}
                    />
                    Vastra Alankarana Seva
                  </label>
                  {formData.vastraAlankaranaSeva && (
                    <div className="grid grid-cols-2 -mt-4">
                      {[70000, 25000, 10000].map((amount) => (
                        <button
                          className="col-span-1 font-semibold p-3 bg-[#F9E7D0] rounded-xl text-black"
                          type="button"
                          key={amount}
                          onClick={() =>
                            handleAmountSelect("vastraAlankarana", amount)
                          }
                          style={{
                            backgroundColor:
                              formData.selectedVastraAlankaranaAmount === amount
                                ? "#fac785"
                                : "",
                            margin: "5px",
                          }}
                        >
                          ₹ {amount.toLocaleString("en-IN")}
                        </button>
                      ))}
                      <input
                        type="number"
                        name="customVastraAlankaranaAmount"
                        placeholder="₹ Enter Amount"
                        value={formData.customVastraAlankaranaAmount || ""}
                        onChange={handleInputChange}
                        className="col-span-2 p-3 border rounded-xl my-1 mx-1 customAmount text-black"
                      />
                    </div>
                  )}

                  {/* Maha Sankhabhisekha Seva */}
                  <label>
                    <input
                      type="checkbox"
                      name="mahaSankhabhisekhaSeva"
                      checked={formData.mahaSankhabhisekhaSeva}
                      onChange={handleInputChange}
                      style={{ accentColor: "blue" }}
                    />
                    Maha Sankhabhisekha Seva
                  </label>
                  {formData.mahaSankhabhisekhaSeva && (
                    <div className="grid grid-cols-2 -mt-4">
                      {[15000, 10000, 5000].map((amount) => (
                        <button
                          className="col-span-1 font-semibold p-3 bg-[#F9E7D0] rounded-xl text-black"
                          type="button"
                          key={amount}
                          onClick={() =>
                            handleAmountSelect("mahaSankhabhisekha", amount)
                          }
                          style={{
                            backgroundColor:
                              formData.selectedMahaSankhabhisekhaAmount ===
                              amount
                                ? "#fac785"
                                : "",
                            margin: "5px",
                          }}
                        >
                          ₹ {amount.toLocaleString("en-IN")}
                        </button>
                      ))}
                      <input
                        type="number"
                        name="customMahaSankhabhisekhaAmount"
                        placeholder="₹ Enter Amount"
                        value={formData.customMahaSankhabhisekhaAmount || ""}
                        onChange={handleInputChange}
                        className="col-span-2 p-3 border rounded-xl my-1 mx-1 customAmount text-black"
                      />
                    </div>
                  )}

                  {/* Maha Annadaan Seva */}
                  <label>
                    <input
                      type="checkbox"
                      name="mahaAnnadaanSeva"
                      checked={formData.mahaAnnadaanSeva}
                      onChange={handleInputChange}
                      style={{ accentColor: "blue" }}
                    />
                    Maha Annadaan Seva
                  </label>
                  {formData.mahaAnnadaanSeva && (
                    <div className="grid grid-cols-2 -mt-4">
                      {[15000, 10000, 5000].map((amount) => (
                        <button
                          className="col-span-1 font-semibold p-3 bg-[#F9E7D0] rounded-xl text-black"
                          type="button"
                          key={amount}
                          onClick={() =>
                            handleAmountSelect("mahaAnnadaan", amount)
                          }
                          style={{
                            backgroundColor:
                              formData.selectedMahaAnnadaanAmount === amount
                                ? "#fac785"
                                : "",
                            margin: "5px",
                          }}
                        >
                          ₹ {amount.toLocaleString("en-IN")}
                        </button>
                      ))}
                      <input
                        type="number"
                        name="customMahaAnnadaanAmount"
                        placeholder="₹ Enter Amount"
                        value={formData.customMahaAnnadaanAmount || ""}
                        onChange={handleInputChange}
                        className="col-span-2 p-3 border rounded-xl my-1 mx-1 customAmount text-black"
                      />
                    </div>
                  )}

                  {/* Maha Nivedana Seva */}
                  <label>
                    <input
                      type="checkbox"
                      name="mahaNivedanaSeva"
                      checked={formData.mahaNivedanaSeva}
                      onChange={handleInputChange}
                      style={{ accentColor: "blue" }}
                    />
                    Maha Nivedana Seva
                  </label>
                  {formData.mahaNivedanaSeva && (
                    <div className="grid grid-cols-2 -mt-4">
                      {[10000, 5000, 3000].map((amount) => (
                        <button
                          className="col-span-1 font-semibold p-3 bg-[#F9E7D0] rounded-xl text-black"
                          type="button"
                          key={amount}
                          onClick={() =>
                            handleAmountSelect("mahaNivedana", amount)
                          }
                          style={{
                            backgroundColor:
                              formData.selectedMahaNivedanaAmount === amount
                                ? "#fac785"
                                : "",
                            margin: "5px",
                          }}
                        >
                          ₹ {amount.toLocaleString("en-IN")}
                        </button>
                      ))}
                      <input
                        type="number"
                        name="customMahaNivedanaAmount"
                        placeholder="₹ Enter Amount"
                        value={formData.customMahaNivedanaAmount || ""}
                        onChange={handleInputChange}
                        className="col-span-2 p-3 border rounded-xl my-1 mx-1 customAmount text-black"
                      />
                    </div>
                  )}

                  {/* Seva Date */}
                  {/* <div>
                    <input
                      type="text"
                      name="sevaDate"
                      value={formData.sevaDate}
                      onChange={handleInputChange}
                      placeholder="Seva Date"
                      onFocus={(e) => (e.target.type = "date")}
                      onBlur={(e) => (e.target.type = "text")}
                    />
                    {errors.sevaDate && <span>{errors.sevaDate}</span>}
                  </div> */}
                </div>

                <div className="innerForm">
                  <h3 className="text-center">Personal Details</h3>
                  <label className="xl:w-full">
                    <input
                      type="text"
                      name="donorName"
                      value={formData.donorName}
                      onChange={handleInputChange}
                      placeholder="Donor Name"
                      className="mt-2"
                    />
                    {errors.donorName && (
                      <span className="block -mt-2 ml-2">
                        {errors.donorName}
                      </span>
                    )}
                  </label>
                  <label className="xl:w-full">
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      placeholder="Email ID"
                    />
                    {errors.email && (
                      <span className="block -mt-2 ml-2">{errors.email}</span>
                    )}
                  </label>
                  <div className="flex gap-2">
                    <label className="flex-1">
                      <input
                        type="tel"
                        name="mobile"
                        value={formData.mobile}
                        onChange={handleInputChange}
                        placeholder="Mobile Number"
                        className="p-[10px] md:w-full"
                      />
                      {errors.mobile && (
                        <span className="block mt-1 ml-2">{errors.mobile}</span>
                      )}
                    </label>
                    <label className="flex-1">
                      <input
                        type="text"
                        name="dob"
                        value={formData.dob}
                        onChange={handleInputChange}
                        className="text-[#909192] p-2 border rounded-md"
                        placeholder="Date Of Birth"
                        onFocus={(e) => (e.target.type = "date")}
                        onBlur={(e) => (e.target.type = "text")}
                      />
                      {errors.dob && (
                        <span className="block -mt-2 ml-2">{errors.dob}</span>
                      )}
                    </label>
                  </div>
                </div>
                <p className="text-white mt-2 text-[10px]">
                  (Sankalpa and Archana will be performed for you on your
                  birthday)
                </p>

                <p className="text-white -mt-2 text-xs">
                  By continuing, you are agreeing to{" "}
                  <a className="underline text-[#EEF9A3]">
                    Terms of Use and Privacy Policy
                  </a>
                </p>

                {/* Additional checkboxes */}
                <div className="px-3 tandd mt-8">
                  <label>
                    <input
                      type="checkbox"
                      name="receive80G"
                      checked={formData.receive80G}
                      onChange={handle80GCheckboxChange}
                      style={{ accentColor: "blue" }}
                    />
                    I would like to receive 80(G) Certificate
                  </label>
                  {show80GDetails && (
                    <div className="mb-3">
                      <p className="text-white text-[10px] text-left ml-2">
                        Finance Act 2021 mandates uploading donation details for
                        80(G) deductions. Ensure PAN and address details are
                        provided.
                      </p>
                      <input
                        type="text"
                        name="panNumber"
                        value={formData.panNumber}
                        onChange={handleInputChange}
                        placeholder="Pan Number *"
                        className="w-full bg-white text-black px-4 py-2 rounded-lg outline-none focus:ring-2 focus:ring-yellow-400"
                        required
                      />
                      <input
                        type="text"
                        name="doorNumber"
                        placeholder="Door Number"
                        className="text-black"
                        value={formData.mahaPrasadamDetails.doorNumber}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            mahaPrasadamDetails: {
                              ...prev.mahaPrasadamDetails,
                              doorNumber: e.target.value,
                            },
                          }))
                        }
                      />
                      <input
                        type="text"
                        className="text-black"
                        name="houseName"
                        placeholder="House / Apartment / Building Name"
                        value={formData.mahaPrasadamDetails.houseName}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            mahaPrasadamDetails: {
                              ...prev.mahaPrasadamDetails,
                              houseName: e.target.value,
                            },
                          }))
                        }
                      />
                      <input
                        type="text"
                        className="text-black"
                        name="streetName"
                        placeholder="Street Name"
                        value={formData.mahaPrasadamDetails.streetName}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            mahaPrasadamDetails: {
                              ...prev.mahaPrasadamDetails,
                              streetName: e.target.value,
                            },
                          }))
                        }
                      />
                      <input
                        type="text"
                        className="text-black"
                        name="area"
                        placeholder="Location / Area"
                        value={formData.mahaPrasadamDetails.area}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            mahaPrasadamDetails: {
                              ...prev.mahaPrasadamDetails,
                              area: e.target.value,
                            },
                          }))
                        }
                      />
                      <input
                        type="text"
                        className="text-black"
                        name="country"
                        placeholder="Country"
                        value={formData.mahaPrasadamDetails.country}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            mahaPrasadamDetails: {
                              ...prev.mahaPrasadamDetails,
                              country: e.target.value,
                            },
                          }))
                        }
                      />
                      <input
                        type="text"
                        className="text-black"
                        name="state"
                        placeholder="State"
                        value={formData.mahaPrasadamDetails.state}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            mahaPrasadamDetails: {
                              ...prev.mahaPrasadamDetails,
                              state: e.target.value,
                            },
                          }))
                        }
                      />
                      <input
                        type="text"
                        className="text-black"
                        name="city"
                        placeholder="City"
                        value={formData.mahaPrasadamDetails.city}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            mahaPrasadamDetails: {
                              ...prev.mahaPrasadamDetails,
                              city: e.target.value,
                            },
                          }))
                        }
                      />
                      <input
                        type="text"
                        className="text-black"
                        name="pincode"
                        placeholder="Pincode"
                        value={formData.mahaPrasadamDetails.pincode}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            mahaPrasadamDetails: {
                              ...prev.mahaPrasadamDetails,
                              pincode: e.target.value,
                            },
                          }))
                        }
                      />
                    </div>
                  )}

                  <label>
                    <input
                      type="checkbox"
                      name="receiveMahaPrasadam"
                      checked={formData.receiveMahaPrasadam}
                      onChange={handleMahaPrasadamCheckboxChange}
                      style={{ accentColor: "blue" }}
                    />
                    I would like to receive Maha prasadam (Only within India)
                  </label>
                  {showMahaPrasadamDetails && (
                    <div className="mt-3">
                      <input
                        type="text"
                        name="doorNumber"
                        placeholder="Door Number"
                        className="text-black"
                        value={formData.mahaPrasadamDetails.doorNumber}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            mahaPrasadamDetails: {
                              ...prev.mahaPrasadamDetails,
                              doorNumber: e.target.value,
                            },
                          }))
                        }
                      />
                      <input
                        type="text"
                        className="text-black"
                        name="houseName"
                        placeholder="House / Apartment / Building Name"
                        value={formData.mahaPrasadamDetails.houseName}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            mahaPrasadamDetails: {
                              ...prev.mahaPrasadamDetails,
                              houseName: e.target.value,
                            },
                          }))
                        }
                      />
                      <input
                        type="text"
                        className="text-black"
                        name="streetName"
                        placeholder="Street Name"
                        value={formData.mahaPrasadamDetails.streetName}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            mahaPrasadamDetails: {
                              ...prev.mahaPrasadamDetails,
                              streetName: e.target.value,
                            },
                          }))
                        }
                      />
                      <input
                        type="text"
                        className="text-black"
                        name="area"
                        placeholder="Location / Area"
                        value={formData.mahaPrasadamDetails.area}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            mahaPrasadamDetails: {
                              ...prev.mahaPrasadamDetails,
                              area: e.target.value,
                            },
                          }))
                        }
                      />
                      <input
                        type="text"
                        className="text-black"
                        name="country"
                        placeholder="Country"
                        value={formData.mahaPrasadamDetails.country}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            mahaPrasadamDetails: {
                              ...prev.mahaPrasadamDetails,
                              country: e.target.value,
                            },
                          }))
                        }
                      />
                      <input
                        type="text"
                        className="text-black"
                        name="state"
                        placeholder="State"
                        value={formData.mahaPrasadamDetails.state}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            mahaPrasadamDetails: {
                              ...prev.mahaPrasadamDetails,
                              state: e.target.value,
                            },
                          }))
                        }
                      />
                      <input
                        type="text"
                        className="text-black"
                        name="city"
                        placeholder="City"
                        value={formData.mahaPrasadamDetails.city}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            mahaPrasadamDetails: {
                              ...prev.mahaPrasadamDetails,
                              city: e.target.value,
                            },
                          }))
                        }
                      />
                      <input
                        type="text"
                        className="text-black"
                        name="pincode"
                        placeholder="Pincode"
                        value={formData.mahaPrasadamDetails.pincode}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            mahaPrasadamDetails: {
                              ...prev.mahaPrasadamDetails,
                              pincode: e.target.value,
                            },
                          }))
                        }
                      />
                    </div>
                  )}
                </div>

                <div className="flex justify-center mt-8">
                  <button
                    type="submit"
                    className="bg-[#FAE29C] text-black px-12 py-3 rounded-2xl font-bold"
                  >
                    Donate{" "}
                    {getTotalAmount() > 0
                      ? "₹ " + getTotalAmount().toLocaleString("en-IN")
                      : ""}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="w-[90%] md:w-[70%] border-solid border-2 border-[#C61D21] rounded-lg mx-auto">
          <h2 className="md:py-4 py-3 bg-[#C61D21] text-white text-center md:text-3xl text-base">
            Donation Through Bank (NEFT/RTGS){" "}
          </h2>
          <ul className=" flex flex-col justify-center xl:pl-80 md:pl-40 pl-0 md:items-start md:space-y-5 space-y-2 leading-4 my-4 items-center ">
            <li className="w-full md:text-2xl text-base  leading-2 tracking-none font-bold text-black px-2 md:px-0 flex justify-between md:justify-normal">
              <span className="md:text-2xl text-base text-[#C61D21] ">
                Bank Name:<span className="text-black"> HDFC Bank</span>
              </span>{" "}
              <button
                class="bg-gray-200 md:text-xs text-[10px] px-2 md:py-0.5 border-1 border-[#C61D21] ml-2 rounded-full"
                onClick={() => copyToClipboard("HDFC Bank", "bankName")}
              >
                {copiedText === "bankName" ? "Copied!" : "Copy"}
              </button>
            </li>
            <li className="w-full md:text-2xl text-base  leading-2 tracking-none font-bold text-black px-2 md:px-0 flex justify-between md:justify-normal">
              <span className="md:text-2xl text-base text-[#C61D21]">
                Account Name:<span className="text-black"> ISKCON</span>
              </span>{" "}
              <button
                class="bg-gray-200 md:text-xs text-[10px] px-2 md:py-0.5  border-1 border-[#C61D21] ml-2 rounded-full"
                onClick={() => copyToClipboard("ISKCON", "accountName")}
              >
                {copiedText === "accountName" ? "Copied!" : "Copy"}
              </button>
            </li>
            <li className="w-full md:text-2xl text-base  leading-2 tracking-none font-bold text-black px-2 md:px-0 flex justify-between md:justify-normal">
              <span className=" text-[#C61D21]">
                Account Number:{" "}
                <span className="text-black">50100436709020</span>
              </span>{" "}
              <button
                class="bg-gray-200 md:text-xs text-[10px] px-2 md:py-0.5 border-1 border-[#C61D21] ml-2 rounded-full"
                onClick={() =>
                  copyToClipboard("50100436709020", "accountNumber")
                }
              >
                {copiedText === "accountNumber" ? "Copied!" : "Copy"}
              </button>
            </li>
            <li className="w-full md:text-2xl text-base  leading-2 tracking-none font-bold text-black px-2 md:px-0 flex justify-between md:justify-normal ">
              <span className=" text-[#C61D21]">
                IFSC Code: <span className="text-black">HDFC000568</span>
              </span>
              <button
                class="bg-gray-200 md:text-xs text-[10px] px-2 md:py-0.5 border-1 border-[#C61D21] ml-2 rounded-full"
                onClick={() => copyToClipboard("HDFC000568", "ifscCode")}
              >
                {copiedText === "ifscCode" ? "Copied!" : "Copy"}
              </button>
            </li>
            <li className="w-full md:text-2xl text-base leading-2 tracking-none font-bold text-black px-2 md:px-0 flex justify-between md:justify-normal">
              <span className=" text-[#C61D21]">
                Branch: <span className="text-black">ONE TOWN VIJAYAWADA </span>
              </span>{" "}
              <button
                class="bg-gray-200 md:text-xs text-[10px] px-2 md:py-0.5 border-1 border-[#C61D21] ml-2 rounded-full"
                onClick={() => copyToClipboard("ONE TOWN VIJAYAWADA", "branch")}
              >
                {copiedText === "branch" ? "Copied!" : "Copy"}
              </button>
            </li>
          </ul>
        </div>

        <div className="mb-3 w-[90%] md:w-[70%] md:mx-44  md:border-5 border-2 md:rounded-2xl rounded-lg border-[#C61D21] mt-10 overflow-hidden mx-auto">
          <h2 className="md:py-4 py-2 bg-[#C61D21] text-white text-center md:text-3xl text-base">
            Donate Through UPI
          </h2>

          <div className="flex md:flex-row flex-col justify-around items-center pt-3 md:pb-3 pb-4 gap-4">
            <div className="md:block flex gap-2">
              <h2 className="md:text-2xl text-base text-[#C61D21]">UPI ID:</h2>
              <p className="md:text-xl text-base  text-black">
                {" "}
                ISCKONVIJAYAWADA@IDFCBANK
              </p>
            </div>
            <img
              loading="lazy"
              src={barcode1}
              alt=""
              width="200px"
              height="200px"
            />
            <p className="lg:text-3xl text-lg font-semibold text-[#C61D21]">
              OR
            </p>
            <img
              loading="lazy"
              src={barcode2}
              alt=""
              width="200px"
              height="200px"
            />
          </div>
        </div>

        <div className="text-left md:mx-44 pb-10 p-2 ">
          <h2 className="text-3xl  text-[#C61D21] text-left font-bold">
            After Donation:
          </h2>
          <p className="md:text-2xl mt-4 text-base text-black w-full text-justify font-semibold">
            Once you have donated, please share the transaction details or
            receipt via WhatsApp at{" "}
            <span className="text-red">+91 9955685568</span>. This helps us
            acknowledge your contribution and issue a receipt for tax exemption
            under Section 80G of the Income Tax Act.
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default GauraPurnima;
